import { ComponentType } from 'react';
import { HealthCareBeneficiary } from 'lib/health-care';
import { MessageDescriptor } from 'react-intl';
import { HomeConsultationStepsEnum } from 'lib/home-consultation';
import { PreventionStepsEnum } from 'lib/preventions/types';
import { TeleconsultationStepsEnum } from 'lib/teleconsultation/types';
import { FormValues, WorkflowKeysEnum } from 'core';
import { PrimoStepsEnum } from 'lib/primo';
import { HeaderV2Props } from 'components/Header';

export type OnNextStepClickParams = {
    wizardType?: WizardType;
};

export type PostSuccessModalComponentProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export type ExtraFormComponentProps = {
    handleError?: (error: unknown) => void;
};

export type StepComponentProps = {
    wizardType: WizardType;
    formType?:
        | HomeConsultationStepsEnum
        | PreventionStepsEnum
        | TeleconsultationStepsEnum
        | PrimoStepsEnum; // add other workflows types
    title?: MessageDescriptor;
    content?: string;
    handleNextStep?: (
        params?: OnNextStepClickParams | FormValues,
        stepNumber?: number
    ) => void;
    handleGoBackOnclick?: () => void;
    resetStep?: () => void;
    additionalData?: WizardFormContainerAdditionalData;
    bottomFormComponent?: (props?: ExtraFormComponentProps) => JSX.Element;
    topFormComponent?: (props?: ExtraFormComponentProps) => JSX.Element;
    postSuccessModalComponent?: (
        props?: PostSuccessModalComponentProps
    ) => JSX.Element;
    isLastStep?: boolean;
    noPadding?: boolean;
    workflowKey?: WorkflowKeysEnum;
    setHideHeader?: React.Dispatch<React.SetStateAction<boolean>>;
    nextButtonMessage?: MessageDescriptor;
    cancelButtonMessage?: MessageDescriptor;
    cancelRedirectStep?: number;
};

type StepHeaderProps = {
    title: MessageDescriptor;
    subtitle?: MessageDescriptor;
};

export type Step = {
    name: string;
    title?: MessageDescriptor;
    Component: ComponentType<StepComponentProps>;
    componentData?: StepComponentProps;
    headerData?: StepHeaderProps;
    headerProps?: HeaderV2Props;
    description?: string;
    beneficiaries?: HealthCareBeneficiary[];
    stepNumber?: number;
    hideHeader?: boolean;
    hideBreadcrumbs?: boolean;
    wizardType?: WizardType;
    ConditionGuard?: React.ElementType;
};

export type WizardFormContainerAdditionalData = {
    isSubmitting: boolean;
    beneficiaries?: HealthCareBeneficiary[];
    requiredSignature?: boolean;
};

export enum WizardType {
    MEDICALIB = 'home-consultation',
    PREVENTION = 'prevention',
    TELECONSULTATION_TEXT_MESSAGE_EXCHANGE = 'text-message-exchange',
    TELECONSULTATION_IMMEDIATE_EXCHANGE = 'immediate-exchange',
    TELECONSULTATION_APPOINTMENT = 'appointment',
    PRIMO = 'primo',
}
