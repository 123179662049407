import React from 'react';
import { useRouter } from 'next/router';
import { FormProvider } from 'react-hook-form';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { Stack, HStack, Button } from '@chakra-ui/react';
import { DevTool } from '@hookform/devtools';

import {
    Heading,
    __DEV__,
    getIntlPath,
    SafeFormattedMessage,
    formMessages,
    FormValues,
    FormObject,
    useFormValidation,
    FormLibrarian,
    UtilsContext,
    useSatisfaction,
    coreSharedMessages,
    coreErrors,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ToastAlert } from 'design-system/components';
import { ArrowRightIcon } from 'design-system/icons';

const intlMessageSuccess = defineMessage({
    id: 'components.forms.satisfaction.send-success',
    defaultMessage: 'Votre avis a bien été envoyé.',
});

function usePostSatisfaction(form: FormObject) {
    const router = useRouter();
    const [submitState, setSubmitState] = React.useState<
        'success' | 'error' | 'pending' | 'idle'
    >('idle');
    const { onSubmit, methods } = useFormValidation(
        'selfcare',
        `${form?.iri}/${router.query.formId}`,
        form?.widgets
    );

    async function handleSubmit(data: FormValues) {
        setSubmitState('pending');
        const result = await onSubmit(data);

        if (result) {
            setSubmitState(result?.status === 200 ? 'success' : 'error');
        } else {
            setSubmitState('error');
        }
    }

    React.useEffect(() => {
        if (submitState === 'success') {
            setTimeout(() => {
                router.push('/');
            }, 1000);
        }
    }, [router, submitState]);

    return {
        methods,
        handleSubmit,
        submitState,
    };
}

type FormSatisfactionProps = {
    boundaryComponent: React.ElementType;
};

function FormSatisfaction({ boundaryComponent }: FormSatisfactionProps) {
    const { routes } = React.useContext(UtilsContext);
    const { isMobile } = useWindowBreakpoints();
    const router = useRouter();
    const intl = useIntl();
    const { data: form, status } = useSatisfaction();
    const { submitState, methods, handleSubmit } = usePostSatisfaction(form);
    const LayoutBoundary = boundaryComponent;

    return (
        <LayoutBoundary py="10" pb={isMobile ? 20 : undefined} status={status}>
            <Stack spacing="8">
                {form?.title && (
                    <Heading as="h2">
                        <SafeFormattedMessage
                            {...formMessages[form.title]}
                            debugKey={form.title}
                        />
                    </Heading>
                )}

                <FormProvider {...methods}>
                    <Stack
                        noValidate
                        as="form"
                        onSubmit={methods.handleSubmit(handleSubmit)}
                        spacing="6">
                        {form?.widgets.map(({ type, id, ...props }) => (
                            <FormLibrarian
                                key={id}
                                type={
                                    type === 'radio'
                                        ? 'radioSatisfaction'
                                        : type
                                }
                                props={{
                                    id,
                                    methods: {
                                        setError: methods.setError,
                                        errors: methods.formState.errors,
                                        clearErrors: methods.clearErrors,
                                    },
                                    ...props,
                                }}
                            />
                        ))}
                        <HStack spacing="4" justifyContent="flex-end">
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={() =>
                                    router.push(
                                        getIntlPath(
                                            routes,
                                            '/messages',
                                            router.locale
                                        )
                                    )
                                }>
                                <FormattedMessage
                                    id="components.forms.satisfaction.textarea-skip"
                                    defaultMessage="Passer"
                                />
                            </Button>
                            <Button
                                colorScheme="primary"
                                type="submit"
                                isLoading={submitState === 'pending'}
                                rightIcon={<ArrowRightIcon w={5} h={5} />}>
                                <FormattedMessage
                                    {...coreSharedMessages.send}
                                />
                            </Button>
                        </HStack>

                        {(submitState === 'error' ||
                            submitState === 'success') && (
                            <ToastAlert
                                status={submitState}
                                description={
                                    submitState === 'success'
                                        ? intl.formatMessage(intlMessageSuccess)
                                        : intl.formatMessage(coreErrors.error)
                                }
                                isClosable
                            />
                        )}
                    </Stack>

                    {__DEV__ && <DevTool control={methods.control} />}
                </FormProvider>
            </Stack>
        </LayoutBoundary>
    );
}

export default FormSatisfaction;
