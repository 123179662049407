import type { Pattern } from '../helpers';

export type PatternValidations = {
    numberOfRequiredCapitalLetters?: number;
    numberOfRequiredNumbers?: number;
    numberOfRequiredSpecialCharacters?: number;
    lengthMin?: number;
};

export enum ValidationsUnit {
    HOUR = 'hour',
}

export type Validations = {
    lengthMin?: number;
    lengthMax?: number;
    lengthMaxWithoutHtmlTags?: number;
    required: boolean;
    richTextareaRequired?: boolean;
    forbiddenCharacters?: string[];
    dateMax?: string | null;
    dateMin?: string | null;
    openDays?: boolean | null;
    minAmount?: number | null;
    maxAmount?: number | null;
    dateRangeMax?: number;
    dateRangeMin?: number;
    numberOfRequiredSpecialCharacters?: number;
    numberOfRequiredNumbers?: number;
    numberOfRequiredCapitalLetters?: number;
    acceptedMimeTypes?: string[];
    maxSize?: number;
    maxFiles?: number;
    allowSpecialCharacters?: boolean | null;
    allowNumbers?: boolean | null;
    allowLetters?: boolean | null;
    allow0?: boolean | null;
    totalMaxSize?: number | null;
    multipleTotalMaxSize?: number;
    numberOfRequiredLowercaseLetters?: number;
    maxTreatmentsNumber?: number;
    minTreatmentsNumber?: number;
    minSelectedOptions?: number;
    maxSelectedOptions?: number;
    minRangeValue?: number;
    maxRangeValue?: number;
    unit?: ValidationsUnit;
    regexPattern?: string;
    regexFlags?: string[];
    mobilePhoneRequired?: boolean;
};

export type ValidationOptions = {
    patternValidations?: PatternValidations;
    sameAs?: string;
    higherThan?: string;
    rangeBetween?: { id: string; minRangeValue: number; maxRangeValue: number };
    type?: Pattern;
};
