import React, { useContext, useEffect, useRef } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { QueryStatus } from '@tanstack/react-query';
import {
    Button,
    ModalContentProps,
    ModalHeaderProps,
    ModalProps,
    Stack,
    Text,
} from '@chakra-ui/react';
import {
    formMessages,
    FormObject,
    FormValues,
    ViolationMessage,
    APIError,
    coreSharedMessages,
    defaultLayoutBoundaryErrorMessages,
    UtilsContext,
    FormErrors,
    FormLibrarian,
    FormFooter,
    SafeFormattedMessage,
    errorToastsMessages,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
    CoreModalFooter,
    CoreModal,
} from 'design-system/components';

type FormModalProps = {
    isOpen: boolean;
    onClose: () => void;
    form: FormObject;
    errors: ViolationMessage[];
    onSubmit: (data: FormValues) => Promise<any> | void;
    methods: UseFormReturn<FieldValues>;
    isSubmitting: boolean;
    formStatus: QueryStatus;
    isFetching: boolean;
    formError: APIError;
    submitErrorCode: number | null;
    customErrorMessage?: MessageDescriptor; // in case of error in the OPTIONS
    customSubmitText?: MessageDescriptor;
    size?: ModalProps['size'];
    withCancelButton?: boolean;
    withCloseButton?: boolean;
    modalProps?: Partial<ModalProps>;
    modalContentProps?: Partial<ModalContentProps>;
    modalHeaderProps?: Partial<ModalHeaderProps>;
};

/**
 * Renders the base modal for creation and edition
 * @returns tsx
 */
export function FormModal({
    isOpen,
    onClose,
    form,
    errors,
    onSubmit,
    methods,
    isSubmitting,
    formStatus,
    isFetching,
    formError,
    submitErrorCode,
    customErrorMessage,
    size = 'lg',
    customSubmitText,
    withCancelButton = true,
    withCloseButton = true,
    modalProps = {},
    modalContentProps = {},
    modalHeaderProps = {},
}: FormModalProps) {
    const {
        layouts: { LayoutBoundary },
    } = useContext(UtilsContext);
    const { isMobile } = useWindowBreakpoints();
    const { formatMessage } = useIntl();
    const bodyRef = useRef(null);

    useEffect(() => {
        errors.length &&
            bodyRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
    }, [errors]);

    return (
        <CoreModal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={!isMobile}
            scrollBehavior={'inside'}
            size={size}
            {...modalProps}>
            <CoreModalContent {...modalContentProps}>
                {formStatus !== 'pending' && (
                    <CoreModalHeader
                        withCloseButton={withCloseButton}
                        {...(!form && {
                            minH: '60px',
                        })}
                        {...modalHeaderProps}>
                        {form?.title ? (
                            <Text data-testid="modal-title">
                                {formMessages[form?.title] ? (
                                    <SafeFormattedMessage
                                        {...formMessages[form?.title]}
                                        debugKey={`GroupDetailsForm - Form title - ${form?.title}`}
                                    />
                                ) : (
                                    <FormattedMessage
                                        {...coreSharedMessages.error}
                                    />
                                )}
                            </Text>
                        ) : (
                            formStatus === 'error' && (
                                <FormattedMessage
                                    {...coreSharedMessages.error}
                                />
                            )
                        )}
                    </CoreModalHeader>
                )}
                <CoreModalBody
                    ref={bodyRef}
                    py="4"
                    px={formStatus === 'error' ? '2' : '6'}>
                    <LayoutBoundary
                        status={isFetching ? 'pending' : formStatus}
                        errorCode={formError?.code}
                        messages={{
                            ...defaultLayoutBoundaryErrorMessages[
                                formError?.code
                            ],
                            toastError: errorToastsMessages[submitErrorCode],
                            ...(customErrorMessage
                                ? { errorDescription: customErrorMessage }
                                : {}),
                        }}
                        isSmallDisplay
                        size="md"
                        px="0"
                        pb="0"
                        pt="2"
                        margin="0"
                        toastError={!isSubmitting && !!submitErrorCode}>
                        <>
                            {errors?.length > 0 && (
                                <FormErrors errors={errors} />
                            )}

                            <FormProvider {...methods}>
                                <Stack
                                    noValidate
                                    as="form"
                                    spacing="6"
                                    alignItems={
                                        isMobile ? 'stretch' : 'flex-end'
                                    }>
                                    {formMessages?.[form?.description] && (
                                        <Text alignSelf="flex-start">
                                            <SafeFormattedMessage
                                                {...formMessages[
                                                    form?.description
                                                ]}
                                                debugKey={`GroupForm - Form description - ${form?.description}`}
                                            />
                                        </Text>
                                    )}

                                    {form?.widgets &&
                                        form.widgets.map(
                                            ({ type, id, ...props }) => (
                                                <FormLibrarian
                                                    key={id}
                                                    type={type}
                                                    props={{
                                                        id,
                                                        links: form?.links,
                                                        methods: {
                                                            setError:
                                                                methods.setError,
                                                            errors: methods
                                                                .formState
                                                                .errors,
                                                            clearErrors:
                                                                methods.clearErrors,
                                                        },
                                                        ...props,
                                                    }}
                                                />
                                            )
                                        )}
                                </Stack>
                            </FormProvider>

                            {formMessages?.[form?.footer] && (
                                <FormFooter text={formMessages[form.footer]} />
                            )}
                        </>
                    </LayoutBoundary>
                </CoreModalBody>
                <CoreModalFooter justifyContent={'space-between'} gap={2}>
                    {form && (
                        <>
                            {withCancelButton && (
                                <Button
                                    onClick={onClose}
                                    colorScheme="gray"
                                    size={isMobile ? 'lg' : 'md'}
                                    w={'full'}>
                                    <FormattedMessage
                                        {...coreSharedMessages.cancel}
                                    />
                                </Button>
                            )}
                            <Button
                                type="submit"
                                colorScheme="primary"
                                size={isMobile ? 'lg' : 'md'}
                                isLoading={isSubmitting}
                                loadingText={formatMessage(
                                    coreSharedMessages.submitting
                                )}
                                w={'full'}
                                onClick={methods.handleSubmit(onSubmit)}>
                                <FormattedMessage
                                    {...(customSubmitText ??
                                        coreSharedMessages.validate)}
                                />
                            </Button>
                        </>
                    )}
                </CoreModalFooter>
            </CoreModalContent>
        </CoreModal>
    );
}
