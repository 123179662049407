import { WidgetProps } from '../types';
import NativeSelect from './NativeSelect';
import SearchableSelect from './SearchableSelect';

export type SelectProps = WidgetProps & {
    options: Option[];
    isSearchable: boolean;
};

export type Option = {
    label: string;
    isTranslatable: boolean;
    id: string;
    subWidgets?: WidgetProps[] | null;
};

function SelectInput(props: SelectProps) {
    const { isSearchable, ...rest } = props;

    return isSearchable ? (
        <SearchableSelect {...rest} />
    ) : (
        <NativeSelect {...rest} />
    );
}

export default SelectInput;
