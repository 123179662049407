import React from 'react';
import { Box, Button, ChakraProps, Container, Flex } from '@chakra-ui/react';
import { LinkIntl, HeadTitle, HeadTitleProps } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ArrowLeftIcon } from 'design-system/icons';
import { LanguageContext } from 'core/lib/user/contexts/LanguageContext';

type FullCenteredScreenLayoutProps = React.PropsWithChildren<
    HeadTitleProps &
        Pick<ChakraProps, 'maxW'> & {
            backLink?: {
                href: string;
                title: string;
            };
            hideBg?: boolean;
            Footer?: React.ElementType;
            innerFooter?: React.ReactNode;
        }
>;

export function FullCenteredScreenLayout({
    children,
    backLink,
    title,
    hideBg = false,
    Footer,
    maxW,
    innerFooter,
}: FullCenteredScreenLayoutProps) {
    const { isMobile } = useWindowBreakpoints();
    const { showSwitch } = React.useContext(LanguageContext);

    return (
        <>
            <HeadTitle title={title} />
            <Flex
                flexDirection="column"
                justifyContent={isMobile ? 'space-between' : 'center'}
                alignItems={isMobile ? 'unset' : 'center'}
                overflowY="auto"
                w="100%"
                minH="100vh"
                bg={isMobile || hideBg ? 'white' : 'primary.main'}>
                <Container
                    maxW={maxW || (isMobile ? 'auto' : '500px')}
                    mt={isMobile && showSwitch && '10'}>
                    {backLink && (
                        <Box pb={isMobile ? 4 : 0}>
                            <LinkIntl href={backLink.href}>
                                <Button
                                    style={{
                                        fontWeight: isMobile ? 800 : 'normal',
                                    }}
                                    fontSize={isMobile ? 'lg' : 'md'}
                                    color="black"
                                    bg="white">
                                    <ArrowLeftIcon mr="2" />
                                    {backLink.title}
                                </Button>
                            </LinkIntl>
                        </Box>
                    )}
                    <Box
                        my={isMobile ? '0' : '4'}
                        as="main"
                        rounded="md"
                        bg="white">
                        {children}
                        {innerFooter && (
                            <Box
                                rounded="md"
                                position={isMobile ? 'fixed' : 'relative'}
                                bottom={isMobile ? 0 : 'auto'}
                                w={isMobile ? 'full' : 'auto'}
                                bg="white"
                                zIndex={isMobile ? 1 : 'unset'}>
                                {innerFooter}
                            </Box>
                        )}
                    </Box>
                </Container>
                {Footer && <Flex mt={isMobile && 4}>{<Footer />}</Flex>}
            </Flex>
        </>
    );
}
