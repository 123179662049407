import React, { useEffect, useState } from 'react';
import { Box, Select } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSafeIntl } from '../../../SafeIntl';
import { FormGroup } from '../../FormGroup';
import { SelectProps } from '.';
import {
    useInputValidation,
    formMessages,
    FormLibrarian,
    WidgetProps,
    usePrevious,
} from '../../../..';

function NativeSelect(props: Omit<SelectProps, 'isSearchable'>) {
    const {
        id,
        options,
        placeholder,
        hideLabel,
        defaultValue,
        validations,
        links,
        validationOptions,
        title,
        name,
        readonly,
        help,
        description,
    } = props;
    const { unregister } = useFormContext();
    const { safeFormatMessage } = useSafeIntl();
    const [subWidgets, setSubWidgets] = useState<WidgetProps[]>([]);
    const inputName = name ?? `${id}.value`;
    const selectValue = useWatch({ name: inputName, defaultValue });
    const { registerValues } = useInputValidation(
        validations,
        inputName,
        validationOptions
    );

    const previousValue = usePrevious(selectValue);

    useEffect(() => {
        const selectedSubWidgets = options.find(
            (option) => option.id === selectValue
        )?.subWidgets;
        setSubWidgets(selectedSubWidgets);
        if (previousValue) {
            const selectedOptionsSW =
                options.find((option) => option.id === previousValue)
                    ?.subWidgets || [];
            const subWidgetsToUnregister = selectedOptionsSW.map(
                (subWidget) => `${subWidget.id}.value`
            );

            if (subWidgetsToUnregister.length)
                unregister(subWidgetsToUnregister);
        }
    }, [selectValue]);

    const _renderOptions = () => {
        return options.map((option) => (
            <option key={option.id} value={option.id}>
                {option.isTranslatable
                    ? safeFormatMessage(
                          formMessages[option.label],
                          null,
                          `<SelectInput /> ${option.label}`
                      )
                    : option.label}
            </option>
        ));
    };

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, hideLabel, help, links }}>
            <Select
                {...registerValues}
                height="44px"
                borderColor="strokes.medium"
                _focus={{ borderColor: 'primary.main' }}
                _hover={{ borderColor: 'strokes.dark' }}
                placeholder={
                    placeholder &&
                    safeFormatMessage(
                        formMessages[placeholder],
                        null,
                        placeholder
                    )
                }
                defaultValue={defaultValue}>
                {_renderOptions()}
            </Select>
            {subWidgets &&
                subWidgets?.map((subWidget) => (
                    <Box key={subWidget.id} pt={5}>
                        <FormLibrarian
                            type={subWidget.type}
                            props={{
                                ...subWidget,
                            }}
                        />
                    </Box>
                ))}
        </FormGroup>
    );
}

export default NativeSelect;
