import { useQueryClient } from '@tanstack/react-query';
import { useRedirect } from 'core';
import { useUserContext } from 'lib/user';
import { SATISFACTION_SESSION_KEY } from 'lib/satisfaction';
import { TELECONSULTATION_DATA_STORAGE_KEY } from 'lib/teleconsultation/consts/teleconsultationFormConfig';
import { ONBOARDING_V2_SESSION_KEY } from 'components/onboarding/v2/consts';

export function useClearUserData() {
    const { redirect } = useRedirect();
    const queryClient = useQueryClient();
    const { clearUserInfos } = useUserContext();

    const clearUserData = async (redirectPath: string = '/login') => {
        queryClient.clear();
        window.sessionStorage.removeItem(TELECONSULTATION_DATA_STORAGE_KEY);
        window.sessionStorage.removeItem(SATISFACTION_SESSION_KEY);
        window.sessionStorage.removeItem(ONBOARDING_V2_SESSION_KEY);
        clearUserInfos();
        await redirect(redirectPath);
    };

    return { clearUserData };
}
