import axios from 'axios';
import { useClearUserData } from './useClearUserData';

const { API_URL } = process.env;

function useLogout() {
    const { clearUserData } = useClearUserData();

    const logout = async (
        microService: string = 'security',
        cookieName: string = 'auth-token'
    ) => {
        try {
            const res = await axios.post(`/api/logout`, {
                API_URL,
                MICRO_SERVICE: microService,
                COOKIE_NAME: cookieName,
            });

            if (res.status === 200) {
                await clearUserData();
            }
        } catch (err) {
            throw new Error(err.message);
        }
    };

    return { logout };
}

export { useLogout };
