import React, { ElementType, useEffect, useRef } from 'react';
import {
    LinkBox,
    LinkOverlay,
    IconButton,
    Flex,
    Text,
    Box,
} from '@chakra-ui/react';

import { LinkIntl } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
type MenuItemProps = React.PropsWithChildren<unknown> & {
    to?: string;
    id: number;
    handleKeyDown: (evt: React.KeyboardEvent<Element>) => void;
    setCurrentFocus: (idx: number) => void;
    isFocused?: boolean;
    Icon: ElementType;
    badge?: boolean | string;
    ariaLabel: string;
    noLeftBorder?: boolean;
    noRightBorder?: boolean;
    flex?: string;
};

type MenuItemContentProps = Omit<MenuItemProps, 'to'>;

type ItemCountBadgeProps = {
    count: string;
};

const MENU_ITEM_WIDTH_DESKTOP = 68;

const ItemCountBadge = ({ count }: ItemCountBadgeProps) => (
    <Flex
        bg="primary.main"
        color="white"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        w={5}
        h={5}
        transform="translate(10px, -5px)"
        borderRadius="full">
        <Text fontSize="xs">{count}</Text>
    </Flex>
);

const NotificationBadge = () => (
    <Box
        bg="warning.main"
        position="absolute"
        w={2}
        h={2}
        transform="translate(10px, -5px)"
        borderRadius="full"
    />
);

const MenuItemContent = ({
    handleKeyDown,
    id,
    isFocused = false,
    setCurrentFocus,
    Icon,
    badge = false,
    ariaLabel,
    noLeftBorder,
    noRightBorder,
    flex,
}: MenuItemContentProps) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { isMobile } = useWindowBreakpoints();

    useEffect(() => {
        if (isFocused && ref.current) {
            ref.current.focus();
        }
    }, [isFocused]);

    return (
        <IconButton
            aria-label={ariaLabel}
            icon={
                <>
                    <Icon w={5} h={5} />
                    {badge &&
                        (typeof badge === 'string' ? (
                            <ItemCountBadge count={badge} />
                        ) : (
                            <NotificationBadge />
                        ))}
                </>
            }
            onKeyDown={handleKeyDown}
            onClick={() => setCurrentFocus(id)}
            variant="outline"
            borderRadius={0}
            borderY="none"
            borderLeft={noLeftBorder ? 'none' : ''}
            borderRight={noRightBorder ? 'none' : ''}
            color={isFocused ? 'black' : 'gray.700'}
            bg={isFocused ? 'blackAlpha.50' : 'transparent'}
            outline="none"
            _hover={{ bg: 'blackAlpha.50' }}
            _focus={{
                color: 'black',
                bg: 'blackAlpha.50',
            }}
            _focusVisible={{ outline: 'none' }}
            ref={ref}
            w={isMobile ? 'full' : MENU_ITEM_WIDTH_DESKTOP}
            h="full"
            flex={flex}
        />
    );
};

const MenuItem = ({ to, flex, ...props }: MenuItemProps) => {
    if (to) {
        return (
            <LinkBox flex={flex} h="full">
                <LinkOverlay tabIndex={-1} as={LinkIntl} href={to} passHref>
                    <MenuItemContent {...props} />
                </LinkOverlay>
            </LinkBox>
        );
    }

    return <MenuItemContent {...props} flex={flex} />;
};

export default MenuItem;
