import React from 'react';
import { useIntl } from 'react-intl';
import { FullCenteredScreenLayout } from '../../../components/Layouts';
import { defineMessages } from '@formatjs/intl';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useMaintenance } from '../hooks';
import { MaintenanceType } from '../types';
import { Maintenance } from '../../../components/Maintenance';
import { LanguageContext } from '../../../lib/user';

type MaintenanceContainerProps = {
    Component: (props?: any) => JSX.Element;
    data: MaintenanceType;
    FooterComponent: (props?: any) => JSX.Element;
};

function MaintenanceContainer({
    Component,
    data,
    FooterComponent,
}: MaintenanceContainerProps) {
    const { isMobile } = useWindowBreakpoints();
    return data?.disableConnection ? (
        <Maintenance
            data={data}
            Layout={FullCenteredScreenLayout}
            {...(!!FooterComponent && {
                Footer: () => (
                    <FooterComponent
                        textColor={!isMobile ? 'white' : 'black'}
                    />
                ),
            })}
        />
    ) : (
        <Component />
    );
}

const intlMessages = defineMessages({
    defaultDescription: {
        id: 'components.maintenance.default-description',
        defaultMessage:
            '<l>Votre espace assuré est temporairement indisponible.</l><l>Veuillez nous excuser pour la gêne occasionnée.</l>',
    },
});

const withMaintenance = (
    Component: (props?: any) => JSX.Element,
    FooterComponent: (props?: any) => JSX.Element = null
) => {
    return () => {
        const [data, setData] = React.useState<MaintenanceType>();
        const { formatMessage } = useIntl();
        const { locale } = React.useContext(LanguageContext);
        const { data: maintenanceData } = useMaintenance(locale);

        React.useEffect(() => {
            if (maintenanceData?.frontMaintenance) {
                setData({
                    disableConnection: true,
                    message: formatMessage(intlMessages.defaultDescription),
                });
                return;
            }

            setData(maintenanceData);
        }, [maintenanceData, formatMessage]);

        return (
            data ? (
                <MaintenanceContainer
                    Component={Component}
                    data={data}
                    FooterComponent={FooterComponent}
                />
            ) : (
                <Component />
            )
        ) as any;
    };
};

export { withMaintenance, MaintenanceContainer };
