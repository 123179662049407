import { siteConfig } from 'lib/site';

export const getAppStoreLink = (locale: string) => {
    const { ios } = siteConfig.meta.appIds;
    return `itms-apps://apps.apple.com/${locale?.split('-')?.[0]}/app/${ios}`;
};

export const getPlayStoreLink = () => {
    const { android } = siteConfig.meta.appIds;
    return `market://details?id=${android}`;
};

export const getWebAppStoreLink = (locale: string) => {
    const { ios } = siteConfig.meta.appIds;
    return `https://apps.apple.com/${locale?.split('-')?.[0]}/app/${ios}`;
};

export const getWebPlayStoreLink = () => {
    const { android } = siteConfig.meta.appIds;
    return `https://play.google.com/store/apps/details?id=${android}`;
};

export const getAppLinkByOs = (os: string | undefined, locale: string) => {
    if (!os) return;

    return os === 'ios' ? getAppStoreLink(locale) : getPlayStoreLink();
};
