import {
    Address,
    Checkbox,
    CheckboxModal,
    InputAmount,
    InputBankDetails,
    InputDate,
    InputDateRange,
    Range,
    InputEmail,
    EmailConfirmation,
    FileUpload,
    InputPassword,
    InputPhoneNumber,
    InputProfessionalId,
    Select,
    InputSocialSecurityNumber,
    InputText,
    TextArea,
    PasswordConfirmation,
    RadioSatisfaction,
    InputRadio,
    InputConditionalRadio,
    FileDownload,
    StarRating,
    MultipleFileUpload,
    CheckboxList,
    AddressAutocomplete,
    Information,
    InternationalPhoneNumber,
} from '../../../components/forms/widgets';
import { SelectProps } from '../../../components/forms/widgets/Select';
import { TextAreaProps } from '../../../components/forms/widgets/TextArea';
import type {
    WidgetInformationProps,
    WidgetProps,
} from '../../../components/forms/widgets/types';
import { AddressAutocompleteWidgetProps } from '../../../components/forms/widgets/AddressAutocomplete';
import { InputRangeProps } from '../../../components/forms/widgets/Range';
import { CheckboxListProps } from '../../../components/forms/widgets/CheckboxList';
import { InternationalPhoneNumberProps } from '../../../components/forms/widgets/InternationalPhoneNumber';

const formLibrary = {
    text(props: WidgetProps) {
        return <InputText {...props} />;
    },
    textArea(props: TextAreaProps) {
        return <TextArea {...props} />;
    },
    phoneNumber(props: WidgetProps) {
        return <InputPhoneNumber {...props} />;
    },
    faxNumber(props: WidgetProps) {
        return <InputPhoneNumber {...props} />;
    },
    email(props: WidgetProps) {
        return <InputEmail {...props} />;
    },
    checkbox(props: any) {
        return <Checkbox {...props} />;
    },
    address(props: WidgetProps) {
        return <Address {...props} />;
    },
    socialSecurityNumber(props: WidgetProps) {
        return <InputSocialSecurityNumber {...props} />;
    },
    emailConfirmation(props: any) {
        return <EmailConfirmation {...props} />;
    },
    password(props: WidgetProps) {
        return <InputPassword hasPattern {...props} />;
    },
    passwordConfirmation(props: any) {
        return <PasswordConfirmation {...props} />;
    },
    date(props: WidgetProps) {
        return <InputDate {...props} />;
    },
    select(props: SelectProps) {
        return <Select {...props} />;
    },
    conditionalSelectMultipleSubWidgets(props: SelectProps) {
        return <Select {...props} />;
    },
    amount(props: WidgetProps) {
        return <InputAmount {...props} />;
    },
    range(props: InputRangeProps) {
        return <Range {...props} />;
    },
    dateRange(props: any) {
        return <InputDateRange {...props} />;
    },
    fileUpload(props: WidgetProps) {
        return <FileUpload {...props} />;
    },
    bankDetails(props: any) {
        return <InputBankDetails {...props} />;
    },
    professionalId(props: WidgetProps) {
        return <InputProfessionalId {...props} />;
    },
    radio(props: any) {
        return <InputRadio {...props} />;
    },
    conditionalRadio(props: any) {
        return (
            <InputConditionalRadio
                {...props}
                options={props?.options?.map((option) => ({
                    ...option,
                    subWidgets: option?.subWidget ? [option.subWidget] : [],
                }))}
            />
        );
    },
    conditionalRadioMultipleSubWidgets(props: any) {
        return <InputConditionalRadio {...props} />;
    },
    radioSatisfaction(props: any) {
        return <RadioSatisfaction {...props} />;
    },
    checkboxModal(props: any) {
        return <CheckboxModal {...props} />;
    },
    fileDownload(props: any) {
        return <FileDownload {...props} />;
    },
    starRating(props: any) {
        return <StarRating {...props} />;
    },
    multipleFileUpload(props: any) {
        return <MultipleFileUpload {...props} />;
    },
    conditionalCheckBoxList(props: CheckboxListProps) {
        return <CheckboxList {...props} />;
    },
    autocompleteCheckboxList(props: CheckboxListProps) {
        return <CheckboxList {...props} />;
    },
    addressAutocomplete(props: AddressAutocompleteWidgetProps) {
        return <AddressAutocomplete {...props} />;
    },
    information(props: WidgetInformationProps) {
        return <Information {...props} />;
    },
    internationalPhoneNumber(props: InternationalPhoneNumberProps) {
        return <InternationalPhoneNumber {...props} />;
    },
};

export default formLibrary;
