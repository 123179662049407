import { useQueryClient } from '@tanstack/react-query';
import { Site, userPaths } from 'core';

export function useCache(
    project: Site.Package,
    keysToRemove?: string[],
    formsInactivePathsToRemove?: string[]
) {
    const queryClient = useQueryClient();

    const resetCacheAfterSubmit = () => {
        keysToRemove &&
            keysToRemove.length > 0 &&
            keysToRemove.map((key) =>
                queryClient.removeQueries({
                    queryKey: [key],
                    ...{
                        exact: false,
                    },
                })
            );

        formsInactivePathsToRemove &&
            formsInactivePathsToRemove.length > 0 &&
            formsInactivePathsToRemove.map((path) =>
                queryClient.removeQueries({
                    queryKey: [path],
                    ...{ type: 'inactive' },
                })
            );

        queryClient.invalidateQueries({
            queryKey: [userPaths[project], true],
            exact: true,
            refetchType: 'all',
        });
    };

    return {
        resetCacheAfterSubmit,
    };
}
