import { MessageDescriptor } from 'react-intl';
import { UrlObject } from 'url';

export enum ConsentModalPartnershipNameEnum {
    MEDICALIB = 'medicalib',
    EAF = 'eaf',
    PREVENTION = 'prevention',
}

export type ConsentModalType = {
    title: MessageDescriptor;
    description: MessageDescriptor;
    benefits: MessageDescriptor[];
    partnershipName: ConsentModalPartnershipNameEnum;
    partnershipIcon: () => JSX.Element;
    partnershipDesc: MessageDescriptor;
    consents: MessageDescriptor[];
    workflowLink: string | UrlObject;
    displayExclusions?: boolean;
    illustration?: React.ElementType;
};

export type ConsentModalConfigType = {
    [ConsentModalEnum.HOME_CONSULTATION]: ConsentModalType;
    [ConsentModalEnum.IMMEDIATE_EXCHANGE]: ConsentModalType;
    [ConsentModalEnum.IMMEDIATE_EXCHANGE_V2]: ConsentModalType;
    [ConsentModalEnum.APPOINTMENT]: ConsentModalType;
    [ConsentModalEnum.WRITTEN_EXCHANGE]: ConsentModalType;
    [ConsentModalEnum.WRITTEN_EXCHANGE_V2]: ConsentModalType;
    [ConsentModalEnum.PREVENTION]: ConsentModalType;
};

export enum ConsentModalEnum {
    HOME_CONSULTATION = 'home-consultation',
    IMMEDIATE_EXCHANGE = 'immediateExchange',
    IMMEDIATE_EXCHANGE_V2 = 'immediate-exchange',
    APPOINTMENT = 'appointment',
    WRITTEN_EXCHANGE = 'writtenExchange',
    WRITTEN_EXCHANGE_V2 = 'text-message-exchange',
    PREVENTION = 'prevention',
    PRIMO = 'primo',
}
