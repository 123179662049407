import { FormValues, WidgetObject } from 'core';
import { transformDate, transformDateRange } from './transformDate';
import transformMultipleFileUpload from './transformMultipleFileUpload';
import transformFileUpload from './transformFileUpload';
import transformNumber from './transformNumber';
import transformPhoneNumber from './transformPhoneNumber';
import transformConditionalRadio from './transformConditionalRadio';
import transformSocialSecurityNumber from './transformSocialSecurityNumber';
import transformMultiTreatments from './transformMultiTreatments';
import transformCheckboxList from './transformCheckboxList';
import transformJSON from './transformJSON';
import transformInternationalPhoneNumber from './transformInternationalPhoneNumber';
import transformConditionalSelect from './transformConditionalSelect';

type HandlerCallback = (
    inputId: string,
    data: FormValues | string | boolean,
    subWidgetsType?: SubWidgetType[],
    hasSubWidgets?: boolean
) => any;

type Handler = (
    data: any[],
    id: string,
    subWidgetsType?: SubWidgetType[],
    hasSubWidgets?: boolean
) => Record<string, any>;

const formatFormFinalObject = (id: string, values: any) => ({
    id,
    ...values,
});

const handler =
    (callback: HandlerCallback): Handler =>
    (data, id, subWidgetsType, hasSubWidgets) => {
        const input = data.find(([inputId]) => inputId === id);

        if (!input) return data;

        const [inputId, inputValues] = input;
        return callback(inputId, inputValues, subWidgetsType, hasSubWidgets);
    };

const handleFileUpload = handler(transformFileUpload);
const handleMultipleFileUpload = handler(transformMultipleFileUpload);
const handlePhoneNumber = handler(transformPhoneNumber);
const handleInternationalPhoneNumber = handler(
    transformInternationalPhoneNumber
);
const handleDate = handler(transformDate);
const handleDateRange = handler(transformDateRange);
const handleNumber = handler(transformNumber);
const handleSocialSecurityNumber = handler(transformSocialSecurityNumber);
const handleConditionalRadio = handler(transformConditionalRadio);
const handleConditionalSelect = handler(transformConditionalSelect);
const handleMultiTreatments = handler(transformMultiTreatments);
const handleCheckboxList = handler(transformCheckboxList);
const handleJSON = handler(transformJSON);

export type SubWidgetType = { optionId: string; subWidget: WidgetObject };

function handleDataTransformations(
    data: FormValues,
    widgetTypes: WidgetObject[],
    subWidgetsType: SubWidgetType[]
) {
    const arrayOfData = Object.entries(data);
    // On garde uniquement les widgets qui ont une valeur.
    // Un widget sans valeur n'as pas d'intêret à être traité.
    const filteredWidgetWithValues = arrayOfData
        .map(([widgetId]) =>
            widgetTypes.find((widget) => widget.id === widgetId)
        )
        .filter((widget) => !!widget);

    const transformedData = filteredWidgetWithValues.map((widget) => {
        switch (widget.type) {
            case 'fileUpload':
                return handleFileUpload(arrayOfData, widget.id, subWidgetsType);

            case 'phoneNumber':
            case 'faxNumber':
                return handlePhoneNumber(
                    arrayOfData,
                    widget.id,
                    subWidgetsType
                );

            case 'date':
                return handleDate(arrayOfData, widget.id, subWidgetsType);

            case 'dateRange':
                return handleDateRange(arrayOfData, widget.id, subWidgetsType);

            case 'amount':
                return handleNumber(arrayOfData, widget.id, subWidgetsType);

            case 'socialSecurityNumber':
                return handleSocialSecurityNumber(
                    arrayOfData,
                    widget.id,
                    subWidgetsType
                );

            case 'conditionalRadioMultipleSubWidgets':
            case 'conditionalRadio':
                return handleConditionalRadio(
                    arrayOfData,
                    widget.id,
                    subWidgetsType
                );

            case 'conditionalSelectMultipleSubWidgets':
                return handleConditionalSelect(
                    arrayOfData,
                    widget.id,
                    subWidgetsType
                );

            case 'multipleTreatments':
                return handleMultiTreatments(
                    arrayOfData,
                    widget.id,
                    subWidgetsType
                );

            case 'multipleFileUpload':
                return handleMultipleFileUpload(
                    arrayOfData,
                    widget.id,
                    subWidgetsType
                );

            case 'conditionalCheckBoxList':
                return handleCheckboxList(
                    arrayOfData,
                    widget.id,
                    subWidgetsType,
                    true
                );

            case 'autocompleteCheckboxList':
                return handleCheckboxList(
                    arrayOfData,
                    widget.id,
                    subWidgetsType,
                    false
                );
            case 'addressAutocomplete':
                return handleJSON(arrayOfData, widget.id);
            case 'internationalPhoneNumber':
                return handleInternationalPhoneNumber(arrayOfData, widget.id);

            default:
                return formatFormFinalObject(widget.id, data[widget.id]);
        }
    });

    return transformedData;
}

export { handleDataTransformations, formatFormFinalObject };
