import { defineMessages } from 'react-intl';

export const sharedMessages = defineMessages({
    filter: { id: 'buttons.filter', defaultMessage: 'Filtrer' },
    modifyFilters: {
        id: 'buttons.modify-filters',
        defaultMessage: 'Modifier les filtres',
    },
    logoImageAlt: {
        id: 'components.common.logo-image-alt',
        description: "Texte alternative de l'image du logo de la mutuelle",
        defaultMessage: 'Logo de {name}',
    },
    addedAt: {
        id: 'components.common.added-at',
        defaultMessage: 'Ajouté le',
    },
    call: {
        id: 'components.common.call',
        defaultMessage: 'Appeler',
    },
    otherRequest: {
        id: 'components.common.no-result',
        defaultMessage: 'Aucun résultat',
    },
    chequeStubs: {
        id: 'contributions.cheque-stubs.title',
        defaultMessage: 'Bordereaux de chèques',
    },
    beneficiaries: {
        id: 'components.common.beneficiaries',
        defaultMessage: 'Bénéficiaires',
    },
    contributions: {
        id: 'components.common.contributions',
        defaultMessage: 'Cotisations',
    },
    guarantees: {
        id: 'components.common.guarantees',
        defaultMessage: 'Garanties',
    },
    products: {
        id: 'components.common.products',
        defaultMessage: 'Produits souscrits',
    },
    myGuaranteesFamilies: {
        id: 'components.common.my-family-guarantees',
        defaultMessage: 'Mes Garanties',
    },
    beneficiariesDesignation: {
        id: 'components.common.beneficiaries-designation',
        defaultMessage: 'Bénéficiaires capital décès',
    },
    myRefunds: {
        id: 'page-title.refunds',
        defaultMessage: 'Remboursements',
    },
    myAccount: {
        id: 'components.common.my-account',
        defaultMessage: 'Mon compte',
    },
    guaranteesFamilies: {
        id: 'components.common.family-guarantees',
        defaultMessage: 'Garanties',
    },
    news: {
        id: 'components.common.news',
        defaultMessage: 'Journal',
    },
    agenda: {
        id: 'components.common.agenda',
        defaultMessage: 'Agenda',
    },
    new: {
        id: 'components.common.new',
        defaultMessage: 'Nouveau',
    },
    contracts: {
        id: 'page-title.contract',
        defaultMessage: 'Contrat',
    },
    back: {
        id: 'buttons.back',
        defaultMessage: 'Retour',
    },
    backToLogin: {
        id: 'buttons.back-login-page',
        defaultMessage: 'Retour à la page de connexion',
    },
    buttonBackHome: {
        id: 'buttons.back-home',
        defaultMessage: "Retour à l'accueil",
    },
    buttonDownload: {
        id: 'buttons.download',
        defaultMessage: 'Télécharger',
    },
    discover: {
        id: 'buttons.discover',
        defaultMessage: 'Découvrir',
    },
    reply: {
        id: 'buttons.reply',
        defaultMessage: 'Répondre',
    },
    save: {
        id: 'buttons.save',
        defaultMessage: 'Enregistrer',
    },
    btnAllServices: {
        id: 'buttons.discover-all-services',
        defaultMessage: 'Découvrir tous les services',
    },
    services: {
        id: 'components.common.services.title',
        defaultMessage: 'Services',
    },
    viewAll: {
        id: 'components.common.view-all',
        defaultMessage: 'Voir tout',
    },
    beforeRefund: {
        id: 'components.common.before-refund',
        defaultMessage: 'decompte-remboursement-{date}.pdf',
    },
    schedule: {
        id: 'contributions.payment-schedules.title',
        defaultMessage: 'Échéanciers',
    },
    error: {
        id: 'components.common.error',
        defaultMessage: 'Une erreur est survenue',
    },
    passworChangeTitle: {
        id: 'components.common.password-change.title',
        defaultMessage: 'Changement de mot de passe',
    },
    knowMore: {
        id: 'components.common.know-more',
        defaultMessage: 'En savoir plus',
    },
    validate: {
        id: 'buttons.validate',
        defaultMessage: 'Valider',
    },
    cancel: {
        id: 'buttons.cancel',
        defaultMessage: 'Annuler',
    },
    confirm: {
        id: 'buttons.confirm',
        defaultMessage: 'Confirmer',
    },
    edit: {
        id: 'buttons.edit',
        defaultMessage: 'Modifier',
    },
    add: {
        id: 'buttons.add',
        defaultMessage: 'Ajouter',
    },
    or: {
        id: 'components.common.or',
        defaultMessage: 'ou',
    },
    max: {
        id: 'components.common.max',
        defaultMessage: 'max',
    },
    next: {
        id: 'buttons.next',
        defaultMessage: 'Suivant',
    },
    end: {
        id: 'buttons.end',
        defaultMessage: 'Terminer',
    },
    refresh: {
        id: 'buttons.refresh',
        defaultMessage: 'Actualiser',
    },
    temporarilyUnavailable: {
        id: 'components.temporarily-unavailable',
        defaultMessage: 'Indisponible',
    },
    loading: {
        id: 'loader.default',
        defaultMessage: 'Chargement en cours',
    },
    membershipNumber: {
        id: 'contract.membership-number',
        defaultMessage: 'N° adhérent',
    },
    reloadPage: {
        id: 'buttons.reload-page',
        defaultMessage: 'Recharger la page',
    },
    amount: {
        id: 'components.amount',
        defaultMessage: 'Montant',
    },
    servicesRedirect: {
        id: 'components.services.redirect.default',
        defaultMessage: 'Redirection en cours',
    },
    messageDateSent: {
        id: 'components.news.timeline-card.message.date.sent',
        defaultMessage: 'Envoyé le {date}',
    },
    getSocialSecurityCertificate: {
        id: 'buttons.get-social-security-certificate',
        defaultMessage: 'Retrouver mon attestation de Sécurité sociale',
    },
    hide: {
        id: 'buttons.hide',
        defaultMessage: 'Masquer',
    },
    viewAllDocuments: {
        id: 'buttons.view-all-documents',
        defaultMessage: 'Voir tous les documents',
    },
    help: {
        id: 'components.help',
        defaultMessage: 'Aide',
    },
    access: {
        id: 'btn.access',
        defaultMessage: 'Accéder',
    },
    invite: {
        id: 'buttons.invite',
        defaultMessage: 'Inviter',
    },
    resend: {
        id: 'buttons.resend',
        defaultMessage: 'Renvoyer',
    },
    exit: {
        id: 'buttons.exit',
        defaultMessage: 'Quitter',
    },
    close: {
        id: 'buttons.close',
        defaultMessage: 'Fermer',
    },
    seeDetail: {
        id: 'buttons.see-details',
        defaultMessage: 'Voir le détail',
    },
    total: {
        id: 'components.total',
        defaultMessage: 'Total',
    },
    details: {
        id: 'components.details',
        defaultMessage: 'Détails',
    },
    less: {
        id: 'components.less',
        defaultMessage: 'Moins',
    },
});

export const errors = defineMessages({
    counter: {
        id: 'errors.counter.default',
        defaultMessage:
            'Nous rencontrons des difficultés à vous afficher certains contenus.',
    },
    notFoundTitle: {
        id: 'errors.not-found.title',
        defaultMessage: 'Page non trouvée',
    },
    notFoundText: {
        id: 'errors.not-found.text',
        defaultMessage: 'La page que vous cherchez n’existe pas.',
    },
    errorInvalidProviderData: {
        id: 'errors.invalid-provider-data.default',
        defaultMessage:
            'Une erreur est survenue lors de la récupération de ce contenu.',
    },
    unavailableContentTitle: {
        id: 'errors.unavailable-content.title',
        defaultMessage: 'Contenu indisponible',
    },
    unavailableContentText: {
        id: 'errors.unavailable-content.text',
        defaultMessage:
            'Le contenu que vous cherchez est momentanément indisponible. Veuillez recharger la page ou réessayer plus tard.',
    },
    closeAlert: {
        id: 'errors.alerts.default',
        defaultMessage:
            'Une erreur est survenue lors de la fermeture de cette alerte. Veuillez réessayer plus tard.',
    },
    maintenanceTitle: {
        id: 'errors.maintenance.title',
        defaultMessage: 'Page en maintenance',
    },
    maintenanceDescription: {
        id: 'errors.maintenance.text',
        defaultMessage:
            'Le contenu que vous cherchez est momentanément indisponible. Veuillez réessayer plus tard.',
    },
});

export const weekDays = defineMessages({
    monday: {
        id: 'components.days.monday',
        defaultMessage: 'Lundi',
    },
    tuesday: {
        id: 'components.days.tuesday',
        defaultMessage: 'Mardi',
    },
    wednesday: {
        id: 'components.days.wednesday',
        defaultMessage: 'Mercredi',
    },
    thursday: {
        id: 'components.days.thursday',
        defaultMessage: 'Jeudi',
    },
    friday: {
        id: 'components.days.friday',
        defaultMessage: 'Vendredi',
    },
    saturday: {
        id: 'components.days.saturday',
        defaultMessage: 'Samedi',
    },
    sunday: {
        id: 'components.days.sunday',
        defaultMessage: 'Dimanche',
    },
});
