import type { FormValues } from 'core';

function transformConditionalSelect(inputId: string, data: FormValues) {
    const { value, ...subWidgetData } = data;
    const subWidgets = subWidgetData[value]
        ? Object.entries(subWidgetData[value])?.map(
              ([subWidgetId, subWidgetValue]) => ({
                  id: `${inputId}.${subWidgetId}`,
                  value: (subWidgetValue as { value?: unknown })?.value,
              })
          )
        : null;

    return {
        id: inputId,
        value,
        ...(subWidgets?.length && { subWidgets }),
    };
}

export default transformConditionalSelect;
