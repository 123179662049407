import { FileFormat } from 'core';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';

type Address = {
    streetNumberAndName?: string | null;
    city?: string | null;
    postalCode?: string | null;
    additionalAddress?: string | null;
    additionalAddress2?: string | null;
};

type Contact = {
    email?: string | null;
    mobilePhoneNumber?: string | null;
    secondPhoneNumber?: string | null;
};

type OtherInformation = {
    emailRefundsNotifications?: boolean;
    commercialInformation?: boolean;
};

export type PersonalData = {
    firstName: string;
    lastName: string;
    birthDate: string;
    socialSecurityNumber: string;
    address: Address;
    contact: Contact;
    otherInformation: OtherInformation;
};

export type GeneralRegime = {
    organizationCode: string | null;
    localRegime: boolean;
};

export enum PrimoStepsEnum {
    WELCOME = 'welcome',
    INTRODUCTION = 'introduction',
    ADD_BENEFICIARIES = 'add_beneficiaries',
    REINFORCING_GUARANTEES = 'reinforcing_guarantees',
    INFORMATIONS = 'informations',
    SOCIAL_SECURITY = 'social_security',
    BANK_DETAILS = 'bank_details',
    VERIFY_SEPA_MANDATE = 'verify_sepa_mandate',
    SEND_CODE_VERIFICATION_SEPA_MANDATE = 'send_code_verification_sepa_mandate',
    DIGITAL_SIGNATURE = 'digital_signature',
    CONFIRMATION = 'confirmation',
}

export type PrimoContextData = {
    currentStep: number;
    previousStep?: number;
    signaturePhoneNumber: string | null;
};

export type WorkflowPrimoData = PersonalData & {
    currentStep: number;
    previousStep?: number;
    contact: Contact & {
        mobilePhoneNumber: { value: string; country: string };
        secondPhoneNumber: { value: string; country: string };
    };
};

export enum BeneficiaryType {
    MAIN = 'main',
    PARTNER = 'partner',
    CHILD = 'child',
}

type Gender = 'male' | 'female';

type SocialSecurityConnection = 'main' | 'partner' | 'child';

export type SocialSecurityNumberDetails = {
    ownSocialSecurityNumber: string;
    socialSecurityConnection: SocialSecurityConnection | null;
    attachedSocialSecurityNumber: string | null;
};

export type BankDetails = {
    requiredSignature: boolean;
    iban: string | null;
    bic: string | null;
    bankName: string | null;
    owner: string | null;
    fileName: string | null;
};

export type PostBankDetails = Pick<
    BankDetails,
    'iban' | 'bic' | 'bankName' | 'owner'
> & {
    signatureLocation: string | null;
};

export type Beneficiary = {
    beneficiaryType: BeneficiaryType | null;
    identifier: number;
    gender: Gender | null;
    lastName: string;
    firstName: string;
    birthDate: string;
    organizationCode: string | null;
    localRegime: boolean;
    otherComplementaryHealthInsurance: boolean;
    socialSecurityNumberDetails: SocialSecurityNumberDetails;
    socialSecurityConnection: SocialSecurityConnection | null;
    attachedSocialSecurityNumber: string | null;
};

type DebitMethod = 'onSalary' | 'contributionRib' | string;

type PmssCalculation = {
    calculationAmount?: string;
    pmssPercentage?: string;
    pmssValue?: string;
};

export type AdditionalProductsPost = {
    option: number | null;
    supplementary: number | null;
};

export type OptionBase = {
    label: string;
    identifier: number;
    amount: number | null;
    debitMethod: DebitMethod;
    contributionStructure: string | null;
    pmssCalculation: PmssCalculation | null;
    pmssValue: string;
    subscribed: boolean;
};

export type AdditionalProducts = {
    base: OptionBase | null;
    options: OptionBase[];
    supplementaries: OptionBase[];
};

export type ContactCenter = {
    phoneNumber: string;
    address: string;
};

export type ConditionGuardProps = PropsWithChildren & {
    primoData: PrimoContextData;
    setPrimoData: Dispatch<SetStateAction<PrimoContextData>>;
};

export type Document = {
    name: string;
    type: FileFormat;
    date: string;
    url: string;
};
