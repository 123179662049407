import { coreSharedMessages } from 'core/lib/shared';
import { defineMessages } from 'react-intl';

export const formErrorMessages = {
    ...defineMessages({
        '66dad313-af0b-4214-8566-6c799be9789c': {
            id: 'errors.forms.after-sending.invalid-length-error',
            defaultMessage:
                'Le BIC est incomplet ; veuillez vérifier votre saisie.',
        },
        'f424c529-7add-4417-8f2d-4b656e4833e2': {
            id: 'errors.forms.after-sending.invalid-bic-characters-error',
            defaultMessage:
                'Le BIC contient des caractères interdits ; veuillez vérifier votre saisie.',
        },
        '00559357-6170-4f29-aebd-d19330aa19cf': {
            id: 'errors.forms.after-sending.invalid-bank-code-error',
            defaultMessage:
                'Le code banque du BIC n’est pas valide ; veuillez vérifier votre saisie.',
        },
        '1ce76f8d-3c1f-451c-9e62-fe9c3ed486ae': {
            id: 'errors.forms.after-sending.invalid-bic-country-code-error',
            defaultMessage:
                'Le code pays du BIC n’est pas valide ; veuillez vérifier votre saisie.',
        },
        '11884038-3312-4ae5-9d04-699f782130c7': {
            id: 'errors.forms.after-sending.invalid-case-error',
            defaultMessage: 'Veuillez saisir le BIC.',
        },
        '29a2c3bb-587b-4996-b6f5-53081364cea5': {
            id: 'errors.forms.after-sending.invalid-bic-error',
            defaultMessage:
                'Le BIC ne correspond pas à l’IBAN ; veuillez vérifier votre saisie.',
        },
        'de78ee2c-bd50-44e2-aec8-3d8228aeadb9': {
            id: 'errors.forms.after-sending.invalid-iban-country-code-error',
            defaultMessage:
                'Le code pays de l’IBAN n’est pas valide ; veuillez vérifier votre saisie.',
        },
        '8d3d85e4-784f-4719-a5bc-d9e40d45a3a5': {
            id: 'errors.forms.after-sending.invalid-iban-characters-error',
            defaultMessage:
                'L’IBAN contient un caractère interdit ; veuillez vérifier votre saisie.',
        },
        'b9401321-f9bf-4dcb-83c1-f31094440795': {
            id: 'errors.forms.after-sending.checksum-failed-error',
            defaultMessage:
                "L’IBAN n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'c8d318f1-2ecc-41ba-b983-df70d225cf5a': {
            id: 'errors.forms.after-sending.invalid-iban-format-error',
            defaultMessage:
                'Le format de l’IBAN n’est pas valide ; veuillez vérifier votre saisie.',
        },
        'e2c259f3-4b46-48e6-b72e-891658158ec8': {
            id: 'errors.forms.after-sending.not-supported-country-code-error',
            defaultMessage:
                'Les IBANs de ce pays ne sont pas acceptés ; veuillez saisir l’IBAN d’un autre pays.',
        },
        '8e179f1b-97aa-4560-a02f-2a8b42e49df7': {
            id: 'errors.forms.after-sending.no-such-choice-error',
            defaultMessage:
                'Cette sélection n’est pas valide ; veuillez faire un autre choix.',
        },
        '8f900c12-61bd-455d-9398-996cd040f7f0': {
            id: 'errors.forms.after-sending.no-such-country-error',
            defaultMessage:
                'Ce pays n’est pas valide ; veuillez saisir un autre pays.',
        },
        '69819696-02ac-4a99-9ff0-14e127c4d1bc': {
            id: 'errors.forms.after-sending.invalid-date-format-error',
            defaultMessage:
                'Le format de la date n’est pas valide ; veuillez saisir une date au format jj/mm/aaaa.',
        },
        '3c184ce5-b31d-4de7-8b76-326da7b2be93': {
            id: 'errors.forms.after-sending.invalid-date-error',
            defaultMessage:
                'La date n’est pas valide ; veuillez vérifier votre saisie.',
        },
        '637c8fff-de3b-4647-a75f-5a25c3015b3f': {
            id: 'errors.forms.after-sending.invalid-date-error-weekend',
            defaultMessage:
                'Seuls les jours de semaine sont acceptés. Veuillez vérifier votre saisie.',
        },
        'f42a14e8-9156-11eb-a8b3-0242ac130003': {
            id: 'errors.forms.after-sending.at-least-one-letter',
            defaultMessage: 'Ce champ ne doit pas comporter de lettres.',
        },
        '1a37a860-9154-11eb-a8b3-0242ac130003': {
            id: 'errors.forms.after-sending.at-least-one-digit',
            defaultMessage: 'Ce champ ne doit pas comporter de chiffres.',
        },
        '9da6943a-915a-11eb-a8b3-0242ac130003': {
            id: 'errors.forms.after-sending.at-least-one-special-character',
            defaultMessage:
                'Ce champ ne doit pas comporter de caractères spéciaux.',
        },
        'bd79c0ab-ddba-46cc-a703-a7a4b08de310': {
            id: 'errors.forms.after-sending.invalid-email-format-error',
            defaultMessage: 'Veuillez saisir une adresse e-mail valide.',
        },
        'd2a3fb6e-7ddc-4210-8fbf-2ab345ce1998': {
            id: 'errors.forms.after-sending.not-found-error',
            defaultMessage:
                'Le fichier est introuvable ; veuillez charger un autre fichier.',
        },
        'c20c92a4-5bfa-4202-9477-28e800e0f6ff': {
            id: 'errors.forms.after-sending.not-readable-error',
            defaultMessage:
                'Le fichier ne peut pas être lu ; veuillez charger un autre fichier.',
        },
        '5d743385-9775-4aa5-8ff5-495fb1e60137': {
            id: 'errors.forms.after-sending.empty-error',
            defaultMessage:
                'Le fichier est vide ; veuillez charger un autre fichier.',
        },
        'df8637af-d466-48c6-a59d-e7126250a654': {
            id: 'errors.forms.after-sending.too-large-error',
            defaultMessage:
                'Le fichier est trop volumineux ; veuillez charger un fichier plus petit.',
        },
        '744f00bc-4389-4c74-92de-9a43cde55534': {
            id: 'errors.forms.after-sending.invalid-mime-type-error',
            defaultMessage:
                'Veuillez vérifier votre fichier : les extensions autorisées sont .png, .jpg ou .pdf.',
        },
        '2c1602c8-8bf2-11eb-8dcd-0242ac130003': {
            id: 'errors.forms.after-sending.at-least-one-forbidden-character',
            defaultMessage:
                'Ce champ contient un caractère interdit, veuillez vérifier votre saisie.',
        },
        '778b7ae0-84d3-481a-9dec-35fdb64b1d78': {
            id: 'errors.forms.after-sending.not-greater-than-error',
            defaultMessage:
                'La valeur saisie doit être supérieure à {validationValue}.',
        },
        'ea4e51d1-3342-48bd-87f1-9e672cd90cad': {
            id: 'errors.forms.after-sending.not-greater-than-or-equal-error',
            defaultMessage:
                'La valeur saisie doit être au moins égale à {validationValue}.',
        },
        '2a8cc50f-58a2-4536-875e-060a2ce69ed5': {
            id: 'errors.forms.after-sending.not-identical-error',
            defaultMessage:
                'Les informations saisies sont différentes ; veuillez compléter les champs de manière identique.',
        },
        '2beabf1c-54c0-4882-a928-05249b26e23b': {
            id: 'errors.forms.after-sending.not-true-error',
            defaultMessage: 'Veuillez cocher la case.',
        },
        '9ff3fdc4-b214-49db-8718-39c315e33d45': {
            id: 'errors.forms.after-sending.too-short-error',
            defaultMessage:
                'Ce champ ne contient pas assez de caractères ; veuillez compléter votre saisie.',
        },
        'd94b19cc-114f-4f44-9cc4-4138e80a87b9': {
            id: 'errors.forms.after-sending.too-long-error',
            defaultMessage:
                'Ce champ contient trop de caractères ; veuillez supprimer des caractères.',
        },
        '35e6a710-aa2e-4719-b58e-24b35749b767': {
            id: 'errors.forms.after-sending.invalid-characters-error',
            defaultMessage:
                'Ce champ contient des caractères interdits ; veuillez vérifier votre saisie.',
        },
        '079d7420-2d13-460c-8756-de810eeb37d2': {
            id: 'errors.forms.after-sending.not-less-than-error',
            defaultMessage:
                'La valeur saisie doit être inférieure à {validationValue}.',
        },
        '30fbb013-d015-4232-8b3b-8f3be97a7e14': {
            id: 'errors.forms.after-sending.not-less-than-or-equal-error',
            defaultMessage:
                'La valeur saisie doit être inférieure ou égale à {validationValue}.',
        },
        'c1051bb4-d103-4f74-8988-acbcafc7fdc3': {
            id: 'errors.forms.after-sending.is-blank-error',
            defaultMessage: 'Veuillez compléter ce champ.',
        },
        'aa2e33da-25c8-4d76-8c6c-812f02ea89dd': {
            id: 'errors.forms.after-sending.is-equal-error',
            defaultMessage:
                'La valeur saisie doit être différente de {validationValue}.',
        },
        '4aaac518-0dda-4129-a6d9-e216b9b454a0': {
            id: 'errors.forms.after-sending.is-identical-error',
            defaultMessage: 'Les données saisies ne sont pas identiques.',
        },
        'ad32d13f-c3d4-423b-909a-857b961eb720': {
            id: 'errors.forms.after-sending.is-null-error',
            defaultMessage: 'Ce champ doit être complété.',
        },
        '6de799aa-8cbf-11eb-8dcd-0242ac130003': {
            id: 'errors.forms.after-sending.not-enough-capital-letters',
            defaultMessage:
                'Ce champ doit comporter au moins {validationValue} {validationValue, plural, one {lettre majuscule} other {lettres majuscules}}.',
        },
        '746860c0-8cbf-11eb-8dcd-0242ac130003': {
            id: 'errors.forms.after-sending.not-enough-digits',
            defaultMessage:
                'Ce champ doit comporter au moins {validationValue} {validationValue, plural, one {chiffre} other {chiffres}}.',
        },
        '7a1c89b0-8cbf-11eb-8dcd-0242ac130003': {
            id: 'errors.forms.after-sending.not-enough-special-characters',
            defaultMessage:
                'Ce champ doit comporter au moins {validationValue} {validationValue, plural, one {caractère spécial} other {caractères spéciaux}}.',
        },
        '4fc94f6a-91fe-11eb-a8b3-0242ac130003': {
            id: 'errors.forms.after-sending.invalid-phone-number',
            defaultMessage:
                'Ce numéro de téléphone n’est pas valide ; veuillez vérifier votre saisie.',
        },
        'e48c673c-96cb-11eb-a8b3-0242ac130003': {
            id: 'errors.forms.after-sending.invalid-professional-id',
            defaultMessage:
                'Cet identifiant professionnel (FINESS ou ADELI) n’est pas valide ; veuillez vérifier votre saisie.',
        },
        'de1e3db3-5ed4-4941-aae4-59f3667cc3a3': {
            id: 'errors.forms.after-sending.regex-failed-error',
            defaultMessage:
                'Le format des informations saisies n’est pas valide ; veuillez vérifier votre saisie.',
        },
        'ba785a8c-82cb-4283-967c-3cf342181b40': {
            id: 'errors.forms.after-sending.invalid-type-error',
            defaultMessage:
                'Le contenu du fichier n’est pas accepté ; veuillez charger un autre fichier.',
        },
        'b496f732-e936-11eb-9a03-0242ac130003': {
            id: 'errors.forms.after-sending.pending-account',
            defaultMessage:
                'Votre dossier est en cours de traitement et certaines fonctionnalités ne sont pas accessibles pour le moment. Si le problème persiste, veuillez contacter votre centre de gestion.',
        },
        'e551e700-9eaa-11eb-a8b3-0242ac130003': {
            id: 'errors.forms.after-sending.current-password',
            defaultMessage:
                'Le mot de passe actuel est invalide ; veuillez vérifier votre saisie.',
        },
        '2e26e850-e937-11eb-9a03-0242ac130003': {
            id: 'errors.forms.after-sending.invalid-code-ss',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '250b7956-e58c-11eb-ba80-0242ac130004': {
            id: 'errors.forms.after-sending.same-iban',
            defaultMessage: 'Le nouvel IBAN est identique à l’actuel',
        },
        'f27e6d6c-261a-4056-b391-6673a623531c': {
            id: 'errors.forms.after-sending.at-least-one-of',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '183ad2de-533d-4796-a439-6d3c3852b549': {
            id: 'errors.forms.after-sending.not-blank',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '11edd7eb-5872-4b6e-9f12-89923999fd0e': {
            id: 'errors.forms.after-sending.choice.too-few',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '9bd98e49-211c-433f-8630-fd1c2d0f08c3': {
            id: 'errors.forms.after-sending.choice.too-many',
            defaultMessage: 'Veuillez sélectionner une seule valeur.',
        },
        '2fa2158c-2a7f-484b-98aa-975522539ff8': {
            id: 'errors.forms.after-sending.collection.missing-field',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '7703c766-b5d5-4cef-ace7-ae0dd82304e9': {
            id: 'errors.forms.after-sending.collection.no-such-field',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'bef8e338-6ae5-4caf-b8e2-50e7b0579e69': {
            id: 'errors.forms.after-sending.count.too-few',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '756b1212-697c-468d-a9ad-50dd783bb169': {
            id: 'errors.forms.after-sending.count.too-many',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '6d99d6c3-1464-4ccf-bdc7-14d083cf455c': {
            id: 'errors.forms.after-sending.not-divisible-by',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '478618a7-95ba-473d-9101-cabd45e49115': {
            id: 'errors.forms.after-sending.not-equal',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '6d55c3f4-e58e-4fe3-91ee-74b492199956': {
            id: 'errors.forms.after-sending.image.size-not-detected',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '7f87163d-878f-47f5-99ba-a8eb723a1ab2': {
            id: 'errors.forms.after-sending.image.too-wide',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '9afbd561-4f90-4a27-be62-1780fc43604a': {
            id: 'errors.forms.after-sending.image.too-narrow',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '7efae81c-4877-47ba-aa65-d01ccb0d4645': {
            id: 'errors.forms.after-sending.image.too-high',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'aef0cb6a-c07f-4894-bc08-1781420d7b4c': {
            id: 'errors.forms.after-sending.image.too-low',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '1b06b97d-ae48-474e-978f-038a74854c43': {
            id: 'errors.forms.after-sending.image.too-few-pixel',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'ee0804e8-44db-4eac-9775-be91aaf72ce1': {
            id: 'errors.forms.after-sending.image.too-many-pixel',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '70cafca6-168f-41c9-8c8c-4e47a52be643': {
            id: 'errors.forms.after-sending.image.ratio-too-big',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '59b8c6ef-bcf2-4ceb-afff-4642ed92f12e': {
            id: 'errors.forms.after-sending.image.ratio-too-small',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '5d41425b-facb-47f7-a55a-de9fbe45cb46': {
            id: 'errors.forms.after-sending.image.square-not-allowed',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '6f895685-7cf2-4d65-b3da-9029c5581d88': {
            id: 'errors.forms.after-sending.image.landscape-not-allowed',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '65608156-77da-4c79-a88c-02ef6d18c782': {
            id: 'errors.forms.after-sending.image.portrait-not-allowed',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '5d4163f3-648f-4e39-87fd-cc5ea7aad2d1': {
            id: 'errors.forms.after-sending.image.corrupted-image',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'd53a91b0-def3-426a-83d7-269da7ab4200': {
            id: 'errors.forms.after-sending.is-false.not-false',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '60d2f30b-8cfa-4372-b155-9656634de120': {
            id: 'errors.forms.after-sending.is-null.not-null',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'd9bcdbfe-a9d6-4bfa-a8ff-da5fd93e0f6d': {
            id: 'errors.forms.after-sending.not-compromised-password',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'ad9a9798-7a99-4df7-8ce9-46e416a1e60b': {
            id: 'errors.forms.after-sending.range.invalid-characters',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '04b91c99-a946-4221-afc5-e65ebac401eb': {
            id: 'errors.forms.after-sending.range.not-in-range',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '2d28afcb-e32e-45fb-a815-01c431a86a69': {
            id: 'errors.forms.after-sending.range.too-high',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '76454e69-502c-46c5-9643-f447d837c4d5': {
            id: 'errors.forms.after-sending.range.too-low',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '57c2f299-1154-4870-89bb-ef3b1f5ad229': {
            id: 'errors.forms.after-sending.url.invalid',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        'aa314679-dac9-4f54-bf97-b2049df8f2a3': {
            id: 'errors.forms.after-sending.uuid.too-short',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '494897dd-36f8-4d31-8923-71a8d5f3000d': {
            id: 'errors.forms.after-sending.uuid.too-long',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '51120b12-a2bc-41bf-aa53-cd73daf330d0': {
            id: 'errors.forms.after-sending.uuid.invalid-characters',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '98469c83-0309-4f5d-bf95-a496dcaa869c': {
            id: 'errors.forms.after-sending.uuid.invalid-hyphen-placement',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '21ba13b4-b185-4882-ac6f-d147355987eb': {
            id: 'errors.forms.after-sending.uuid.invalid-version',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '164ef693-2b9d-46de-ad7f-836201f0c2db': {
            id: 'errors.forms.after-sending.uuid.invalid-variant',
            defaultMessage:
                "Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '454d0ee2-627c-11ec-90d6-0242ac120003': {
            id: 'errors.forms.after-sending.tp-card.too-many-requests',
            defaultMessage:
                "<l>Votre demande n'a pas pu aboutir.</l><l>Avant de pouvoir demander à nouveau l'envoi d'une carte de tiers payant, vous devez attendre un délai de 5 jours à compter de votre précédente demande.</l>",
        },
        '2431c232-6e09-11ec-90d6-0242ac120003': {
            id: 'errors.forms.after-sending.value-should-not-blank',
            defaultMessage: 'Veuillez ajouter au moins un soin.',
        },
        '51c087d8-5c2f-11ec-bf63-0242ac130002': {
            id: 'errors.forms.after-sending.to-few-treatments',
            defaultMessage:
                'Vous avez renseigné moins de soins que ce qui est requis.',
        },
        '24b5b2a6-6d62-11ec-90d6-0242ac120003': {
            id: 'errors.forms.after-sending.to-many-treatments',
            defaultMessage:
                'Vous avez renseigné plus de soins que ce qui est autorisé.',
        },
        '4b6f5c76-22b4-409d-af16-fbe823ba9332': {
            id: 'errors.forms.after-sending.not-equal-length-error',
            defaultMessage:
                'Ce champ doit comporter {validationValue} caractères.',
        },
        '40f3fd08-5727-4b3b-a0f4-12f46948fd4d': {
            id: 'errors.forms.after-sending.could-not-merge-files',
            defaultMessage:
                'Une erreur est survenue lors du traitement de vos fichiers.',
        },
        'cd125a9f-b228-4f2c-85bf-072c7ff9a2d9': {
            id: 'errors.forms.after-sending.unreadable-file',
            defaultMessage:
                'Le fichier ne peut pas être lu ; veuillez charger un autre fichier.',
        },
        '6d5e62db-9096-4f44-b563-1c0daf1d4711': {
            id: 'errors.forms.after-sending.unreadable-file-with-name',
            defaultMessage:
                'Le fichier {file} ne peut pas être lu ; veuillez charger un autre fichier.',
        },
        'cb949612-923b-11eb-a8b3-0242ac130003': {
            id: 'errors.forms.after-sending.file-should-not-exist',
            defaultMessage:
                'Le nombre de fichiers joints est supérieur à la limite autorisée. Veuillez en supprimer.',
        },
        '62453b3d-23ef-4907-ba9e-7f5b8f57a797': {
            id: 'errors.forms.after-sending.value-should-not-be-blank',
            defaultMessage: 'Veuillez joindre au moins un fichier.',
        },
        '66223b31-a258-49a5-9ff9-852bbd9c569f': {
            id: 'errors.forms.after-sending.file-category-should-not-exist',
            defaultMessage:
                'Désolé, une erreur est survenue. Veuillez réessayer plus tard.',
        },
        'd46d986e-2738-4e6c-ad68-19d044d5de06': {
            id: 'errors.forms.after-sending.certificate.too-many-requests',
            defaultMessage:
                "<l>Votre demande n'a pas pu aboutir.</l><l>Avant de pouvoir demander à nouveau une attestation d’appartenance, vous devez attendre 24h à compter de votre précédente demande.</l>",
        },
        'ff0e7a14-7012-4737-8f89-e57e85aaa4d4': {
            id: 'errors.forms.after-sending.file-extension',
            defaultMessage:
                'Veuillez vérifier votre fichier : les extensions autorisées sont .png, .jpg ou .pdf.',
        },
        '95759af1-8e31-4146-8736-ac42935a4242': {
            id: 'errors.forms.after-sending.simulator-guarantee-greatherthanorequal',
            defaultMessage:
                'La valeur de la garantie doit être au moins égale à {validationValue}.',
        },
        'f8e15b86-38cb-11ed-a261-0242ac120002': {
            id: 'errors.forms.after-sending.signature.beneficiaries.no-main-beneficiary',
            defaultMessage:
                'Vous devez renseigner le bénéficiaire principal avant de soumettre votre demande.',
        },
        '3e5ca67e-38cd-11ed-a261-0242ac120002': {
            id: 'errors.forms.after-sending.signature.beneficiaries.no-consecutive-rank',
            defaultMessage:
                'Le rang de vos bénéficiaires n’est pas correct. Veuillez modifier votre saisie et soumettre à nouveau votre demande.',
        },
        'b74ef394-4326-11ed-b878-0242ac120002': {
            id: 'errors.forms.after-sending.signature.beneficiaries.capital-percentage-should-be-100',
            defaultMessage:
                '100% du capital doit être réparti. Veuillez vérifier votre saisie et soumettre à nouveau votre demande.',
        },
        '705a9e79-7b48-4b68-9ada-8b043115815c': {
            id: 'errors.forms.after-sending.invalid-care-date',
            defaultMessage:
                '{validationValue, select, undefined {La date de soins est antérieure à la date de début du contrat.} other {Votre demande a été partiellement envoyée : {validationValue, plural, one {1 soin n\'a} other {{validationValue} soins n\'ont}} pas été transmis car la date de soins renseignée est antérieure à la date de début du contrat. Rendez-vous dans la rubrique "Messages" pour vérifier quels soins ont été transmis.}}',
        },
        'a92acfe9-58ab-4889-bb8f-81ca0f50a254': {
            id: 'errors.forms.after-sending.beneficiary-already-exist',
            defaultMessage: 'Ce bénéficiaire existe déjà.',
        },
        '9af7eda4-8a70-41cd-8ee5-c204aefde254': {
            id: 'errors.healthcare-adult-beneficary.existing-provider-account',
            defaultMessage:
                'Ce bénéficiaire a déjà un compte sur l’application avec cette adresse e-mail et doit contacter le service client de son contrat afin d’en demander la suppression.',
        },
        'df78b406-666f-449b-b84f-064b4aed7087': {
            id: 'errors.healthcare-adult-beneficary.existing-prending-account',
            defaultMessage:
                'Une erreur s’est produite à la création ou à la validation du compte. Veuillez contacter votre service client.',
        },
        '70e929f9-3de0-4ece-b0c5-5c3c86094047': {
            id: 'errors.invalid-provider-data.no-rib',
            defaultMessage:
                'Aucun RIB n’est actuellement renseigné. Veuillez contacter votre centre de gestion.',
        },
        'ae7c903d-1c4c-4c84-8d0a-9475fe94f449': {
            id: 'errors.forms.validations.pattern-type.ssn',
            defaultMessage:
                "Le numéro de Sécurité sociale n'est pas valide ; veuillez vérifier votre saisie.",
        },
        '6a9f4a34-730f-4a20-8edc-9ecdc76e69d5': {
            id: 'errors.forms.after-sending.postal-code-city-mismatch',
            defaultMessage:
                'La ville et le code postal ne correspondent pas ; veuillez vérifier votre saisie.',
        },
        '537ca9cb-349c-4e2d-bbbf-fa8090b2a0db': {
            id: 'errors.forms.after-sending.local-regime-organization-code-mismatch',
            defaultMessage:
                'Le code organisme saisi ne correspond pas au régime Alsace-Moselle ; veuillez vérifier votre saisie.',
        },
        'c969e053-390d-4956-b00a-25fd1073a70b': {
            id: 'errors.forms.after-sending.social-security-number-gender-mismatch',
            defaultMessage:
                'Le numéro de Sécurité sociale ne correspond pas à la civilité ; veuillez vérifier votre saisie.',
        },
        '60f205a3-3e65-4776-9966-4b83a0142315': {
            id: 'errors.forms.after-sending.partner-already-added',
            defaultMessage: 'Vous avez déjà renseigné un conjoint.',
        },
        '0524ebdd-62f8-44fe-b4ba-f20157e0357c': {
            id: 'errors.forms.after-sending.unknown-iban',
            defaultMessage:
                'Votre IBAN n’est pas valide ; veuillez vérifier votre saisie.',
        },
        '03adca95-017d-40de-aefb-0dba06c2875b': {
            id: 'errors.forms.after-sending.phone-one.not-mobile',
            defaultMessage:
                'Veuillez saisir un numéro de téléphone portable principal',
        },
        '35a5b7e0-7423-49aa-b9c8-d843df78986b': {
            id: 'errors.forms.key.ssn',
            defaultMessage:
                'La clé ne correspond pas au numéro de Sécurité sociale saisi.',
        },
    }),
    'a58922b6-a465-11ec-b909-0242ac120002': coreSharedMessages.expiredCode,
};
