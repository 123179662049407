import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
    'health.refunds.requests.dental.socialSecurityReceipt.help': {
        id: 'api.forms.health.refunds.requests.dental.social-security-receipt.help',
        defaultMessage:
            "<l>La télétransmission est un système d'échange de données entre la Sécurité sociale et la complémentaire santé. Lorsqu’elle est activée, elle vous permet d'éviter l’envoi de documents et d'être remboursé plus rapidement. </l><l>Pour connaître votre statut de télétransmission, consultez les informations des bénéficiaires depuis “Mon compte”.</l>",
    },
    'common.socialSecurityReceipt.help': {
        id: 'api.forms.common.social-security-receipt.help',
        defaultMessage:
            "<l>La télétransmission est un système d'échange de données entre la Sécurité sociale et la complémentaire santé. Lorsqu’elle est activée, elle vous permet d'éviter l’envoi de documents et d'être remboursé plus rapidement. </l><l>Pour connaître votre statut de télétransmission, consultez les informations des bénéficiaires depuis “Mon compte”.</l>",
    },
    'health.refunds.requests.other.socialSecurityReceipt.help': {
        id: 'api.forms.health.refunds.requests.other.social-security-receipt.help',
        defaultMessage:
            "<l>La télétransmission est un système d'échange de données entre la Sécurité sociale et la complémentaire santé. Lorsqu’elle est activée, elle vous permet d'éviter l’envoi de documents et d'être remboursé plus rapidement. </l><l>Pour connaître votre statut de télétransmission, consultez les informations des bénéficiaires depuis “Mon compte”.</l>",
    },
    'common.invoiceFile.description': {
        id: 'api.forms.common.invoiceFile.description',
        defaultMessage:
            'Veuillez joindre la facture avec la mention "acquittée" délivrée par le professionnel de santé.',
    },
    'common.quoteFile.description': {
        id: 'api.forms.common.quoteFile.description',
        defaultMessage:
            'Veuillez joindre le devis délivré par le professionnel de santé.',
    },
    'profile.password.new-password.password.description': {
        id: 'api.forms.profile.password.new-password.password.description',
        defaultMessage:
            '<l>Votre mot de passe doit contenir au moins :</l><l>8 caractères</l><l>une majuscule</l><l>une minuscule</l><l>un chiffre</l><l>un caractère spécial.</l>',
    },
    'common.quote.description': {
        id: 'api.forms.common.quote.description',
        defaultMessage:
            "<l>Vous pouvez joindre votre devis et compléter les informations suivantes afin d'obtenir une estimation du remboursement de votre soin.</l>",
    },
    'health.refunds.requests.other.lmv.description': {
        id: 'api.forms.health.refunds.requests.other.lmv.description',
        defaultMessage:
            "<p>Vous pouvez nous transmettre ici une demande de remboursement pour une prestation de soins préalablement remboursée par l'assurance maladie obligatoire hors optique, dentaire, médecine douce, hospitalisation, médicament.</p><p>Pour toute autre prestation non remboursée par l'assurance maladie obligatoire, vous devez nous adresser l'original du document par voie postale.</p><p>Attention : une demande de remboursement non conforme ne pourra pas être traitée.</p>",
    },
    'health.beneficiaries.insurance-rank.healthInsuranceRank.description.rank1':
        {
            id: 'api.forms.health.beneficiaries.insurance-rank.healthInsuranceRank.description.rank1',
            defaultMessage:
                "<p>Dans le cas où vous possédez un autre organisme de complémentaire santé qui intervient en 2<sup>ème</sup> rang, vous vous engagez pour ce bénéficiaire à :</p><p><l>- Demander à l'autre organisme complémentaire de se déconnecter des fichiers de l'organisme d'assurance maladie obligatoire pour n'intervenir qu'en complémentaire santé de 2<sup>ème</sup> rang (surcomplémentaire).</l><l>- Nous transmettre les décomptes originaux émis par l'organisme d'assurance maladie obligatoire dans l'attente de la mise en place de la télétransmission.</l></p>",
        },
    'health.beneficiaries.insurance-rank.healthInsuranceRank.description.rank2':
        {
            id: 'api.forms.health.beneficiaries.insurance-rank.healthInsuranceRank.description.rank2',
            defaultMessage:
                "<p><l>Vous possédez donc un autre organisme de complémentaire santé de 1<sup>er</sup> rang.</l><l>Pour que nous intervenions en tant que complémentaire santé de 2<sup>ème</sup> rang (surcomplémentaire), vous vous engagez pour ce bénéficiaire à :</l></p><p><l>- Nous faire parvenir le détail du contrat souscrit ainsi que la copie de la carte de tiers payant de l'organisme complémentaire de 1<sup>er</sup> rang, afin de permettre de s'assurer du respect de l'article 9 de la Loi 89-1009 du 31/12/1989.</l><l>- Présenter les décomptes papiers originaux émis par l'organisme complémentaire de 1<sup>er</sup> rang pour nous solliciter en tant que surcomplémentaire, en cas de reste à charge.</l><l>- Présenter les factures originales des prestations non prises en charge par l'organisme de 1<sup>er</sup> rang (une notification de non prise en charge établie par cet organisme pourra être demandée, si le détail de la garantie fourni ne permet pas de s'assurer du non remboursement d'une prestation par cet organisme).</l></p><p>À noter que nous ne pourrons plus être destinataire de la procédure de tiers payant pour ce bénéficiaire : le tiers payant ne sera effectué qu'avec l'organisme complémentaire de 1<sup>er</sup> rang. Vous vous engagez à nous faire retour de la carte de tiers payant en votre possession, dès réception de la carte actualisée, stipulant votre nouvelle situation.</p>",
        },
    'health.refunds.requests.dental.lmv.description': {
        id: 'api.forms.health.refunds.requests.dental.lmv.description',
        defaultMessage:
            "<p><l>Vous pouvez nous transmettre ici les justificatifs de dépenses engagées pour les soins pris en charge par l'assurance maladie obligatoire (couronnes, bridges et appareils mobiles définitifs, soins dentaires...).</l><l>Vous pouvez  également nous transmettre les justificatifs des dépenses engagées pour des actes dentaires non pris en charge par l'assurance maladie obligatoire tels que les implants, les piliers, la parodontie, l'endodontie, l'imagerie et l'orthodontie, uniquement si le devis du praticien nous a  préalablement été transmis et a fait l'objet d'une estimation de remboursement.</l></p><p>Pour toute autre demande de remboursement, les documents originaux doivent être transmis par voie postale.</p><p>Attention : une demande de remboursement non conforme ne pourra pas être traitée.</p>",
    },
    'health.refunds.requests.alternative-medicine.description': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.description',
        defaultMessage:
            '<p>Vous avez avancé vos frais de santé ? Vous pouvez faire une demande de remboursement grâce à ce formulaire.</p>',
    },
    'common.refunds.description': {
        id: 'api.forms.common.refunds.description',
        defaultMessage:
            '<p>Vous pouvez faire une demande de remboursement lorsque vous avez avancé des frais chez un professionnel de santé.</p>',
    },
    'health.refunds.requests.cure.description': {
        id: 'api.forms.health.refunds.requests.cure.description',
        defaultMessage:
            "<p>Vous pouvez faire une demande de remboursement lorsque vous avez avancé des frais. Chaque demande de remboursement ne doit concerner qu'une seule prestation et un seul bénéficiaire.</p><p>Attention : une demande de remboursement non conforme ne pourra pas être traitée.</p>",
    },
    'health.contract.compulsory-health-insurance.link.newSsNumber.placeholder':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.link.newSsNumber.placeholder',
            defaultMessage: '0 00 00 00 000 000 00',
        },
    'common.amount.placeholder': {
        id: 'api.forms.common.amount.placeholder',
        defaultMessage: '0',
    },
    'common.phone.placeholder': {
        id: 'api.forms.common.phone.placeholder',
        defaultMessage: '00 00 00 00 00',
    },
    'health.contract.compulsory-health-insurance.dependency.title': {
        id: 'api.forms.health.contract.compulsory-health-insurance.dependency.title',
        defaultMessage: 'Rattachement d’un bénéficiaire à une carte Vitale',
    },
    'health.contract.compulsory-health-insurance.dependency.description': {
        id: 'api.forms.health.contract.compulsory-health-insurance.dependency.description',
        defaultMessage:
            "<l>Vos informations de Sécurité sociale nous sont nécessaires pour la mise en place de services tels que la télétransmission (système d'échange de données entre la Sécurité sociale et la complémentaire santé).</l><l>Pour déclarer ou modifier le rattachement d’un bénéficiaire à une carte Vitale, veuillez compléter ce formulaire.</l>",
    },
    'health.contract.compulsory-health-insurance.dependency.beneficiary.title':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependency.beneficiary.title',
            defaultMessage: 'Bénéficiaire',
        },
    'health.contract.compulsory-health-insurance.dependency.beneficiary.description':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependency.beneficiary.description',
            defaultMessage:
                'Sélectionnez le bénéficiaire concerné par le changement de rattachement.',
        },
    'health.contract.compulsory-health-insurance.dependency.rankToAttachTo.title':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependency.rankToAttachTo.title',
            defaultMessage:
                'À quelle carte Vitale souhaitez-vous rattacher ce bénéficiaire ?',
        },
    'health.contract.compulsory-health-insurance.dependency.rankToAttachTo.attachedToMain':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependency.rankToAttachTo.attachedToMain',
            defaultMessage: "À la carte Vitale de l'adhérent",
        },
    'health.contract.compulsory-health-insurance.dependency.rankToAttachTo.attachedToPartner':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependency.rankToAttachTo.attachedToPartner',
            defaultMessage: 'À la carte Vitale du conjoint',
        },
    'health.contract.compulsory-health-insurance.dependency.rankToAttachTo.attachedToMainAndPartner':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependency.rankToAttachTo.attachedToMainAndPartner',
            defaultMessage:
                "À la carte Vitale de l'adhérent et à celle du conjoint",
        },
    'common.compulsory-health-insurance.certificate.help': {
        id: 'api.forms.common.compulsory-health-insurance.certificate.help',
        defaultMessage:
            "<p><b>Où trouver votre attestation de droits actualisée ?</b></p><p>Vous pouvez obtenir votre attestation de droits (ou attestation de carte Vitale) de plusieurs façons.</p><p><b>Si vous relevez du régime général :</b></p><p>En ligne :</p><p>Vous pouvez télécharger votre attestation de droits depuis votre compte ameli (rubrique « Mes démarches »). Vous pouvez également l'obtenir depuis l'application ameli pour smartphone et tablette, disponible sur l'App Store et Google Play.</p><p>Sur place :</p><p>Vous pouvez vous rendre dans les locaux de votre caisse d'assurance maladie. Sur place, vous pourrez utiliser une borne automatique qui vous fournira votre attestation de droits.</p><p>Par courrier : </p><p>Vous pouvez adresser une demande à votre caisse d'assurance maladie par courrier.</p><p><b>Si vous relevez d’un autre régime :</b></p><p>Si vous êtes affiliée à un autre régime, vous devez contacter votre organisme de rattachement : CMSA, Régime des Etudiants...</p>",
    },
    'health.contract.compulsory-health-insurance.dependancy.rankToAttach.title':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependancy.rankToAttach.title',
            defaultMessage: 'à',
        },
    'health.refunds.filters.thirdParty.option.false.label': {
        id: 'api.forms.health.refunds.filters.thirdParty.option.false.label',
        defaultMessage: 'Un bénéficiaire',
    },
    'health.refunds.filters.thirdParty.option.true.label': {
        id: 'api.forms.health.refunds.filters.thirdParty.option.true.label',
        defaultMessage: 'Un professionnel de santé',
    },
    'health.refunds.requests.alternative-medicine.subCategory.acupuncture': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.acupuncture',
        defaultMessage: 'Acupuncture',
    },
    'health.cost-coverages.requests.hospitalization.hospitalAddress.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalAddress.title',
        defaultMessage: "Adresse de l'établissement hospitalier",
    },
    'health.beneficiaries.new.title': {
        id: 'api.forms.health.beneficiaries.new.title',
        defaultMessage: 'Ajout d’un bénéficiaire',
    },
    'common.certificateFile.title': {
        id: 'api.forms.common.certificateFile.title',
        defaultMessage: 'Ajouter le document',
    },
    'common.compulsory-health-insurance.certificate.title': {
        id: 'api.forms.common.compulsory-health-insurance.certificate.title',
        defaultMessage: 'Attestation de droits actualisée',
    },
    'common.compulsory-health-insurance.certificate.description': {
        id: 'api.forms.common.compulsory-health-insurance.certificate.description',
        defaultMessage:
            "L'attestation de droits est une copie des informations qui sont dans votre carte Vitale. Vous devez au préalable avoir mis à jour votre carte Vitale afin d'obtenir votre attestation de droits actualisée.",
    },
    'common.other': {
        id: 'api.forms.common.other',
        defaultMessage: 'Autre',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.other': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.other',
        defaultMessage: 'Autre',
    },
    'health.messages.other.title': {
        id: 'api.forms.health.messages.other.title',
        defaultMessage: 'Autre question',
    },
    'health.tp-card.request.reason.other': {
        id: 'api.forms.health.tp-card.request.reason.other',
        defaultMessage: 'Autre raison',
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationOtherType.title':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationOtherType.title',
            defaultMessage: 'Autre, précisez :',
        },
    'common.beneficiaries.title': {
        id: 'api.forms.common.beneficiaries.title',
        defaultMessage: 'Bénéficiaire',
    },
    'common.beneficiaries.message.description': {
        id: 'api.forms.common.beneficiaries.message.description',
        defaultMessage:
            'Précisez les informations du bénéficiaire concerné (nom, prénom…).',
    },
    'health.contract.compulsory-health-insurance.dependancy.beneficiary.title':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependancy.beneficiary.title',
            defaultMessage: 'Bénéficiaire à rattacher',
        },
    'health.refunds.filters.thirdParty.title': {
        id: 'api.forms.health.refunds.filters.thirdParty.title',
        defaultMessage: 'Réglé à',
    },
    'health.tp-card.request.reason.cardNotReceived': {
        id: 'api.forms.health.tp-card.request.reason.cardNotReceived',
        defaultMessage: 'Carte non-reçue',
    },
    'health.tp-card.request.reason.lostCard': {
        id: 'api.forms.health.tp-card.request.reason.lostCard',
        defaultMessage: 'Carte perdue',
    },
    'health.beneficiaries.deletion.complaints.description': {
        id: 'api.forms.health.beneficiaries.deletion.complaints.description',
        defaultMessage:
            "Ce formulaire est à compléter uniquement pour une réclamation concernant la radiation d'un bénéficiaire.",
    },
    'health.beneficiaries.teletransmission.complaints.description': {
        id: 'api.forms.health.beneficiaries.teletransmission.complaints.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant la télétransmission.',
    },
    'health.beneficiaries.new.complaints.description': {
        id: 'api.forms.health.beneficiaries.new.complaints.description',
        defaultMessage:
            "Ce formulaire est à compléter uniquement pour une réclamation concernant l'affiliation d'un bénéficiaire.",
    },
    'health.contract.options.complaints.description': {
        id: 'api.forms.health.contract.options.complaints.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant les options de votre contrat.',
    },
    'health.refunds.complaints.dental.description': {
        id: 'api.forms.health.refunds.complaints.dental.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant un remboursement en dentaire.',
    },
    'health.refunds.complaints.optical.description': {
        id: 'api.forms.health.refunds.complaints.optical.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant un remboursement en optique.',
    },
    'health.refunds.complaints.hospitalization.description': {
        id: 'api.forms.health.refunds.complaints.hospitalization.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant un remboursement suite à une hospitalisation.',
    },
    'health.refunds.complaints.other.description': {
        id: 'api.forms.health.refunds.complaints.other.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant un remboursement.',
    },
    'health.cost-coverages.complaints.other.description': {
        id: 'api.forms.health.cost-coverages.complaints.other.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant une prise en charge ou un devis.',
    },
    'health.cost-coverages.complaints.hospitalization.description': {
        id: 'api.forms.health.cost-coverages.complaints.hospitalization.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant une prise en charge ou un devis d’hospitalisation.',
    },
    'health.cost-coverages.complaints.dental.description': {
        id: 'api.forms.health.cost-coverages.complaints.dental.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant une prise en charge ou un devis dentaire.',
    },
    'health.cost-coverages.complaints.optical.description': {
        id: 'api.forms.health.cost-coverages.complaints.optical.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant une prise en charge ou un devis optique.',
    },
    'health.contributions.complaints.description': {
        id: 'api.forms.health.contributions.complaints.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant vos cotisations.',
    },
    'health.warranties.complaints.hospitalization.description': {
        id: 'api.forms.health.warranties.complaints.hospitalization.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant vos garanties hospitalisation.',
    },
    'health.warranties.complaints.dental.description': {
        id: 'api.forms.health.warranties.complaints.dental.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant vos garanties en dentaire.',
    },
    'health.warranties.complaints.optical.description': {
        id: 'api.forms.health.warranties.complaints.optical.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant vos garanties en optique.',
    },
    'health.warranties.complaints.other.description': {
        id: 'api.forms.health.warranties.complaints.other.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant vos garanties.',
    },
    'health.bank-details.complaints.description': {
        id: 'api.forms.health.bank-details.complaints.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant votre RIB.',
    },
    'health.tp-card.complaints.description': {
        id: 'api.forms.health.tp-card.complaints.description',
        defaultMessage:
            'Ce formulaire est à compléter uniquement pour une réclamation concernant votre carte de tiers payant.',
    },
    'health.tp-card.request.description': {
        id: 'api.forms.health.tp-card.request.description',
        defaultMessage:
            "Ce formulaire vous permet de demander l'envoi d'une nouvelle carte de tiers payant.",
    },
    'health.refunds.requests.alternative-medicine.professionalId.help': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.professionalId.help',
        defaultMessage:
            "Ce numéro correspond à l'identifiant de votre praticien. Il se trouve sur votre facture.",
    },
    'profile.email.description': {
        id: 'api.forms.profile.email.description',
        defaultMessage:
            'Cette adresse e-mail ne sera jamais utilisée à des fins commerciales.',
    },
    'health.contract.compulsory-health-insurance.organization-code.title': {
        id: 'api.forms.health.contract.compulsory-health-insurance.organization-code.title',
        defaultMessage:
            'Changement de code organisme de rattachement à la Sécurité sociale',
    },
    'profile.password.newPassword.title': {
        id: 'api.forms.profile.password.new-password.title',
        defaultMessage: 'Changement de mot de passe',
    },
    'health.refunds.requests.alternative-medicine.subCategory.chiropractic': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.chiropractic',
        defaultMessage: 'Chiropractie',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.ambulatory': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.ambulatory',
        defaultMessage: 'Chirurgie ambulatoire',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.surgical': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.surgical',
        defaultMessage: 'Chirurgie générale',
    },
    'common.postalCode.title': {
        id: 'api.forms.common.postalCode.title',
        defaultMessage: 'Code postal',
    },
    'common.postal-address.additionalAddressOne.title': {
        id: 'api.forms.common.postal-address.additionalAddressOne.title',
        defaultMessage: "Complément d'adresse",
    },
    'common.postal-address.additionalAddressTwo.title': {
        id: 'api.forms.common.postal-address.additionalAddressTwo.title',
        defaultMessage: "Complément d'adresse 2",
    },
    'common.moreInformations.title': {
        id: 'api.forms.common.moreInformations.title',
        defaultMessage: "Complément d'information",
    },
    'health.beneficiaries.insurance-rank.healthInsuranceRank.label.rank1': {
        id: 'api.forms.health.beneficiaries.insurance-rank.healthInsuranceRank.label.rank1',
        defaultMessage: 'Complémentaire santé de 1<sup>er</sup> rang',
    },
    'health.beneficiaries.insurance-rank.healthInsuranceRank.label.rank2': {
        id: 'api.forms.health.beneficiaries.insurance-rank.healthInsuranceRank.label.rank2',
        defaultMessage: 'Complémentaire santé de 2<sup>ème</sup> rang',
    },
    'health.bank-details.refunds.confirmation.description': {
        id: 'api.forms.health.bank-details.refunds.confirmation.description',
        defaultMessage:
            "J'autorise ma complémentaire santé à verser les remboursements des frais de santé sur ce nouveau compte bancaire.",
    },
    'health.bank-details.refunds.confirmation.title': {
        id: 'api.forms.health.bank-details.refunds.confirmation.title',
        defaultMessage:
            'Confirmation de modification du compte bancaire pour les remboursements des frais de santé',
    },
    'profile.email.newEmail.confirmation.title': {
        id: 'api.forms.profile.email.newEmail.confirmation.title',
        defaultMessage: 'Confirmer l’adresse e-mail',
    },
    'profile.password.newPassword.confirmation.title': {
        id: 'api.forms.profile.password.new-password.confirmation.title',
        defaultMessage: 'Confirmer le nouveau mot de passe',
    },
    'health.refunds.requests.alternative-medicine.subCategory.dietician': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.dietician',
        defaultMessage: 'Consultation diététicien',
    },
    'health.refunds.requests.alternative-medicine.subCategory.homeopathy': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.homeopathy',
        defaultMessage: 'Consultation homéopathe',
    },
    'health.refunds.requests.alternative-medicine.subCategory.nutritionist': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.nutritionist',
        defaultMessage: 'Consultation nutritionniste',
    },
    'health.refunds.requests.alternative-medicine.subCategory.podiatrist': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.podiatrist',
        defaultMessage: 'Consultation podologue hors semelles orthopédiques',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.convalescence':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.convalescence',
            defaultMessage: 'Convalescence',
        },
    'profile.postal-address.insuredPostalAddress.title': {
        id: 'api.forms.profile.postal-address.insuredPostalAddress.title',
        defaultMessage: 'Coordonnées postales',
    },
    'health.refunds.filters.dateRange.dateStart.title': {
        id: 'api.forms.health.refunds.filters.dateRange.dateStart.title',
        defaultMessage: 'À partir du',
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationDates.dateStart.title':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationDates.dateStart.title',
            defaultMessage: 'Date de début d’hospitalisation',
        },
    'health.refunds.filters.dateRange.dateEnd.title': {
        id: 'api.forms.health.refunds.filters.dateRange.dateEnd.title',
        defaultMessage: "Jusqu'au",
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationDates.dateEnd.title':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationDates.dateEnd.title',
            defaultMessage: 'Date de fin d’hospitalisation',
        },
    'common.invoiceDate.title': {
        id: 'api.forms.common.invoiceDate.title',
        defaultMessage: 'Date de la facture',
    },
    'common.quoteDate.title': {
        id: 'api.forms.common.quoteDate.title',
        defaultMessage: "Date d'émission du devis",
    },
    'health.refunds.requests.alternative-medicine.invoiceAmount.title': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.invoiceAmount.title',
        defaultMessage: 'Montant du soin',
    },
    'health.refunds.requests.alternative-medicine.invoiceDate.title': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.invoiceDate.title',
        defaultMessage: 'Date du soin',
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationDates.title':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationDates.title',
            defaultMessage: 'Dates d’hospitalisation',
        },
    'health.refunds.filters.dateRange.title': {
        id: 'api.forms.health.refunds.filters.dateRange.title',
        defaultMessage: 'Dates de règlement',
    },
    'common.socialSecurityReceipt.title': {
        id: 'api.forms.common.social-security-receipt.title',
        defaultMessage: 'Décompte de Sécurité sociale',
    },
    'health.tp-card.request.title': {
        id: 'api.forms.health.tp-card.request.title',
        defaultMessage: 'Demande de nouvelle carte de tiers payant',
    },
    'health.cost-coverages.requests.hospitalization.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.title',
        defaultMessage: 'Demander une prise en charge hospitalière',
    },
    'health.refunds.requests.alternative-medicine.title': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.title',
        defaultMessage:
            'Demander un remboursement pour une séance d’ostéopathie, de psychologie...',
    },
    'health.refunds.requests.dental.title': {
        id: 'api.forms.health.refunds.requests.dental.title',
        defaultMessage: 'Demander un remboursement pour des soins dentaires',
    },
    'health.refunds.requests.other.title': {
        id: 'api.forms.health.refunds.requests.other.title',
        defaultMessage: 'Demander un remboursement pour un autre type de soin',
    },
    'health.refunds.requests.optical.title': {
        id: 'api.forms.health.refunds.requests.optical.title',
        defaultMessage: 'Demander un remboursement pour un équipement optique',
    },
    'health.refunds.requests.audio.title': {
        id: 'api.forms.health.refunds.requests.audio.title',
        defaultMessage: 'Demande de remboursement pour une aide auditive',
    },
    'health.refunds.requests.cure.title': {
        id: 'api.forms.health.refunds.requests.cure.title',
        defaultMessage: 'Demande de remboursement pour une cure thermale',
    },
    'health.refunds.requests.hospitalization.title': {
        id: 'api.forms.health.refunds.requests.hospitalization.title',
        defaultMessage: 'Demander un remboursement pour une hospitalisation',
    },
    'common.quoteFile.title': {
        id: 'api.forms.common.quoteFile.title',
        defaultMessage: 'Devis',
    },
    'health.cost-coverages.requests.hospitalization.insuredEmail.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.insuredEmail.title',
        defaultMessage: "E-mail de l'assuré principal",
    },
    'health.cost-coverages.requests.hospitalization.hospitalEmail.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalEmail.title',
        defaultMessage: "E-mail de l'établissement hospitalier",
    },
    'common.socialSecurityReceipt.description': {
        id: 'api.forms.common.social-security-receipt.description',
        defaultMessage:
            'En cas de télétransmission non activée, veuillez également joindre votre décompte de Sécurité sociale.',
    },
    'common.ongoing': {
        id: 'api.forms.common.ongoing',
        defaultMessage: 'En cours',
    },
    'health.quotes.dental.title': {
        id: 'api.forms.health.quotes.dental.title',
        defaultMessage: 'Envoi de devis pour un acte dentaire',
    },
    'health.quotes.optical.title': {
        id: 'api.forms.health.quotes.optical.title',
        defaultMessage: 'Envoi de devis pour un équipement optique',
    },
    'health.quotes.other.title': {
        id: 'api.forms.health.quotes.other.title',
        defaultMessage: 'Envoyer un devis pour un autre type de soin',
    },
    'health.quotes.hospitalization.title': {
        id: 'api.forms.health.quotes.hospitalization.title',
        defaultMessage: 'Envoi de devis pour une hospitalisation',
    },
    'health.certificates.taxation.title': {
        id: 'api.forms.health.certificates.taxation.title',
        defaultMessage: "Envoi d'un justificatif d’imposition",
    },
    'health.certificates.disability.title': {
        id: 'api.forms.health.certificates.disability.title',
        defaultMessage: "Envoi d'un justificatif de situation de handicap",
    },
    'health.certificates.retirement.title': {
        id: 'api.forms.health.certificates.retirement.title',
        defaultMessage: "Envoi d'un justificatif de retraite",
    },
    'health.certificates.schooling.title': {
        id: 'api.forms.health.certificates.schooling.title',
        defaultMessage: "Envoi d'un justificatif de scolarité",
    },
    'health.certificates.socialSecurityReceipt.title': {
        id: 'api.forms.health.certificates.social-security-receipt.title',
        defaultMessage: "Envoi d'une attestation de Sécurité sociale",
    },
    'health.certificates.socialSecurityReceipt.description': {
        id: 'api.forms.health.certificates.social-security-receipt.description',
        defaultMessage:
            'Pour la mise en place de la télétransmission, veuillez joindre l’attestation de Sécurité sociale à jour.',
    },
    'health.certificates.job-seeker.title': {
        id: 'api.forms.health.certificates.job-seeker.title',
        defaultMessage: "Envoi d'un justificatif de demandeur d’emploi",
    },
    'health.certificates.job-seeker.description': {
        id: 'api.forms.health.certificates.job-seeker.description',
        defaultMessage:
            "<p>Pour la mise en place de la portabilité, veuillez joindre l’attestation ARE (Allocation chômage d'aide au retour à l'emploi) mentionnant la date à laquelle l’indemnisation débute.</p><p>Durant votre période carence, veuillez nous adresser chaque mois votre justificatif d’actualisation ou votre déclaration de situation mensuelle.</p><p>Afin d’actualiser vos droits, veuillez nous envoyer votre attestation de paiement Pôle emploi tous les mois.</p>",
    },
    'health.certificates.schooling.description': {
        id: 'api.forms.health.certificates.schooling.description',
        defaultMessage:
            'Envoyez le justificatif de scolarité de votre enfant pour prolonger ses droits.',
    },
    'health.refunds.requests.alternative-medicine.subCategory.ergotherapy': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.ergotherapy',
        defaultMessage: 'Ergothérapie',
    },
    'health.refunds.requests.alternative-medicine.subCategory.etiopathy': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.etiopathy',
        defaultMessage: 'Étiopathie',
    },
    'common.email.placeholder': {
        id: 'api.forms.common.email.placeholder',
        defaultMessage: 'exemple@mail.fr',
    },
    'common.invoiceFile.title': {
        id: 'api.forms.common.invoiceFile.title',
        defaultMessage: 'Facture',
    },
    'health.refunds.requests.cure.invoiceFileHousing.title': {
        id: 'api.forms.health.refunds.requests.cure.invoiceFileHousing.title',
        defaultMessage: "Facture de l'hébergement",
    },
    'health.refunds.requests.cure.invoiceFileTransport.title': {
        id: 'api.forms.health.refunds.requests.cure.invoiceFileTransport.title',
        defaultMessage: 'Facture des frais de transport',
    },
    'health.refunds.requests.cure.invoiceFileCure.title': {
        id: 'api.forms.health.refunds.requests.cure.invoiceFileCure.title',
        defaultMessage: 'Facture des soins de cure',
    },
    'health.cost-coverages.requests.hospitalization.description': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.description',
        defaultMessage:
            "Faire une demande de prise en charge avant une hospitalisation vous permet, en fonction de vos garanties, d'éviter une avance de frais (frais médicaux, chambre particulière, TV...).",
    },
    'health.cost-coverages.requests.hospitalization.hospitalFaxNumber.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalFaxNumber.title',
        defaultMessage: "Fax de l'établissement hospitalier",
    },
    'health.refunds.filters.title': {
        id: 'api.forms.health.refunds.filters.title',
        defaultMessage: 'Filtres',
    },
    'common.future': {
        id: 'api.forms.common.future',
        defaultMessage: 'Futur',
    },
    'health.bank-details.refunds.ribDetails.iban.title': {
        id: 'api.forms.health.bank-details.refunds.ribDetails.iban.title',
        defaultMessage: 'IBAN',
    },
    'health.bank-details.refunds.ribDetails.title': {
        id: 'api.forms.health.bank-details.refunds.ribDetails.title',
        defaultMessage: 'Nouvelles informations bancaires',
    },
    'health.contract.portability.activity-started.activityDate.title': {
        id: 'api.forms.health.contract.portability.activity-started.activityDate.title',
        defaultMessage:
            'Vous avez repris ou vous reprenez une activité professionnelle à compter du :',
    },
    'health.contract.portability.activity-started.title': {
        id: 'api.forms.health.contract.portability.activity-started.title',
        defaultMessage:
            "J'ai récemment repris ou je vais reprendre une activité professionnelle.",
    },
    'health.contract.portability.no-activity-no-benefits.endingBenefitsDate.title':
        {
            id: 'api.forms.health.contract.portability.no-activity-no-benefits.ending-benefits-date.title',
            defaultMessage:
                'Vous ne faites plus partie de la liste des demandeurs d’emploi indemnisés par Pôle emploi à compter du :',
        },
    'health.contract.portability.no-activity-no-benefits.title': {
        id: 'api.forms.health.contract.portability.no-activity-no-benefits.title',
        defaultMessage:
            "Je ne fais plus partie de la liste des demandeurs d'emploi indemnisés par Pôle emploi.",
    },
    'health.contract.portability.no-activity-benefits-received.title': {
        id: 'api.forms.health.contract.portability.no-activity-benefits-received.title',
        defaultMessage: 'Je perçois une indemnité par Pôle emploi.',
    },
    'health.beneficiaries.insurance-rank.acknowledgement.description': {
        id: 'api.forms.health.beneficiaries.insurance-rank.acknowledgement.description',
        defaultMessage:
            'Je reconnais avoir pris connaissance des règles applicables en cas de pluralité d’organismes complémentaire santé.',
    },
    'health.contract.portability.no-activity-benefits-postponed.title': {
        id: 'api.forms.health.contract.portability.no-activity-benefits-postponed.title',
        defaultMessage:
            'Je vais percevoir une indemnisation de Pôle emploi après un délai de carence.',
    },
    'common.time.placeholder': {
        id: 'widgets.common.time.placeholder',
        defaultMessage: 'hh:mm',
    },
    'common.date.placeholder': {
        id: 'api.forms.common.date.placeholder',
        defaultMessage: 'jj/mm/aaaa',
    },
    'common.jobSeeker.certificate.title': {
        id: 'api.forms.common.jobSeeker.certificate.title',
        defaultMessage: "Joindre l'attestation Pôle emploi",
    },
    'health.bank-details.refunds.bankFile.description': {
        id: 'api.forms.health.bank-details.refunds.bankFile.description',
        defaultMessage:
            'Seuls les formats pdf, jpeg, jpg ou png sont acceptés.',
    },
    'health.bank-details.refunds.bankFile.title': {
        id: 'api.forms.health.bank-details.refunds.bankFile.title',
        defaultMessage: 'Joindre votre RIB',
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationDocuments.title':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationDocuments.title',
            defaultMessage: 'Justificatif(s)',
        },
    'health.cost-coverages.requests.hospitalization.insuredEmail.description': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.insuredEmail.description',
        defaultMessage:
            'L’adresse e-mail de l’assuré principal doit être renseignée pour le bon suivi de votre dossier et l’envoi d’informations complémentaires.',
    },
    'health.refunds.requests.hospitalization.paymentReceipt.help': {
        id: 'api.forms.health.refunds.requests.hospitalization.paymentReceipt.help',
        defaultMessage:
            'La quittance désigne le document qui atteste votre paiement. Il vous est fourni par l’établissement lorsque vous avez réglé des frais suite à une hospitalisation.',
    },
    'health.contract.compulsory-health-insurance.dependancy.certificate.description':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.dependancy.certificate.description',
            defaultMessage:
                "L'attestation de droits est une copie des informations qui sont dans votre carte Vitale. Votre carte Vitale doit être à jour pour obtenir une attestation actualisée.",
        },
    'health.contract.compulsory-health-insurance.organization-code.certificate.description':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.organization-code.certificate.description',
            defaultMessage:
                "L'attestation de droits est une copie des informations qui sont dans votre carte Vitale. Vous devez au préalable avoir mis à jour votre carte Vitale afin d'obtenir votre attestation de droits actualisée.",
        },

    'common.compulsory-health-insurance.organizationCode.title': {
        id: 'api.forms.common.compulsory-health-insurance.organizationCode.title',
        defaultMessage:
            'Code organisme de rattachement à la Sécurité sociale (9 chiffres)',
    },
    'common.compulsory-health-insurance.organizationCode.description': {
        id: 'api.forms.common.compulsory-health-insurance.organizationCode.description',
        defaultMessage:
            "Le code d’organisme de rattachement (ou code d’affiliation) est un numéro composé de 9 chiffres qui permet de déterminer la caisse d'assurance maladie dont vous dépendez.",
    },
    'common.compulsory-health-insurance.organizationCode.help': {
        id: 'api.forms.common.compulsory-health-insurance.organizationCode.help',
        defaultMessage:
            '<p><b>Où trouver votre code organisme de rattachement ?</b></p><p>Le code d’organisme de rattachement (ou code d’affiliation) figure sur votre attestation de droits (ou attestation de carte Vitale).</p>',
    },
    'common.compulsory-health-insurance.organizationCode.placeholder': {
        id: 'api.forms.common.compulsory-health-insurance.organizationCode.placeholder',
        defaultMessage: '00 000 0000',
    },
    'health.cost-coverages.requests.hospitalization.hospitalFaxNumber.help': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalFaxNumber.help',
        defaultMessage:
            "Le numéro de fax de l'établissement hospitalier peut être renseigné pour faciliter les échanges avec votre centre de gestion. Ce numéro est généralement présent sur le devis ou tout document transmis par l'établissement hospitalier.",
    },
    'health.cost-coverages.requests.hospitalization.insuredPhoneNumber.description':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.insuredPhoneNumber.description',
            defaultMessage:
                'Le numéro de téléphone de l’assuré principal doit être renseigné pour le bon suivi de votre dossier. Veuillez indiquer de préférence un numéro de téléphone mobile afin de recevoir les SMS.',
        },
    'profile.password.newPassword.confirmation.description': {
        id: 'api.forms.profile.password.new-password.confirmation.description',
        defaultMessage: 'Les deux mots de passe doivent être identiques.',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.maternity': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.maternity',
        defaultMessage: 'Maternité',
    },
    'common.message.title': {
        id: 'api.forms.common.message.title',
        defaultMessage: 'Message',
    },
    'health.refunds.requests.alternative-medicine.subCategory.microphysiotherapy':
        {
            id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.microphysiotherapy',
            defaultMessage: 'Microkinésithérapie',
        },
    'health.contract.update.title': {
        id: 'api.forms.health.contract.update.title',
        defaultMessage: 'Mise à jour de mon contrat',
    },
    'health.contract.options.title': {
        id: 'api.forms.health.contract.options.title',
        defaultMessage: 'Modifier la ou les options de mon contrat',
    },
    'profile.postal-address.title': {
        id: 'api.forms.profile.postal-address.title',
        defaultMessage: 'Modifier mes coordonnées postales',
    },
    'profile.phones.title': {
        id: 'api.forms.profile.phones.title',
        defaultMessage: 'Modifier mes numéros de téléphone',
    },
    'profile.email.title': {
        id: 'api.forms.profile.email.title',
        defaultMessage: 'Modifier mon adresse e-mail de contact',
    },
    'profile.email.currentEmail.title': {
        id: 'api.forms.profile.email.currentEmail.title',
        defaultMessage: 'Adresse e-mail actuelle',
    },
    'profile.password.title': {
        id: 'api.forms.profile.password.title',
        defaultMessage: 'Modifier mon mot de passe',
    },
    'health.beneficiaries.insurance-rank.title': {
        id: 'api.forms.health.beneficiaries.insurance-rank.title',
        defaultMessage: "Modifier notre rang d'intervention",
    },
    'health.bank-details.refunds.description': {
        id: 'api.forms.health.bank-details.refunds.description',
        defaultMessage:
            'Modifier ou ajouter un RIB sur lequel vous ou vos bénéficiaires seront remboursés.',
    },
    'health.bank-details.refunds.title': {
        id: 'api.forms.health.bank-details.refunds.title',
        defaultMessage: 'Modifier un RIB de remboursement',
    },
    'common.amount.title': {
        id: 'api.forms.common.amount.title',
        defaultMessage: 'Montant',
    },
    'common.invoiceAmount.title': {
        id: 'api.forms.common.invoiceAmount.title',
        defaultMessage: 'Montant de la facture',
    },
    'common.quoteAmount.title': {
        id: 'api.forms.common.quoteAmount.title',
        defaultMessage: 'Montant du devis',
    },
    'profile.password.currentPassword.title': {
        id: 'api.forms.profile.password.currentPassword.title',
        defaultMessage: 'Mot de passe actuel',
    },
    'health.tp-card.request.reason.title': {
        id: 'api.forms.health.tp-card.request.reason.title',
        defaultMessage: 'Motif de la demande',
    },
    'health.contract.compulsory-health-insurance.link.newSsNumber.title': {
        id: 'api.forms.health.contract.compulsory-health-insurance.link.newSsNumber.title',
        defaultMessage: 'N° de Sécurité sociale',
    },
    'health.refunds.requests.alternative-medicine.subCategory.naturopathy': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.naturopathy',
        defaultMessage: 'Naturopathie',
    },
    'profile.postal-address.insuredPostalAddressName.title': {
        id: 'api.forms.profile.postal-address.insuredPostalAddressName.title',
        defaultMessage: 'Destinataire',
    },
    'health.cost-coverages.requests.hospitalization.hospitalName.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalName.title',
        defaultMessage: "Nom de l'établissement hospitalier",
    },
    'health.beneficiaries.insurance-rank.healthInsuranceRank.title': {
        id: 'api.forms.health.beneficiaries.insurance-rank.healthInsuranceRank.title',
        defaultMessage: "Notre rang d'intervention",
    },
    'profile.password.newPassword.password.title': {
        id: 'api.forms.profile.password.new-password.password.title',
        defaultMessage: 'Nouveau mot de passe',
    },
    'profile.email.newEmail.title': {
        id: 'api.forms.profile.email.newEmail.title',
        defaultMessage: 'Nouvelle adresse e-mail',
    },
    'common.streetNumber.title': {
        id: 'api.forms.common.streetNumber.title',
        defaultMessage: 'Numéro de voie',
    },
    'health.refunds.requests.alternative-medicine.professionalId.title': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.professionalId.title',
        defaultMessage: 'Numéro FINESS/ADELI',
    },
    'health.refunds.requests.alternative-medicine.professionalId.description': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.professionalId.description',
        defaultMessage:
            '<l>Exemple :</l><l>- N° FINESS : 000000000,</l><l>- N° ADELI : 00 00 0000 0.</l>',
    },
    'health.refunds.requests.optical.prescriptionFile.title': {
        id: 'api.forms.health.refunds.requests.optical.prescription-file.title',
        defaultMessage: 'Ordonnance',
    },
    'health.refunds.requests.alternative-medicine.subCategory.osteodensitometry':
        {
            id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.osteodensitometry',
            defaultMessage: 'Ostéodensitométrie',
        },
    'health.refunds.requests.alternative-medicine.subCategory.osteopathy': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.osteopathy',
        defaultMessage: 'Ostéopathie',
    },
    'common.country.title': {
        id: 'api.forms.common.country.title',
        defaultMessage: 'Pays',
    },
    'health.refunds.requests.alternative-medicine.subCategory.pedicure-hn': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.pedicure-hn',
        defaultMessage: 'Pédicurie HN',
    },
    'common.attachmentFiles.title': {
        id: 'api.forms.common.attachmentFiles.title',
        defaultMessage: 'Pièce(s) jointe(s)',
    },
    'common.attachment-file.title': {
        id: 'api.forms.common.attachment-files.title',
        defaultMessage: 'Pièce(s) jointe(s)',
    },
    'health.refunds.requests.alternative-medicine.subCategory.help': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.help',
        defaultMessage:
            'Pour avoir le détail des médecines douces prises en charge par votre contrat, veuillez vous référer à votre notice d’information.',
    },
    'health.contract.portability.no-activity-benefits-received.description': {
        id: 'api.forms.health.contract.portability.no-activity-benefits-received.description',
        defaultMessage:
            'Pour continuer à bénéficier des droits à la portabilité de votre contrat santé, veuillez nous faire parvenir votre dernier justificatif d’indemnisation par Pôle emploi.',
    },
    'health.refunds.requests.cure.invoiceFileHousing.description': {
        id: 'api.forms.health.refunds.requests.cure.invoiceFileHousing.description',
        defaultMessage:
            'Pour vos frais d’hébergement, veuillez joindre la facture originale avec la mention "acquittée" et le numéro d\'agrément du bailleur.',
    },
    'health.contract.options.message.placeholder': {
        id: 'api.forms.health.contract.options.message.placeholder',
        defaultMessage:
            'Précisez l’option à laquelle vous souhaitez souscrire ou celle que vous souhaitez supprimer de votre contrat.',
    },
    'health.contract.options.message.description': {
        id: 'api.forms.health.contract.options.message.description',
        defaultMessage:
            'Précisez l’option à laquelle vous souhaitez souscrire ou celle que vous souhaitez supprimer de votre contrat.',
    },
    'common.beneficiaries.message.placeholder': {
        id: 'api.forms.common.beneficiaries.message.placeholder',
        defaultMessage:
            'Précisez les informations du bénéficiaire concerné (nom, prénom…).',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.psychiatry': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.psychiatry',
        defaultMessage: 'Psychiatrie générale',
    },
    'health.refunds.requests.alternative-medicine.subCategory.psychology': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.psychology',
        defaultMessage: 'Psychologie',
    },
    'health.refunds.requests.alternative-medicine.subCategory.child-psychology':
        {
            id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.child-psychology',
            defaultMessage: 'Psychologie de l’enfant',
        },
    'health.refunds.requests.alternative-medicine.subCategory.psychomotricity':
        {
            id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.psychomotricity',
            defaultMessage: 'Psychomotricité',
        },
    'health.refunds.requests.alternative-medicine.subCategory.child-psychomotricity':
        {
            id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.child-psychomotricity',
            defaultMessage: 'Psychomotricité de l’enfant',
        },
    'health.refunds.requests.alternative-medicine.subCategory.psychotherapy': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.psychotherapy',
        defaultMessage: 'Psychothérapie',
    },
    'health.refunds.requests.alternative-medicine.subCategory.child-psychotherapy':
        {
            id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.child-psychotherapy',
            defaultMessage: 'Psychothérapie de l’enfant',
        },
    'health.warranties.messages.other.title': {
        id: 'api.forms.health.warranties.messages.other.title',
        defaultMessage: 'Question concernant mes garanties',
    },
    'health.warranties.messages.dental.title': {
        id: 'api.forms.health.warranties.messages.dental.title',
        defaultMessage: 'Question concernant mes garanties dentaires',
    },
    'health.warranties.messages.hospitalization.title': {
        id: 'api.forms.health.warranties.messages.hospitalization.title',
        defaultMessage: 'Question concernant mes garanties hospitalisation',
    },
    'health.warranties.messages.optical.title': {
        id: 'api.forms.health.warranties.messages.optical.title',
        defaultMessage: 'Question concernant mes garanties optiques',
    },
    'health.bank-details.messages.title': {
        id: 'api.forms.health.bank-details.messages.title',
        defaultMessage: 'Question relative à mon RIB',
    },
    'health.contributions.messages.title': {
        id: 'api.forms.health.contributions.messages.title',
        defaultMessage: 'Question sur mes cotisations',
    },
    'health.refunds.messages.other.title': {
        id: 'api.forms.health.refunds.messages.other.title',
        defaultMessage: 'Questions concernant mes remboursements',
    },
    'health.refunds.messages.hospitalization.title': {
        id: 'api.forms.health.refunds.messages.hospitalization.title',
        defaultMessage:
            'Questions concernant mes remboursements d’hospitalisation',
    },
    'health.refunds.messages.dental.title': {
        id: 'api.forms.health.refunds.messages.dental.title',
        defaultMessage: 'Questions concernant mes remboursements dentaires',
    },
    'health.beneficiaries.teletransmission.title': {
        id: 'api.forms.health.beneficiaries.teletransmission.title',
        defaultMessage: 'Question concernant la télétransmission',
    },
    'health.cost-coverages.messages.hospitalization.title': {
        id: 'api.forms.health.cost-coverages.messages.hospitalization.title',
        defaultMessage:
            'Questions concernant une prise en charge hospitalière ou un devis d’hospitalisation',
    },
    'health.cost-coverages.messages.other.title': {
        id: 'api.forms.health.cost-coverages.messages.other.title',
        defaultMessage: 'Questions concernant une prise en charge ou un devis',
    },
    'health.cost-coverages.messages.dental.title': {
        id: 'api.forms.health.cost-coverages.messages.dental.title',
        defaultMessage:
            'Questions concernant une prise en charge ou un devis dentaire',
    },
    'health.cost-coverages.messages.optical.title': {
        id: 'api.forms.health.cost-coverages.messages.optical.title',
        defaultMessage:
            'Questions concernant une prise en charge ou un devis optique',
    },
    'health.tp-card.messages.title': {
        id: 'api.forms.health.tp-card.messages.title',
        defaultMessage: 'Questions sur ma carte de tiers payant',
    },
    'health.refunds.messages.optical.title': {
        id: 'api.forms.health.refunds.messages.optical.title',
        defaultMessage: 'Questions sur mes remboursements optiques',
    },
    'health.refunds.requests.hospitalization.paymentReceipt.title': {
        id: 'api.forms.health.refunds.requests.hospitalization.paymentReceipt.title',
        defaultMessage: 'Quittance de règlement',
    },
    'common.compulsory-health-insurance.localFund.description': {
        id: 'api.forms.common.compulsory-health-insurance.localFund.description',
        defaultMessage: 'Rattachement au régime local Alsace-Moselle',
    },
    'health.contract.compulsory-health-insurance.dependancy.title': {
        id: 'api.forms.health.contract.compulsory-health-insurance.dependancy.title',
        defaultMessage:
            "Rattacher un bénéficiaire à la carte Vitale d'un autre bénéficiaire",
    },
    'health.contract.compulsory-health-insurance.link.title': {
        id: 'api.forms.health.contract.compulsory-health-insurance.link.title',
        defaultMessage:
            'Ajout d’un numéro de Sécurité sociale à un bénéficiaire',
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationType.placeholder':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationType.placeholder',
            defaultMessage: 'Rechercher un type d’hospitalisation',
        },
    'health.refunds.requests.alternative-medicine.subCategory.placeholder': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.placeholder',
        defaultMessage: 'Sélectionner une catégorie',
    },
    'health.beneficiaries.new.complaints.title': {
        id: 'api.forms.health.beneficiaries.new.complaints.title',
        defaultMessage: "Réclamation concernant l’ajout d'un bénéficiaire",
    },
    'health.beneficiaries.deletion.complaints.title': {
        id: 'api.forms.health.beneficiaries.deletion.complaints.title',
        defaultMessage:
            "Réclamation concernant la résiliation d'un bénéficiaire",
    },
    'health.contract.options.complaints.title': {
        id: 'api.forms.health.contract.options.complaints.title',
        defaultMessage: 'Réclamation concernant les options de mon contrat',
    },
    'health.tp-card.complaints.title': {
        id: 'api.forms.health.tp-card.complaints.title',
        defaultMessage: 'Réclamation concernant ma carte de tiers payant',
    },
    'health.beneficiaries.teletransmission.complaints.title': {
        id: 'api.forms.health.beneficiaries.teletransmission.complaints.title',
        defaultMessage: 'Réclamation concernant ma télétransmission',
    },
    'health.contributions.complaints.title': {
        id: 'api.forms.health.contributions.complaints.title',
        defaultMessage: 'Réclamation concernant mes cotisations',
    },
    'health.warranties.complaints.other.title': {
        id: 'api.forms.health.warranties.complaints.other.title',
        defaultMessage: 'Réclamation concernant mes garanties',
    },
    'health.warranties.complaints.dental.title': {
        id: 'api.forms.health.warranties.complaints.dental.title',
        defaultMessage: 'Réclamation concernant mes garanties dentaires',
    },
    'health.warranties.complaints.hospitalization.title': {
        id: 'api.forms.health.warranties.complaints.hospitalization.title',
        defaultMessage: 'Réclamation concernant mes garanties hospitalisation',
    },
    'health.warranties.complaints.optical.title': {
        id: 'api.forms.health.warranties.complaints.optical.title',
        defaultMessage: 'Réclamation concernant mes garanties optiques',
    },
    'health.bank-details.complaints.title': {
        id: 'api.forms.health.bank-details.complaints.title',
        defaultMessage: 'Réclamation concernant mon RIB',
    },
    'health.refunds.complaints.other.title': {
        id: 'api.forms.health.refunds.complaints.other.title',
        defaultMessage: 'Réclamation concernant un remboursement',
    },
    'health.refunds.complaints.dental.title': {
        id: 'api.forms.health.refunds.complaints.dental.title',
        defaultMessage: 'Réclamation concernant un remboursement dentaire',
    },
    'health.refunds.complaints.hospitalization.title': {
        id: 'api.forms.health.refunds.complaints.hospitalization.title',
        defaultMessage:
            "Réclamation concernant un remboursement d'hospitalisation",
    },
    'health.refunds.complaints.optical.title': {
        id: 'api.forms.health.refunds.complaints.optical.title',
        defaultMessage: 'Réclamation concernant un remboursement optique',
    },
    'health.cost-coverages.complaints.other.title': {
        id: 'api.forms.health.cost-coverages.complaints.other.title',
        defaultMessage:
            'Réclamation concernant une prise en charge ou un devis',
    },
    'health.cost-coverages.complaints.hospitalization.title': {
        id: 'api.forms.health.cost-coverages.complaints.hospitalization.title',
        defaultMessage:
            'Réclamation concernant une prise en charge ou un devis d’hospitalisation',
    },
    'health.cost-coverages.complaints.dental.title': {
        id: 'api.forms.health.cost-coverages.complaints.dental.title',
        defaultMessage:
            'Réclamation concernant une prise en charge ou un devis dentaire',
    },
    'health.cost-coverages.complaints.optical.title': {
        id: 'api.forms.health.cost-coverages.complaints.optical.title',
        defaultMessage:
            'Réclamation concernant une prise en charge ou un devis optique',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.physiotherapy':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.physiotherapy',
            defaultMessage: 'Rééducation fonctionnelle',
        },
    'health.refunds.requests.alternative-medicine.subCategory.reflexology': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.reflexology',
        defaultMessage: 'Réflexologie',
    },
    'messages.answer.message.title': {
        id: 'api.forms.messages.answer.message.title',
        defaultMessage: 'Réponse',
    },
    'health.beneficiaries.deletion.title': {
        id: 'api.forms.health.beneficiaries.deletion.title',
        defaultMessage: 'Résiliation d’un bénéficiaire',
    },
    'common.quote.beneficiary.description': {
        id: 'api.forms.common.quote.beneficiary.description',
        defaultMessage: 'Sélectionnez le bénéficiaire concerné par ce devis.',
    },
    'health.certificates.socialSecurityReceipt.beneficiary.description': {
        id: 'api.forms.health.certificates.social-security-receipt.beneficiary.description',
        defaultMessage:
            "Sélectionnez le bénéficiaire concerné par l'attestation de Sécurité sociale.",
    },
    'health.certificates.socialSecurityReceipt.beneficiary.help': {
        id: 'api.forms.health.certificates.social-security-receipt.beneficiary.help',
        defaultMessage:
            'Sur le site de la Sécurité sociale, vous avez la possibilité, en cas de chevauchement, de sélectionner la complémentaire santé souhaitée.',
    },
    'health.certificates.job-seeker.beneficiary.description': {
        id: 'api.forms.health.certificates.job-seeker.beneficiary.description',
        defaultMessage:
            "Sélectionnez le bénéficiaire concerné par l'attestation Pôle emploi.",
    },
    'health.certificates.taxation.beneficiary.description': {
        id: 'api.forms.health.certificates.taxation.beneficiary.description',
        defaultMessage:
            'Sélectionnez le bénéficiaire concerné par le justificatif d’imposition.',
    },
    'health.certificates.disability.beneficiary.description': {
        id: 'api.forms.health.certificates.disability.beneficiary.description',
        defaultMessage:
            'Sélectionnez le bénéficiaire concerné par le justificatif de handicap.',
    },
    'health.certificates.retirement.beneficiary.description': {
        id: 'api.forms.health.certificates.retirement.beneficiary.description',
        defaultMessage:
            'Sélectionnez le bénéficiaire concerné par le justificatif de retraite.',
    },
    'health.certificates.schooling.beneficiary.description': {
        id: 'api.forms.health.certificates.schooling.beneficiary.description',
        defaultMessage:
            'Sélectionnez le bénéficiaire concerné par le justificatif de scolarité.',
    },
    'health.refunds.requests.optical.beneficiary.description': {
        id: 'api.forms.health.refunds.requests.optical.beneficiary.description',
        defaultMessage: "Sélectionnez le bénéficiaire de l'équipement optique.",
    },
    'common.act.beneficiary.description': {
        id: 'api.forms.common.act.beneficiary.description',
        defaultMessage: 'Sélectionnez le bénéficiaire du soin.',
    },
    'health.bank-details.refunds.beneficiary.description': {
        id: 'api.forms.health.bank-details.refunds.beneficiary.description',
        defaultMessage:
            'Sélectionnez le bénéficiaire pour lequel vous souhaitez modifier le RIB de remboursement.',
    },
    'profile.postal-address.gfp.description': {
        id: 'api.forms.profile.postal-address.gfp.description',
        defaultMessage:
            "Seules les adresses situées en France sont acceptées. Pour une adresse située à l'étranger, veuillez vous rapprocher de votre service client.",
    },
    'health.cost-coverages.requests.hospitalization.gfp.hospitalAddress.description':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.gfp.hospitalAddress.description',
            defaultMessage:
                'Seuls les établissements hospitaliers situés en France sont pris en compte. Si votre hospitalisation a lieu dans un autre pays, veuillez contacter votre centre de gestion.',
        },
    'common.certificateFile.description': {
        id: 'api.forms.common.certificateFile.description',
        defaultMessage: 'Veuillez joindre votre justificatif.',
    },
    'common.certificateFile.help': {
        id: 'api.forms.common.certificateFile.help',
        defaultMessage:
            "<p>ARE : Allocation chômage d’aide au retour à l’emploi. Les justificatifs ASP (Allocation de sécurisation professionnelle) et ACRE (Aide à la Reprise ou la Création d'Entreprise) sont également acceptés.</p><p>En cas d’arrêt maladie, veuillez nous adresser les indemnités journalières Sécurité sociale.</p>",
    },
    'health.bank-details.refunds.ribDetails.description': {
        id: 'api.forms.health.bank-details.refunds.ribDetails.description',
        defaultMessage:
            '<l>Seuls les IBAN français et monégasques sont acceptés.</l><l>Pour un IBAN étranger, veuillez vous rapprocher de votre centre de gestion. </l>',
    },
    'health.refunds.requests.alternative-medicine.subCategory.shiatsu': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.shiatsu',
        defaultMessage: 'Shiatsu',
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationType.gfp.help':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationType.gfp.help',
            defaultMessage:
                'Si votre type d’hospitalisation n’est pas présent dans la liste, veuillez sélectionner “Autre”.',
        },
    'health.cost-coverages.requests.hospitalization.hospitalizationType.lmv.help':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationType.lmv.help',
            defaultMessage:
                'Si votre type d’hospitalisation n’est pas présent dans la liste, veuillez sélectionner “médecine générale”.',
        },
    'health.bank-details.refunds.ribDetails.iban.placeholder': {
        id: 'api.forms.health.bank-details.refunds.ribDetails.iban.placeholder',
        defaultMessage: 'FR00 0000 0000 0000 0000 0000 000',
    },
    'health.complaints.description': {
        id: 'api.forms.health.complaints.description',
        defaultMessage: `Ce formulaire est à compléter uniquement pour une réclamation.`,
    },
    'health.complaints.title': {
        id: 'api.forms.health.complaints.title',
        defaultMessage: 'Réclamation',
    },
    'profile.password.currentPassword.description': {
        id: 'api.forms.profile.password.currentPassword.description',
        defaultMessage:
            'Si vous avez oublié votre mot de passe, veuillez vous déconnecter et cliquer sur "Identifiant ou mot de passe oublié ?".',
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationOtherType.description':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationOtherType.description',
            defaultMessage:
                'Si vous avez sélectionné "Autre", veuillez indiquer le type d\'hospitalisation.',
        },
    'health.cost-coverages.requests.hospitalization.hospitalizationDates.help':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationDates.help',
            defaultMessage:
                'Si vous ne connaissez pas la date exacte de votre hospitalisation (par exemple pour un accouchement), veuillez renseigner la date estimée de votre hospitalisation.',
        },
    'health.cost-coverages.requests.hospitalization.hospitalizationDates.description':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationDates.description',
            defaultMessage:
                'Si vous ne connaissez pas la date exacte de votre hospitalisation (par exemple pour un accouchement), veuillez renseigner la date estimée de votre hospitalisation.',
        },
    'health.contract.portability.no-activity-benefits-received.attachmentFiles.description':
        {
            id: 'api.forms.health.contract.portability.no-activity-benefits-received.attachmentFiles.description',
            defaultMessage:
                "Si vous ne pouvez pas joindre le justificatif, merci de nous l'envoyer par courrier postal en nous précisant vos coordonnées et votre situation.",
        },
    'health.refunds.requests.alternative-medicine.subCategory.sophrology': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.sophrology',
        defaultMessage: 'Sophrologie',
    },
    'common.subject.title': {
        id: 'api.forms.common.subject.title',
        defaultMessage: 'Sujet',
    },
    'health.cost-coverages.requests.hospitalization.insuredPhoneNumber.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.insuredPhoneNumber.title',
        defaultMessage: "Téléphone de l'assuré principal",
    },
    'health.cost-coverages.requests.hospitalization.hospitalPhoneNumber.title':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalPhoneNumber.title',
            defaultMessage: "Téléphone de l'établissement hospitalier",
        },
    'profile.phones.phoneNumberOne.title': {
        id: 'api.forms.profile.phones.phoneNumberOne.title',
        defaultMessage: 'Téléphone principal',
    },
    'profile.phones.phoneNumberTwo.title': {
        id: 'api.forms.profile.phones.phoneNumberTwo.title',
        defaultMessage: 'Téléphone secondaire',
    },
    'health.bank-details.refunds.ribDetails.owner.title': {
        id: 'api.forms.health.bank-details.refunds.ribDetails.owner.title',
        defaultMessage: 'Titulaire du compte',
    },
    'health.refunds.filters.thirdParty.option.none.label': {
        id: 'api.forms.health.refunds.filters.thirdParty.option.none.label',
        defaultMessage: 'Tous',
    },
    'common.hospitalizationType.title': {
        id: 'api.forms.common.hospitalizationType.title',
        defaultMessage: 'Type d’hospitalisation',
    },
    'health.refunds.requests.alternative-medicine.subCategory.title': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.subCategory.title',
        defaultMessage: 'Type de médecine douce',
    },
    'common.streetName.title': {
        id: 'api.forms.common.streetName.title',
        defaultMessage: 'Type et nom de voie',
    },
    'health.cost-coverages.requests.hospitalization.beneficiary.description': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.beneficiary.description',
        defaultMessage:
            'Veuillez indiquer le bénéficiaire concerné par l’hospitalisation.',
    },
    'health.refunds.requests.alternative-medicine.invoiceFile.description': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.invoiceFile.description',
        defaultMessage:
            'Veuillez joindre la facture avec la mention "acquittée" délivrée par le professionnel.',
    },
    'health.refunds.requests.hospitalization.invoiceFile.description': {
        id: 'api.forms.health.refunds.requests.hospitalization.invoiceFile.description',
        defaultMessage:
            'Veuillez joindre la facture avec la mention "acquittée" délivrée par l\'établissement hospitalier.',
    },
    'health.refunds.requests.hospitalization.invoiceFile.help': {
        id: 'api.forms.health.refunds.requests.hospitalization.invoiceFile.help',
        defaultMessage:
            '<l>- En établissement de santé public : avis des sommes à payer et attestation de paiement.</l><l>- En établissements en budget global : attestation du fonctionnement en budget global et notes d’honoraires.</l><l>- En établissement de santé privé d’intérêt collectif : bordereau de facturation acquittée et notes d’honoraires.</l><l>- En établissement de santé privé non conventionné : honoraires des hôpitaux non conventionnés, décompte de Sécurité sociale.</l><l>- Dépassement d’honoraire : bordereau de facturation - facture acquittée.</l>',
    },
    'health.refunds.requests.cure.invoiceFileCure.description': {
        id: 'api.forms.health.refunds.requests.cure.invoiceFileCure.description',
        defaultMessage:
            'Veuillez joindre la facture avec la mention "acquittée" délivrée par l\'établissement thermal.',
    },
    'health.refunds.requests.hospitalization.paymentReceipt.description': {
        id: 'api.forms.health.refunds.requests.hospitalization.paymentReceipt.description',
        defaultMessage:
            "Veuillez joindre la quittance fournie par l'établissement hospitalier.",
    },
    'health.cost-coverages.requests.hospitalization.hospitalizationDocuments.description':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospitalizationDocuments.description',
            defaultMessage:
                "Veuillez joindre les documents fournis par l'établissement hospitalier.",
        },
    'health.refunds.requests.cure.invoiceFileTransport.description': {
        id: 'api.forms.health.refunds.requests.cure.invoiceFileTransport.description',
        defaultMessage:
            'Veuillez joindre les factures de frais de transport (carburant, péages, etc.).',
    },
    'health.contract.update.attachmentFiles.description': {
        id: 'api.forms.health.contract.update.attachmentFiles.description',
        defaultMessage:
            'Veuillez joindre tout document permettant d’attester votre changement de situation : attestation de régime obligatoire, RIB, attestation Pôle emploi…',
    },
    'health.contact.appointment.attachmentFiles.description': {
        id: 'api.forms.health.contact.appointment.attachmentFiles.description',
        defaultMessage:
            'Veuillez joindre tout document utile à la préparation de ce rendez-vous.',
    },
    'health.refunds.requests.optical.prescriptionFile.description': {
        id: 'api.forms.health.refunds.requests.optical.prescription-file.description',
        defaultMessage:
            'Veuillez nous adresser une ordonnance en cours de validité, afin de percevoir le remboursement prévu par votre contrat.',
    },
    'common.city.title': {
        id: 'api.forms.common.city.title',
        defaultMessage: 'Ville',
    },
    'health.contract.compulsory-health-insurance.link.description': {
        id: 'api.forms.health.contract.compulsory-health-insurance.link.description',
        defaultMessage:
            "<l>Vos informations de Sécurité sociale nous sont nécessaires pour la mise en place de services tels que la télétransmission (système d'échange de données entre la Sécurité sociale et la complémentaire santé).</l><l>Pour déclarer qu’un bénéficiaire possède désormais sa propre carte Vitale, veuillez compléter ce formulaire.</l>",
    },
    'health.contract.compulsory-health-insurance.organization-code.description':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.organization-code.description',
            defaultMessage:
                "<l>Vos informations de Sécurité sociale nous sont nécessaires pour la mise en place de services tels que la télétransmission (système d'échange de données entre la Sécurité sociale et la complémentaire santé).</l><l>Pour nous faire part d'un changement de caisse d'assurance maladie, veuillez compléter ce formulaire.</l>",
        },
    'profile.phones.description': {
        id: 'api.forms.profile.phones.description',
        defaultMessage:
            'Vos numéros de téléphone ne seront jamais utilisés à des fins commerciales.',
    },
    'profile.postal-address.insuredPostalAddressName.description': {
        id: 'api.forms.profile.postal-address.insuredPostalAddressName.description',
        defaultMessage: 'Veuillez renseigner le destinataire des courriers.',
    },
    'common.attachmentFiles.description': {
        id: 'api.forms.common.attachmentFiles.description',
        defaultMessage:
            'Vous pouvez joindre tout document permettant de traiter votre demande.',
    },
    'common.attachment-file.description': {
        id: 'api.forms.common.attachment-files.description',
        defaultMessage:
            'Vous pouvez joindre tout document permettant de traiter votre demande.',
    },
    'health.beneficiaries.insurance-rank.description': {
        id: 'api.forms.health.beneficiaries.insurance-rank.description',
        defaultMessage:
            "Vous pouvez modifier notre rang d'intervention : si vous adhérez à deux organismes de complémentaire santé, seul celui défini en 1<sup>er</sup> rang peut être relié à la Sécurité sociale par télétransmission.",
    },
    'health.beneficiaries.new.description': {
        id: 'api.forms.health.beneficiaries.new.description',
        defaultMessage:
            'Vous pouvez nous contacter pour ajouter un bénéficiaire à votre contrat. Si votre contrat le permet, votre demande sera prise en compte. Dans le cas contraire, nous vous informerons que cette démarche doit être effectuée auprès de votre employeur.',
    },
    'health.contract.update.description': {
        id: 'api.forms.health.contract.update.description',
        defaultMessage:
            'Vous pouvez nous contacter pour nous prévenir de tout changement de situation pouvant impacter votre contrat.',
    },
    'health.beneficiaries.deletion.description': {
        id: 'api.forms.health.beneficiaries.deletion.description',
        defaultMessage:
            'Vous pouvez nous contacter pour retirer un bénéficiaire de votre contrat.',
    },
    'health.tp-card.messages.description': {
        id: 'api.forms.health.tp-card.messages.description',
        defaultMessage:
            'Vous pouvez nous contacter pour toute question relative à votre carte de tiers payant.',
    },
    'health.contributions.messages.description': {
        id: 'api.forms.health.contributions.messages.description',
        defaultMessage:
            'Vous pouvez nous contacter pour toute question relative au paiement de vos cotisations.',
    },
    'health.messages.other.description': {
        id: 'api.forms.health.messages.other.description',
        defaultMessage: 'Vous pouvez nous contacter pour toute autre question.',
    },
    'health.bank-details.refunds.rib-details.iban.placeholder': {
        id: 'api.forms.health.bank-details.refunds.rib-details.iban.placeholder',
        defaultMessage: 'XX00 0000 0000 0000 0000 0000 000',
    },
    'health.contract.compulsory-health-insurance.dependancy.description': {
        id: 'api.forms.health.contract.compulsory-health-insurance.dependancy.description',
        defaultMessage: '',
    },
    'health.contract.compulsory-health-insurance.organization-code.beneficiary.title':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.organization-code.beneficiary.title',
            defaultMessage: 'Bénéficiaire',
        },
    'health.contract.compulsory-health-insurance.organization-code.beneficiary.description':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.organization-code.beneficiary.description',
            defaultMessage:
                'Sélectionnez le bénéficiaire concerné par le changement de code organisme.',
        },
    'health.contract.compulsory-health-insurance.organization-code.organization-code.title':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.organization-code.organization-code.title',
            defaultMessage: '',
        },
    'health.contract.portability.activity-started.attachment-files.title': {
        id: 'api.forms.health.contract.portability.activity-started.attachment-files.title',
        defaultMessage: '',
    },
    'health.contract.portability.activity-started.description': {
        id: 'api.forms.health.contract.portability.activity-started.description',
        defaultMessage:
            '<l>Veuillez nous faire parvenir le dernier justificatif de Pôle emploi avant votre retour à l’emploi.</l><l>Vous ne bénéficiez ou ne bénéficierez plus des droits à la portabilité de votre contrat santé à compter de la date de reprise de votre activité professionnelle.</l>',
    },
    'health.contract.portability.no-activity-benefits-postponed.description': {
        id: 'api.forms.health.contract.portability.no-activity-benefits-postponed.description',
        defaultMessage:
            'Pour mettre à jour votre dossier, veuillez nous faire parvenir le dernier justificatif de votre déclaration de situation auprès de Pôle emploi.',
    },
    'health.contract.portability.no-activity-no-benefits.description': {
        id: 'api.forms.health.contract.portability.no-activity-no-benefits.description',
        defaultMessage:
            '<l>Veuillez nous faire parvenir le justificatif de Pôle emploi confirmant votre radiation de la liste des demandeurs d’emplois indemnisés.</l><l>Vous ne bénéficiez plus des droits à la portabilité de votre contrat santé à compter de la date de votre radiation.</l>',
    },
    'health.cost-coverages.requests.hospitalization.optionLabel.medical': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.optionLabel.medical',
        defaultMessage: 'Médecine générale',
    },
    'health.contract.compulsory-health-insurance.link.beneficiary.title': {
        id: 'api.forms.health.contract.compulsory-health-insurance.link.beneficiary.title',
        defaultMessage: 'Bénéficiaire',
    },
    'health.contract.compulsory-health-insurance.link.beneficiary.description':
        {
            id: 'api.forms.health.contract.compulsory-health-insurance.link.beneficiary.description',
            defaultMessage:
                'Sélectionnez le bénéficiaire concerné par l’ajout du numéro de Sécurité sociale.',
        },
    'health.refunds.requests.dental.invoiceFile.help': {
        id: 'api.forms.health.refunds.requests.dental.invoiceFile.help',
        defaultMessage:
            "<l><b>Attention :</b></l><l>Pour l’acte d'orthodontie, la facture doit comporter obligatoirement les dates de début et de fin de traitement.</l>",
    },
    'common.multiple-file-upload.title': {
        id: 'api.forms.attachments.title',
        defaultMessage: 'Documents à joindre',
    },

    // specific for user reset password
    'user.password.reset.title': {
        id: 'api.forms.user.password.reset.title',
        defaultMessage: 'Réinitialisation de mon mot de passe',
    },
    'user.password.reset.description': {
        id: 'api.forms.user.password.reset.description',
        defaultMessage: 'Veuillez compléter les champs suivants : ',
    },

    'user.password.reset.newPassword.title': {
        id: 'api.forms.user.password.reset.new-password.title',
        defaultMessage: 'Changement de mot de passe',
    },
    'user.password.reset.newPassword.password.title': {
        id: 'api.forms.user.password.reset.new-password.password.title',
        defaultMessage: 'Nouveau mot de passe',
    },
    'user.password.reset.newPassword.password.description': {
        id: 'api.forms.user.password.reset.new-password.password.description',
        defaultMessage:
            'Votre mot de passe doit contenir au moins : 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.',
    },

    // specific for user login
    'user.login.lost.title': {
        id: 'api.forms.user.login.lost.title',
        defaultMessage: 'Identifiant oublié ?',
    },
    'user.login.lost.description': {
        id: 'api.forms.user.login.lost.description',
        defaultMessage:
            'Veuillez compléter les champs suivants de la même manière qu’ils sont inscrits sur votre carte de tiers payant :',
    },
    'common.lastname.title': {
        id: 'api.forms.common.lastname.title',
        defaultMessage: 'Nom de famille',
    },
    'common.birthdate.title': {
        id: 'api.forms.common.birthdate.title',
        defaultMessage: 'Date de naissance',
    },
    'common.socialSecurityNumber.title': {
        id: 'api.forms.common.socialSecurityNumber.title',
        defaultMessage: 'Numéro de Sécurité sociale (15 chiffres)',
    },
    'common.postalCode.placeholder': {
        id: 'api.forms.common.postalCode.placeholder',
        defaultMessage: '00000',
    },
    'common.socialSecurityNumber.placeholder': {
        id: 'api.forms.common.socialSecurityNumber.placeholder',
        defaultMessage: '0 00 00 00 000 000 00',
    },
    'common.birthdate.placeholder': {
        id: 'api.forms.common.birthdate.placeholder',
        defaultMessage: 'jj/mm/aaaa',
    },

    //specific for lost password
    'user.password.request.basic.title': {
        id: 'api.forms.user.password.request.basic.title',
        defaultMessage: 'Mot de passe oublié ?',
    },
    'user.password.request.basic.description': {
        id: 'api.forms.user.password.request.basic.description',
        defaultMessage: 'Votre identifiant est votre numéro adhérent.',
    },
    'common.username.title': {
        id: 'api.forms.common.username.title',
        defaultMessage: 'Identifiant',
    },
    'user.password.request.basic.username.help': {
        id: 'api.forms.user.password.request.basic.username.help',
        defaultMessage:
            "Vous pouvez trouver votre numéro adhérent à l'intérieur de votre carte de tiers payant (en haut à droite).",
    },
    'user.password.request.advanced.description': {
        id: 'api.forms.user.password.request.advanced.description',
        defaultMessage: '<p>Veuillez compléter les champs suivants :</p>',
    },
    'user.password.request.advanced.title': {
        id: 'api.forms.user.password.request.advanced.title',
        defaultMessage: 'Mot de passe oublié ?',
    },
    // specific for login page
    'login.username': {
        id: 'login.username.title',
        defaultMessage: 'Identifiant',
    },
    'login.remember-username': {
        id: 'login.remember-username',
        defaultMessage: 'Se souvenir de mon identifiant ',
    },
    'login.password': {
        id: 'login.password',
        defaultMessage: 'Mot de passe',
    },

    // satisfaction
    'forms.satisfaction.title': {
        id: 'api.forms.satisfaction.title',
        defaultMessage: 'Qu’avez-vous pensé de votre expérience ?',
    },
    'forms.satisfaction.score.title': {
        id: 'api.forms.satisfaction.score.title',
        defaultMessage: '',
    },
    'forms.satisfaction.comment.title': {
        id: 'api.forms.satisfaction.comment.title',
        defaultMessage: 'N’hésitez pas à nous expliquer pourquoi :',
    },

    // specific for messages filters
    'messages.filters.title': {
        id: 'api.forms.filters.messages.title',
        defaultMessage: 'Filtres',
    },
    'messages.filters.dateRange.title': {
        id: 'api.forms.filters.messages.dateRange.title',
        defaultMessage: 'Dates',
    },
    'messages.filters.dateRange.dateStart.title': {
        id: 'api.forms.filters.messages.dateRange.dateStart.title',
        defaultMessage: 'À partir du',
    },
    'messages.filters.dateRange.dateEnd.title': {
        id: 'api.forms.filters.messages.dateRange.dateEnd.title',
        defaultMessage: "Jusqu'au",
    },
    'messages.filters.wasRead.title': {
        id: 'api.forms.filters.messages.wasRead.title',
        defaultMessage: 'Suivi des messages',
    },
    'messages.filters.wasRead.option.true.label': {
        id: 'api.forms.filters.messages.wasRead.option.true.title',
        defaultMessage: 'Messages lus',
    },
    'messages.filters.wasRead.option.false.label': {
        id: 'api.forms.filters.messages.wasRead.option.false.title',
        defaultMessage: 'Messages non lus',
    },
    'messages.filters.wasRead.option.none.label': {
        id: 'api.forms.filters.messages.wasRead.option.none.title',
        defaultMessage: 'Tous',
    },

    // Tunnels d'inscription
    'common.registration.username.help': {
        id: 'api.forms.registration.username.help',
        defaultMessage:
            'Votre identifiant vous a été communiqué par courrier ou a été défini lors de votre adhésion en ligne.',
    },
    'common.registration.username.title': {
        id: 'api.forms.registration.username.title',
        defaultMessage: 'Identifiant',
    },
    'common.registration.password.help': {
        id: 'api.registration.password.help',
        defaultMessage: 'common.registration.password.help',
    },
    'common.registration.password.title': {
        id: 'api.registration.password.title',
        defaultMessage: 'Mot de passe',
    },

    // new registration
    'user.registration.request.title': {
        id: 'api.forms.user.registration.request.title',
        defaultMessage: 'Demande de création de compte',
    },
    'user.registration.request.description': {
        id: 'api.forms.user.registration.request.description',
        defaultMessage:
            '<p>Vous êtes concerné si vous avez rempli un bulletin d’adhésion papier.</p><p>Pour créer votre compte, veuillez compléter les champs suivants :</p>',
    },
    'common.lastname.help': {
        id: 'api.forms.common.lastname.help',
        defaultMessage:
            'Si vous avez un nom composé, veuillez essayer de le saisir avec un espace ou avec un tiret. Si votre nom possède une apostrophe, essayez de le saisir avec ou sans.',
    },

    // new elibility
    'user.registration.eligibility.title': {
        id: 'api.forms.user.registration.eligibility.title',
        defaultMessage: 'Activation de compte',
    },
    'user.registration.eligibility.description': {
        id: 'api.forms.user.registration.eligibility.description',
        defaultMessage:
            '<p>Vous êtes concerné si :</p><l>- Vous avez défini des identifiants lors de votre adhésion en ligne</l><l>ou</l><l>- Vous avez reçu des identifiants par courrier.</l><br></br><p>Pour activer votre compte, veuillez compléter les champs suivants :</p><br></br>{buttonLostId}',
    },
    'user.account.language.description': {
        id: 'api.forms.user.account.language.description',
        defaultMessage: 'Vous pouvez choisir votre langue d’affichage.',
    },
    'user.account.language.title': {
        id: 'api.forms.user.account.language.title',
        defaultMessage: 'Modifier la langue d’affichage',
    },
    'user.account.language.languageSelect.title': {
        id: 'api.forms.user.account.language.languageSelect.title',
        defaultMessage: 'Langue d’affichage',
    },
    // registration
    'registration.account-validation.title': {
        id: 'api.forms.registration.account-validation.title',
        defaultMessage: 'Finalisation de la création de mon compte',
    },
    'registration.account-validation.description': {
        id: 'api.forms.registration.account-validation.description',
        defaultMessage: 'Veuillez compléter les champs suivants :',
    },
    'password.title': {
        id: 'api.widgets.password.title',
        defaultMessage: 'Mot de passe',
    },
    'password.confirmation.title': {
        id: 'api.widgets.password.confirmation.title',
        defaultMessage: 'Confirmer le mot de passe',
    },
    'common.email.title': {
        id: 'api.forms.common.email.title',
        defaultMessage: 'Adresse e-mail',
    },
    'common.email.confirmation.title': {
        id: 'api.forms.common.email.confirmation.title',
        defaultMessage: 'Confirmer l’adresse e-mail',
    },
    'common.registration.gcuConfirmation.modal.text': {
        id: 'api.forms.common.registration.gcu-confirmation.text',
        defaultMessage: 'Mentions légales et CGU.',
    },
    'registration.gcu-confirmation.title': {
        id: 'api.widgets.registration.gcu-confirmation.title',
        defaultMessage: 'Mentions légales & CGU',
    },
    'common.registration.gcuConfirmation.description': {
        id: 'api.forms.common.registration.gcu-confirmation.description',
        defaultMessage: 'J’ai lu et j’accepte les',
    },
    'registration.gcu-confirmation.description': {
        id: 'api.widgets.registration.gcu-confirmation.description',
        defaultMessage: 'J’ai lu et j’accepte les',
    },
    'common.registration.gcuConfirmation.title': {
        id: 'api.forms.common.registration.gcu-confirmation.title',
        defaultMessage: 'Mentions légales & CGU',
    },
    'registration.gcu-confirmation.modal.text': {
        id: 'api.widgets.registration.gcu-confirmation.modal.text',
        defaultMessage: 'mentions légales et CGU.',
    },
    'common.registration.gcuConfirmation.modal.title': {
        id: 'api.forms.common.registration.gcu-confirmation.modal.title',
        defaultMessage: 'Mentions légales & CGU',
    },
    'registration.gcu-confirmation.modal.title': {
        id: 'api.widgets.registration.gcu-confirmation.modal.title',
        defaultMessage: 'Mentions légales & CGU',
    },
    'password.new-password.description': {
        id: 'api.widgets.password.new-password.description',
        defaultMessage:
            'Pour plus de sécurité, définissez un mot de passe robuste.',
    },
    'password.new-password-confirmation.title': {
        id: 'api.widgets.password.new-password-confirmation.title',
        defaultMessage: 'Confirmer le nouveau mot de passe',
    },
    'user.password.reset.new-password.confirmation.description': {
        id: 'api.widgets.user.password.reset.new-password.confirmation.description',
        defaultMessage: 'Les deux mots de passe doivent être identiques.',
    },
    'common.newPassword.confirmation.title': {
        id: 'api.widgets.common.newPassword.confirmation.title',
        defaultMessage:
            'Pour plus de sécurité, définissez un mot de passe robuste.',
    },
    'registration.contact-email.description': {
        id: 'api.widgets.registration.contact-email.description',
        defaultMessage: 'Renseignez votre adresse e-mail.',
    },
    'registration.contact-email-confirmation.description': {
        id: 'api.widgets.registration.contact-email-confirmation.description',
        defaultMessage: 'Les deux adresses e-mail doivent être identiques.',
    },
    'registration.mobile-phone-number.description': {
        id: 'api.widgets.registration.mobile-phone-number.description',
        defaultMessage:
            'Votre n° de téléphone portable sera utilisé pour recevoir un sms vous permettant de vous authentifier.',
    },
    'registration.other-phone-number.description': {
        id: 'api.widgets.registration.other-phone-number.description',
        defaultMessage:
            'Autre n° de téléphone pouvant être utilisé pour votre authentification.',
    },
    'user.registration.account-creation.title': {
        id: 'api.forms.user.registration.account-creation.title',
        defaultMessage: "Finalisation de l'activation de mon compte",
    },
    'user.registration.account-creation.description': {
        id: 'api.forms.user.registration.account-creation.description',
        defaultMessage: 'Veuillez compléter les champs suivants :',
    },
    'common.newPassword.password.title': {
        id: 'api.forms.common.new-password.password.title',
        defaultMessage: 'Nouveau mot de passe',
    },
    'password.new-password.title': {
        id: 'api.widgets.password.new-password.title',
        defaultMessage: 'Nouveau mot de passe',
    },
    'common.newPassword.confirmation.description': {
        id: 'api.forms.common.new-password.confirmation.description',
        defaultMessage: 'Les deux mots de passe doivent être identiques.',
    },
    'password.confirmation.description': {
        id: 'api.widgets.password.confirmation.description',
        defaultMessage: 'Les deux mots de passe doivent être identiques.',
    },
    'common.prescriptionFile.title': {
        id: 'api.forms.common.prescription-file.title',
        defaultMessage: 'Ordonnance',
    },
    'common.prescriptionFile.description': {
        id: 'api.forms.common.prescription-file.description',
        defaultMessage: 'Veuillez joindre une ordonnance en cours de validité.',
    },
    'common.prescriptionFile.help': {
        id: 'api.forms.common.prescription-file.help',
        defaultMessage:
            "En cas de changement de dioptrie, veuillez joindre l'ancienne et la nouvelle ordonnance.",
    },
    'health.bank-details.contributions.moneyOrderDownload': {
        id: 'api.forms.common.health.bank-details.contributions.money-order-download.text',
        defaultMessage: 'Télécharger le mandat SEPA',
    },
    'health.bank-details.contributions.title': {
        id: 'api.forms.common.health.bank-details.contributions.title',
        defaultMessage: 'Modifier mon RIB de cotisations',
    },

    'health.bank-details.contributions.moneyOrderDownload.title': {
        id: 'api.forms.common.health.bank-details.contributions.money-order-download.title',
        defaultMessage: 'Télécharger le mandat SEPA',
    },
    'health.bank-details.contributions.attachmentFiles.title': {
        id: 'api.forms.common.health.bank-details.contributions.attachment-files.title',
        defaultMessage: 'Joindre le relevé d’identité bancaire (RIB)',
    },
    'health.bank-details.contributions.message.description': {
        id: 'api.forms.common.health.bank-details.contributions.message.description',
        defaultMessage:
            "<l>Merci d’indiquer les informations suivantes :</l><l>- l'IBAN (ex. FR7630003035409876543210925),</l><l>- le titulaire du compte.</l><l>Veuillez noter que le RIB de cotisation sera le même pour tous les bénéficiaires rattachés au contrat.</l>",
    },
    'health.bank-details.contributions.attachmentFiles.description': {
        id: 'api.forms.common.health.bank-details.contributions.attachment-files.description',
        defaultMessage:
            'Seuls les formats pdf, jpeg, jpg ou png sont acceptés.',
    },

    'health.beneficiaries.situation-change.description': {
        id: 'api.forms.common.health.beneficiaries.situation-change.description',
        defaultMessage:
            'Vous pouvez nous contacter pour toutes questions relatives à votre adhésion ou celles de vos bénéficiaires ou nous signaler un changement de situation.',
    },
    'health.beneficiaries.teletransmission.deletion.title': {
        id: 'api.forms.common.health.beneficiaries.teletransmission.deletion.title',
        defaultMessage: 'Supprimer ma télétransmission',
    },
    'health.beneficiaries.teletransmission.deletion.description': {
        id: 'api.forms.common.health.beneficiaries.teletransmission.deletion.description',
        defaultMessage:
            'Vous pouvez visualiser toutes les informations concernant votre télétransmission sur votre espace personnel ameli.fr (ou sur votre espace personnel concernant un Régime obligatoire différent de la Sécurité sociale).',
    },
    'health.beneficiaries.teletransmission.organization-code.message.description':
        {
            id: 'api.forms.common.health.beneficiaries.teletransmission.organization-code.message.description',
            defaultMessage:
                'Votre code organisme est composé de 9 chiffres. Votre code organisme de rattachement se trouve sur votre attestation de droits de Sécurité sociale.',
        },
    'health.beneficiaries.teletransmission.organization-code.message.placeholder':
        {
            id: 'api.forms.common.health.beneficiaries.teletransmission.organization-code.message.placeholder',
            defaultMessage: '123456789',
        },
    'health.beneficiaries.teletransmission.trouble.description': {
        id: 'api.forms.common.health.beneficiaries.teletransmission.trouble.description',
        defaultMessage:
            'Vous pouvez signaler un problème concernant le chevauchement de votre télétransmission ou tout autre dysfonctionnement relatif à votre télétransmission.',
    },
    'health.beneficiaries.teletransmission.trouble.message.description': {
        id: 'api.forms.common.health.beneficiaries.teletransmission.trouble.message.description',
        defaultMessage:
            'Veuillez préciser les nom et prénom du bénéficiaire concerné, son numéro de caisse et numéro de régime associé.',
    },
    'health.beneficiaries.teletransmission.trouble.title': {
        id: 'api.forms.common.health.beneficiaries.teletransmission.trouble.title',
        defaultMessage:
            'Signaler un dysfonctionnement au niveau de la télétransmission',
    },
    'health.contact.data-protection.title': {
        id: 'api.forms.common.health.contact.data-protection.title',
        defaultMessage:
            'Demander une information sur la protection des données',
    },
    'health.contract.options.description': {
        id: 'api.forms.common.health.contract.options.description',
        defaultMessage:
            'Vous pouvez nous contacter pour souscrire, faire évoluer ou supprimer une option de votre contrat.',
    },
    'health.contract.portability.message.description': {
        id: 'api.forms.common.health.contract.portability.message.description',
        defaultMessage:
            'En cas de sortie des effectifs, veuillez nous préciser le motif de sortie correspondant.',
    },
    'health.contract.portability.title': {
        id: 'api.forms.common.health.contract.portability.title',
        defaultMessage:
            'Demander une information sur la prolongation de mes droits',
    },
    'health.cost-coverages.messages.description': {
        id: 'api.forms.common.health.cost-coverages.messages.description',
        defaultMessage:
            '<p>Vous pouvez nous contacter pour toute question relative à une avance de frais en optique, dentaire ou appareillage.</p><p>Pour information, une avance de frais ou prise en charge est une demande d’avance de frais réalisée par le professionnel de santé.</p>',
    },
    'health.cost-coverages.messages.message.description': {
        id: 'api.forms.common.health.cost-coverages.messages.message.description',
        defaultMessage:
            'Veuillez nous indiquer les nom et prénom du bénéficiaire concerné par la prise en charge ainsi que le descriptif de votre demande.',
    },
    'health.cost-coverages.messages.other.description': {
        id: 'api.forms.common.health.cost-coverages.messages.other.description',
        defaultMessage:
            '<l>Aujourd’hui, deux possibilités vous sont offertes pour une prise en charge pour vous ou l’un de vos bénéficiaires :</l><l>- Demander à votre professionnel de santé de saisir votre prise en charge,</l><l>- Contacter votre centre de gestion via ce formulaire de contact.</l>',
    },
    'health.cost-coverages.messages.title': {
        id: 'api.forms.common.health.cost-coverages.messages.title',
        defaultMessage: 'Demander une explication suite à une avance de frais',
    },
    'health.quotes.messages.title': {
        id: 'api.forms.common.health.quotes.messages.title',
        defaultMessage:
            'Demander une explication complémentaire suite à une estimation chiffrée',
    },
    'health.refunds.messages.message.description': {
        id: 'api.forms.common.health.refunds.messages.message.description',
        defaultMessage:
            'Veuillez nous préciser la nature et la date des soins, le bénéficiaire concerné et la date de règlement complémentaire.',
    },
    'health.refunds.messages.title': {
        id: 'api.forms.common.health.refunds.messages.title',
        defaultMessage:
            'Demander une explication complémentaire suite à un remboursement',
    },
    'provident.messages.description': {
        id: 'api.forms.common.provident.messages.description',
        defaultMessage:
            '<p>Si vous possédez un contrat prévoyance, vous pouvez nous contacter pour toute question relative à une incapacité, une invalidité ou un décès.</p><p><b>Attention :</b> en ce qui concerne l’allocation frais d’obsèques, veuillez nous transmettre votre demande <i>via</i> le formulaire “Pour un acte non listé” disponible dans la catégorie “Me faire rembourser”.</p>',
    },
    'provident.messages.title': {
        id: 'api.forms.common.provident.messages.title',
        defaultMessage: 'Question sur mon contrat prévoyance',
    },
    'profile.postal-address.description': {
        id: 'api.forms.common.profile.postal-address.description',
        defaultMessage:
            "Seules les adresses situées en France sont acceptées. Pour une adresse située à l'étranger, veuillez vous rapprocher de votre centre de gestion.",
    },
    'profile.postal-address.proof-of-residency-files.title': {
        id: 'api.forms.common.profile.postal-address.proof-of-residency-files.title',
        defaultMessage: 'Joindre un justificatif de domicile',
    },
    'profile.postal-address.proof-of-residency-files.description': {
        id: 'api.forms.common.profile.postal-address.proof-of-residency-files.description',
        defaultMessage:
            "<p>Les justificatifs de domicile acceptés sont :</p><l>- pièce d’identité</l><l>- facture d'électricité</l><l>- quittance de loyer</l><l>- attestation de Sécurité sociale</l>",
    },
    'health.quotes.messages.description': {
        id: 'api.forms.common.health.quotes.messages.description',
        defaultMessage:
            '<p>Pour information, suite à votre demande de devis, une estimation chiffrée de vos remboursements en fonction des garanties prévues par votre contrat est réalisée par nos services.</p><p>Pour rappel, vous trouverez le détail des prestations prévues par votre contrat sur votre plaquette de garantie.</p>',
    },
    'health.contract.portability.message.placeholder': {
        id: 'api.forms.common.health.contract.portability.message.placeholder',
        defaultMessage:
            'En cas de sortie des effectifs, veuillez nous préciser le motif de sortie correspondant.',
    },
    'health.beneficiaries.situation-change.title': {
        id: 'api.forms.common.health.beneficiaries.situation-change.title',
        defaultMessage: 'Signaler un changement de situation',
    },
    'health.contract.options.mercer.title': {
        id: 'api.forms.common.health.contract.options.mercer.title',
        defaultMessage: 'Mettre à jour mes options au contrat',
    },
    'health.contract.options.mercer.description': {
        id: 'api.forms.common.health.contract.options.mercer.description',
        defaultMessage:
            'Vous pouvez nous contacter pour souscrire, faire évoluer ou supprimer une option de votre contrat.',
    },
    'health.refunds.requests.cure.beneficiaries.title': {
        id: 'api.forms.common.health.refunds.requests.cure.beneficiaries.title',
        defaultMessage: 'Sélectionnez le bénéficiaire de la cure thermale',
    },
    'health.refunds.requests.dental.invoiceFile.description': {
        id: 'api.forms.common.health.refunds.requests.dental.invoice-file.description',
        defaultMessage:
            'Pour tout type d’acte dentaire, veuillez joindre la facture avec la mention "acquittée" délivrée par le professionnel de santé et mentionnant le numéro dent concerné.',
    },
    'health.refunds.requests.cure.invoiceAmount.title': {
        id: 'api.forms.common.health.refunds.requests.cure.invoice-amount.title',
        defaultMessage: 'Montant total des factures',
    },
    'health.refunds.statements.status.title': {
        id: 'api.forms.common.health.refunds.statements.status.title',
        defaultMessage: 'Activation de mes e-relevés',
    },
    'health.refunds.statements.status.statementsActivation.description': {
        id: 'api.forms.common.health.refunds.statements.status.statements-activation.description ',
        defaultMessage:
            'Je souhaite recevoir mes décomptes de prestations sur mon espace en ligne.',
    },
    'health.contract.portability.no-activity-benefits-postponed.attachmentFiles.description':
        {
            id: 'api.forms.common.health.contract.portability.no-activity-benefits-postponed.attachment-files.description',
            defaultMessage:
                "Si vous ne pouvez pas joindre le justificatif, merci de nous l'envoyer par courrier postal en nous précisant vos coordonnées et votre situation.",
        },
    'health.contract.portability.activity-started.attachmentFiles.description':
        {
            id: 'api.forms.common.health.contract.portability.activity-started.attachment-files.description',
            defaultMessage:
                "Si vous ne pouvez pas joindre le justificatif, merci de nous l'envoyer par courrier postal en nous précisant vos coordonnées et votre situation.",
        },
    'health.contract.portability.no-activity-no-benefits.attachmentFiles.description':
        {
            id: 'api.forms.common.health.contract.portability.no-activity-no-benefits.attachment-files.description',
            defaultMessage:
                "Si vous ne pouvez pas joindre le justificatif, merci de nous l'envoyer par courrier postal en nous précisant vos coordonnées et votre situation.",
        },

    // Simulateur
    'family.standard-care': {
        id: 'simulator.families.standard-care',
        defaultMessage: 'Soins courants',
    },
    'family.hospitalization': {
        id: 'simulator.families.hospitalization',
        defaultMessage: 'Hospitalisation',
    },
    'family.dental': {
        id: 'simulator.families.dental',
        defaultMessage: 'Dentaire',
    },
    'family.audio': {
        id: 'simulator.families.audio',
        defaultMessage: 'Aides auditives',
    },
    'family.optical': {
        id: 'simulator.families.optical',
        defaultMessage: 'Optique',
    },
    'guarantee-group.general-practitioner': {
        id: 'api.forms.guarantee-group.general-practitioner',
        defaultMessage: 'Médecin généraliste',
    },
    'guarantee-group.specialist': {
        id: 'api.forms.guarantee-group.specialist',
        defaultMessage: 'Médecin spécialiste',
    },
    'guarantee-group.prostheses-care-remaining-amount-not-null': {
        id: 'api.forms.guarantee-group.prostheses-care-remaining-amount-not-null',
        defaultMessage:
            'Soins et prothèses hors 100% Santé dont panier reste à charge maîtrisé',
    },
    'guarantee-group.other-dental': {
        id: 'api.forms.guarantee-group.other-dental',
        defaultMessage: 'Autres prestations dentaires',
    },
    'guarantee-group.equipment-remaining-amount-null': {
        id: 'api.forms.guarantee-group.equipment-remaining-amount-null',
        defaultMessage: 'Équipements 100% Santé',
    },
    'guarantee-group.equipment-remaining-amount-not-null': {
        id: 'api.forms.guarantee-group.equipment-remaining-amount-not-null',
        defaultMessage: 'Équipements hors 100% Santé',
    },
    'guarantee-group.other-optical': {
        id: 'api.forms.guarantee-group.other-optical',
        defaultMessage: 'Autres prestations optiques',
    },
    'guarantee.attending-physician-dptm-member.label': {
        id: 'api.forms.guarantee.attending-physician-dptm-member.label',
        defaultMessage:
            'Consultation médecin traitant généraliste adhérent DPTM',
    },
    'guarantee.attending-physician-dptm-not-member.label': {
        id: 'api.forms.guarantee.attending-physician-dptm-not-member.label',
        defaultMessage:
            'Consultation médecin traitant généraliste non adhérent DPTM',
    },
    'guarantee.physician-dptm-member.label': {
        id: 'api.forms.guarantee.physician-dptm-member.label',
        defaultMessage: 'Consultation médecin spécialiste adhérent DPTM',
    },
    'guarantee.physician-dptm-not-member.label': {
        id: 'api.forms.guarantee.physician-dptm-not-member.label',
        defaultMessage: 'Consultation médecin spécialiste non adhérent DPTM',
    },
    'guarantee.daily-rate.label': {
        id: 'api.forms.guarantee.daily-rate.label',
        defaultMessage: 'Forfait journalier hospitalier',
    },
    'guarantee.prostheses-social-security-reimbursement.label': {
        id: 'api.forms.guarantee.prostheses-social-security-reimbursement.label',
        defaultMessage:
            'Prothèses dentaires hors 100% Santé remboursées par la Sécurité sociale',
    },
    'guarantee.prostheses-social-security-reimbursement-back-teeth.label': {
        id: 'api.forms.guarantee.prostheses-social-security-reimbursement-back-teeth.label',
        defaultMessage:
            'Prothèses dentaires hors 100% Santé remboursées par la Sécurité sociale pour les dents du fond',
    },
    'guarantee.tooth-crown-social-security-reimbursement.label': {
        id: 'api.forms.guarantee.tooth-crown-social-security-reimbursement.label',
        defaultMessage:
            'Couronne dentaire hors 100% Santé remboursée par la Sécurité sociale',
    },
    'guarantee.inlay-core-social-security-reimbursement.label': {
        id: 'api.forms.guarantee.inlay-core-social-security-reimbursement.label',
        defaultMessage:
            'Inlay-core hors 100% Santé remboursé par la Sécurité sociale',
    },
    'guarantee.orthodontia-social-security-reimbursement.label': {
        id: 'api.forms.guarantee.orthodontia-social-security-reimbursement.label',
        defaultMessage: 'Orthodontie remboursée par la Sécurité sociale',
    },
    'guarantee.dental-consultation-care-social-security-reimbursement.label': {
        id: 'api.forms.guarantee.dental-consultation-care-social-security-reimbursement.label',
        defaultMessage:
            'Consultations et soins dentaires remboursés par la Sécurité sociale',
    },
    'guarantee.hearing-aid.label': {
        id: 'api.forms.guarantee.hearing-aid.label',
        defaultMessage: "Aide auditive 100% Santé quel que soit l'âge",
    },
    'guarantee.hearing-aid-over-20.label': {
        id: 'api.forms.guarantee.hearing-aid-over-20.label',
        defaultMessage:
            'Aide auditive hors 100% Santé pour les assurés au-delà du 20ème anniversaire',
    },
    'guarantee.hearing-aid-under-20.label': {
        id: 'api.forms.guarantee.hearing-aid-under-20.label',
        defaultMessage:
            "Aide auditive hors 100% Santé pour les assurés jusqu'au 20ème anniversaire",
    },
    'guarantee.accessories.label': {
        id: 'api.forms.guarantee.accessories.label',
        defaultMessage: 'Accessoires et fournitures auditifs hors 100% Santé',
    },
    'guarantee.lenses.label': {
        id: 'api.forms.guarantee.lenses.label',
        defaultMessage: 'Lentilles',
    },
    'guarantee.lenses-social-security-reimbursement.label': {
        id: 'api.forms.guarantee.lenses-social-security-reimbursement.label',
        defaultMessage: 'Lentilles remboursées par la Sécurité sociale',
    },
    'guarantee.frame.label': {
        id: 'api.forms.guarantee.frame.label',
        defaultMessage: 'Monture hors 100% Santé',
    },
    'guarantee.simple-glass.label': {
        id: 'api.forms.guarantee.simple-glass.label',
        defaultMessage: 'Verre simple hors 100% Santé',
    },
    'guarantee.complex-glass.label': {
        id: 'api.forms.guarantee.complex-glass.label',
        defaultMessage: 'Verre hypercomplexe hors 100% Santé',
    },
    'guarantee.extra-complex-glass.label': {
        id: 'api.forms.guarantee.extra-complex-glass.label',
        defaultMessage: 'Verre complexe hors 100% Santé',
    },
    'guarantee.physician-dptm-member.description': {
        id: 'api.forms.guarantee.physician-dptm-member.description',
        defaultMessage:
            'Exemples de médecin spécialiste : cardiologue, rhumatologue, pneumologue…',
    },
    'guarantee.physician-dptm-not-member.description': {
        id: 'api.forms.guarantee.physician-dptm-not-member.description',
        defaultMessage:
            'Exemples de médecin spécialiste : cardiologue, rhumatologue, pneumologue…',
    },
    'guarantee.lenses-social-security-reimbursement.description': {
        id: 'api.forms.guarantee.lenses-social-security-reimbursement.description',
        defaultMessage:
            '<p>Il s’agit d’un forfait annuel.</p><p><l>Lentilles remboursables sur prescription médicale, pour les indications suivantes :</l><l>- astigmatisme irrégulier myopie égale ou supérieure à 8 dioptries</l><l>- strabisme accommodatif</l><l>- aphakie</l><l>- anisométropie à 3 dioptries non corrigeables par des lunettes</l><l>- kératocône</l></p>',
    },
    'guarantee.widget.question.prescription': {
        id: 'api.forms.guarantee.widget.question.prescription',
        defaultMessage:
            'Etes-vous passé au préalable par votre médecin traitant ?',
    },
    'guarantee.widget.question.establishment': {
        id: 'api.forms.guarantee.widget.question.establishment',
        defaultMessage: "Dans quel type d'établissement séjournez-vous ?",
    },
    'guarantee.widget.question.basket': {
        id: 'api.forms.guarantee.widget.question.basket',
        defaultMessage: 'Quel type de panier ?',
    },
    'guarantee.widget.question.tooth-crown': {
        id: 'api.forms.guarantee.widget.question.tooth-crown',
        defaultMessage: 'Quel type de couronne dentaire ?',
    },
    'guarantee.widget.question.prostheses': {
        id: 'api.forms.guarantee.widget.question.prostheses',
        defaultMessage: 'Quel type de prothèse ?',
    },
    'guarantee.widget.question.orthodontia': {
        id: 'api.forms.guarantee.widget.question.orthodontia',
        defaultMessage: "Quel est le traitement d'orthodontie ?",
    },
    'guarantee.widget.question.dental-care': {
        id: 'api.forms.guarantee.widget.question.dental-care',
        defaultMessage: 'Quel est le soin dentaire ?',
    },
    'guarantee.widget.question.beneficiary-age': {
        id: 'api.forms.guarantee.widget.question.beneficiary-age',
        defaultMessage: "Quel est l'âge du bénéficiaire ?",
    },
    'guarantee.widget.question.equipment': {
        id: 'api.forms.guarantee.widget.question.equipment',
        defaultMessage: "Quel type d'équipement ?",
    },
    'guarantee.widget.question.accessories': {
        id: 'api.forms.guarantee.widget.question.accessories',
        defaultMessage: "Quel type d'accessoire ?",
    },
    'guarantee.widget.question.lenses': {
        id: 'api.forms.guarantee.widget.question.lenses.text',
        defaultMessage: 'Lentilles :',
    },
    'guarantee.widget.question.specify': {
        id: 'api.forms.guarantee.widget.question.specify',
        defaultMessage: 'Précisez :',
    },
    'guarantee.widget.answer.yes': {
        id: 'api.forms.guarantee.widget.answer.yes',
        defaultMessage: 'Oui',
    },
    'guarantee.widget.answer.no': {
        id: 'api.forms.guarantee.widget.answer.no',
        defaultMessage: 'Non',
    },
    'guarantee.widget.answer.contracted-establishment': {
        id: 'api.forms.guarantee.widget.answer.contracted-establishment',
        defaultMessage: 'Etablissement public ou clinique privée conventionnée',
    },
    'guarantee.widget.answer.not-contracted-establishment': {
        id: 'api.forms.guarantee.widget.answer.not-contracted-establishment',
        defaultMessage: 'Clinique privée non conventionnée',
    },
    'guarantee.widget.answer.basket-controlled-remaining-amount': {
        id: 'api.forms.guarantee.widget.answer.basket-controlled-remaining-amount',
        defaultMessage: 'Panier "Reste à charge maîtrisé"',
    },
    'guarantee.widget.answer.free-basket': {
        id: 'api.forms.guarantee.widget.answer.free-basket',
        defaultMessage: 'Panier Tarif libre',
    },
    'guarantee.widget.answer.tooth-crown-monolithic-ceramic': {
        id: 'api.forms.guarantee.widget.answer.tooth-crown-monolithic-ceramic',
        defaultMessage:
            'Dentoportée céramique monolithique autre que zircone sur deuxièmes prémolaires et molaires',
    },
    'guarantee.widget.answer.tooth-crown-metal-ceramic': {
        id: 'api.forms.guarantee.widget.answer.tooth-crown-metal-ceramic',
        defaultMessage:
            'Dentoportée céramométallique sur une deuxième prémolaire',
    },
    'guarantee.widget.answer.tooth-crown': {
        id: 'api.forms.guarantee.widget.answer.tooth-crown',
        defaultMessage: 'Couronne',
    },
    'guarantee.widget.answer.inlay-core': {
        id: 'api.forms.guarantee.widget.answer.inlay-core',
        defaultMessage: 'Inlay-core',
    },
    'guarantee.widget.answer.brace': {
        id: 'api.forms.guarantee.widget.answer.brace',
        defaultMessage: 'Appareil dentaire (1 à 3 dents)',
    },
    'guarantee.widget.answer.semester-treatment': {
        id: 'api.forms.guarantee.widget.answer.semester-treatment',
        defaultMessage: 'Traitement par semestre (enfant de moins de 16 ans)',
    },
    'guarantee.widget.answer.follow-up': {
        id: 'api.forms.guarantee.widget.answer.follow-up',
        defaultMessage: 'Séance de surveillance',
    },
    'guarantee.widget.answer.contestation-1-st-year': {
        id: 'api.forms.guarantee.widget.answer.contestation-1-st-year',
        defaultMessage: 'Contention 1er année',
    },
    'guarantee.widget.answer.contestation-2-nd-year': {
        id: 'api.forms.guarantee.widget.answer.contestation-2-nd-year',
        defaultMessage: 'Contention 2<sup>ème</sup> année',
    },
    'guarantee.widget.answer.scaling': {
        id: 'api.forms.guarantee.widget.answer.scaling',
        defaultMessage: 'Détartrage',
    },
    'guarantee.widget.answer.decay': {
        id: 'api.forms.guarantee.widget.answer.decay',
        defaultMessage: "Traitement d'une carie",
    },
    'guarantee.widget.answer.devitalisation': {
        id: 'api.forms.guarantee.widget.answer.devitalisation',
        defaultMessage: 'Dévitalisation',
    },
    'guarantee.widget.answer.tooth-pulled-out': {
        id: 'api.forms.guarantee.widget.answer.tooth-pulled-out',
        defaultMessage: "Extraction d'une dent",
    },
    'guarantee.widget.answer.furrows': {
        id: 'api.forms.guarantee.widget.answer.furrows',
        defaultMessage: 'Scellement de sillons une dent',
    },
    'guarantee.widget.answer.varnish': {
        id: 'api.forms.guarantee.widget.answer.varnish',
        defaultMessage: 'Vernis fluoré',
    },
    'guarantee.widget.answer.over-20': {
        id: 'api.forms.guarantee.widget.answer.over-20',
        defaultMessage: 'Plus de 20 ans',
    },
    'guarantee.widget.answer.under-20': {
        id: 'api.forms.guarantee.widget.answer.under-20',
        defaultMessage: 'Moins de 20 ans',
    },
    'guarantee.widget.answer.audio-prosthese': {
        id: 'api.forms.guarantee.widget.answer.audio-prosthese',
        defaultMessage: 'Prothèse auditive',
    },
    'guarantee.widget.answer.earphone': {
        id: 'api.forms.guarantee.widget.answer.earphone',
        defaultMessage: 'Écouteur',
    },
    'guarantee.widget.answer.microphone': {
        id: 'api.forms.guarantee.widget.answer.microphone',
        defaultMessage: 'Microphone',
    },
    'guarantee.widget.answer.potentiometer': {
        id: 'api.forms.guarantee.widget.answer.potentiometer',
        defaultMessage: 'Potentiomètre',
    },
    'guarantee.widget.answer.vibrator': {
        id: 'api.forms.guarantee.widget.answer.vibrator',
        defaultMessage: 'Vibrateur à conduction osseuse',
    },
    'guarantee.widget.answer.earmold': {
        id: 'api.forms.guarantee.widget.answer.earmold',
        defaultMessage: 'Embout auriculaire',
    },
    'guarantee.widget.answer.pile': {
        id: 'api.forms.guarantee.widget.answer.pile',
        defaultMessage: 'Pile sans mercure',
    },
    'guarantee.widget.answer.refundable': {
        id: 'api.forms.guarantee.widget.answer.refundable',
        defaultMessage: 'Remboursables',
    },
    'guarantee.widget.answer.not-refundable': {
        id: 'api.forms.guarantee.widget.answer.not-refundable',
        defaultMessage: 'Non remboursables',
    },
    'guarantee.widget.answer.molar-crown-monolithic-ceramic': {
        id: 'api.forms.guarantee.widget.answer.molar-crown-monolithic-ceramic',
        defaultMessage:
            'Dentoportée céramique monolithique (zircone) sur une molaire',
    },
    'guarantee.widget.answer.1-face': {
        id: 'api.forms.guarantee.widget.answer.1-face',
        defaultMessage: 'Une face',
    },
    'guarantee.widget.answer.2-faces': {
        id: 'api.forms.guarantee.widget.answer.2-faces',
        defaultMessage: 'Deux faces',
    },
    'guarantee.widget.answer.3-faces': {
        id: 'api.forms.guarantee.widget.answer.3-faces',
        defaultMessage: 'Trois faces',
    },
    'guarantee.widget.answer.incisive-canine': {
        id: 'api.forms.guarantee.widget.answer.incisive-canine',
        defaultMessage: "D'une incisive ou d'une canine",
    },
    'guarantee.widget.answer.premolar': {
        id: 'api.forms.guarantee.widget.answer.premolar',
        defaultMessage: "D'une prémolaire",
    },
    'guarantee.widget.answer.molar': {
        id: 'api.forms.guarantee.widget.answer.molar',
        defaultMessage: "D'une molaire",
    },
    'guarantee.widget.answer.baby-tooth': {
        id: 'api.forms.guarantee.widget.answer.baby-tooth',
        defaultMessage: 'De lait',
    },
    'guarantee.widget.answer.permanent': {
        id: 'api.forms.guarantee.widget.answer.permanent',
        defaultMessage: 'Permanente',
    },
    'guarantee.widget.answer.under-2': {
        id: 'api.forms.guarantee.widget.answer.under-2',
        defaultMessage: 'Pour un enfant de moins de 2 ans',
    },
    'guarantee.widget.answer.between-2-20': {
        id: 'api.forms.guarantee.widget.answer.between-2-20',
        defaultMessage: 'Pour un enfant ou adulte entre 2 et 20 ans',
    },
    'guarantee.widget.answer.hearing-loss-blindness': {
        id: 'api.forms.guarantee.widget.answer.hearing-loss-blindness',
        defaultMessage:
            "Pour un personne souffrant d'un déficit auditif et de cécité",
    },
    'guarantee.widget.question.lenses.description': {
        id: 'api.forms.guarantee.widget.question.lenses.description',
        defaultMessage:
            '<p>Il s’agit d’un forfait annuel.</p><p><l>Lentilles remboursables sur prescription médicale, pour les indications suivantes :</l><l>- astigmatisme irrégulier myopie égale ou supérieure à 8 dioptries</l><l>- strabisme accommodatif</l><l>- aphakie</l><l>- anisométropie à 3 dioptries non corrigeables par des lunettes</l><l>- kératocône</l></p>',
    },
    'guarantee-group.medical-fees': {
        id: 'simulator.guarantee-group.medical-fees',
        defaultMessage: 'Honoraires médicaux',
    },
    'simulator.guarantee-group.100%-santé': {
        id: 'simulator.guarantee-group.100%-santé',
        defaultMessage: 'Panier 100% Santé',
    },
    'simulator.guarantee-group.100%-santé-description': {
        id: 'simulator.guarantee-group.100%-santé-description',
        defaultMessage:
            'Dans le cadre du panier 100% Santé, les montures et verres sont intégralement pris en charge par votre régime obligatoire et nos services.',
    },
    'guarantee.general-practitioner.label': {
        id: 'widgets.guarantee.general-practitioner.label',
        defaultMessage: "Consultation d'un médecin généraliste",
    },
    'guarantee.specialist.label': {
        id: 'widgets.guarantee.specialist.label',
        defaultMessage: "Consultation d'un médecin spécialiste",
    },
    'guarantee.frame-remaining-amount-not-null.label': {
        id: 'widgets.guarantee.frame-remaining-amount-not-null.label',
        defaultMessage: 'Monture',
    },
    'guarantee.widget.question.sector': {
        id: 'widgets.guarantee.widget.question.sector',
        defaultMessage: 'Secteur du médecin',
    },
    'guarantee.widget.question.sector-description': {
        id: 'widgets.guarantee.widget.question.sector-description',
        defaultMessage:
            'Votre médecin applique-t-il les tarifs de convention de la Sécurité sociale (secteur 1) ou pratique-t-il des honoraires libres (secteur 2) ?',
    },
    'guarantee.widget.answer.sector-one': {
        id: 'widgets.guarantee.widget.answer.sector-one',
        defaultMessage: 'Secteur 1',
    },
    'guarantee.widget.answer.sector-two': {
        id: 'widgets.guarantee.widget.answer.sector-two',
        defaultMessage: 'Secteur 2',
    },
    'guarantee.widget.question.prescription-description': {
        id: 'widgets.guarantee.widget.question.prescription-description',
        defaultMessage:
            'A noter que le non-respect du parcours de soins coordonnés peut entrainer un remboursement minoré de votre régime obligatoire et non pris en charge par votre contrat santé.',
    },
    'guarantee-expression.title': {
        id: 'widgets.guarantee-expression.title',
        defaultMessage: 'Expression de la garantie',
    },
    'guarantee-value.title': {
        id: 'widgets.guarantee-value.title',
        defaultMessage: 'Valeur de la garantie',
    },
    'social-security-inclusion.description': {
        id: 'widgets.social-security-inclusion.description',
        defaultMessage:
            'Ma garantie est exprimée remboursement Sécurité sociale inclus.',
    },
    'guarantee-expression.reimbursement-basis': {
        id: 'widgets.guarantee-expression.reimbursement-basis',
        defaultMessage: '% BR',
    },
    'guarantee-expression.real-costs': {
        id: 'widgets.guarantee-expression.real-costs',
        defaultMessage: 'Frais réels',
    },
    'guarantee-expression.euro': {
        id: 'widgets.guarantee-expression.euro',
        defaultMessage: 'Euros',
    },
    'guarantee-expression.pmss': {
        id: 'widgets.guarantee-expression.pmss',
        defaultMessage: '% PMSS',
    },
    'guarantee-expression.tm': {
        id: 'widgets.guarantee-expression.tm',
        defaultMessage: 'TM + X% BR',
    },
    'guarantee-expression.placeholder': {
        id: 'widgets.guarantee-expression.placeholder',
        defaultMessage: 'Sélectionner l’expression de votre garantie',
    },
    'social-security-inclusion.title': {
        id: 'widgets.social-security-inclusion.title',
        defaultMessage:
            'Votre garantie est-elle exprimée remboursement Sécurité sociale inclus ?',
    },
    'health.cost-coverages.messages.other.message.help': {
        id: 'api.forms.health.cost-coverages.messages.other.message.help',
        defaultMessage:
            'Vous trouverez l’ensemble des informations dans la FAQ pour l’envoi des documents.',
    },
    'health.beneficiaries.teletransmission.organization-code.attachmentFiles.title':
        {
            id: 'api.forms.health.beneficiaries.teletransmission.organization-code.attachmentFiles.title',
            defaultMessage: 'Joindre votre attestation de droits',
        },
    'actAmount.title': {
        id: 'api.forms.act-amount.title',
        defaultMessage: "Montant de l'acte",
    },
    'regime.title': {
        id: 'api.forms.regime.title',
        defaultMessage: 'Régime d’assurance maladie',
    },
    generalRegime: {
        id: 'api.forms.general-regime',
        defaultMessage: 'Régime général',
    },
    localRegime: {
        id: 'api.forms.local-regime',
        defaultMessage: 'Régime Alsace-Moselle',
    },
    'user.password.upgrade.title': {
        id: 'api.forms.user.password.upgrade.title',
        defaultMessage: 'Mettre à jour votre mot de passe',
    },
    'user.password.upgrade.description': {
        id: 'api.forms.user.password.upgrade.description',
        defaultMessage:
            'Votre mot de passe actuel ne suit pas notre politique de sécurité et doit être changé.',
    },
    'user.password.activation.title': {
        id: 'forms.user.password.activation.title',
        defaultMessage: 'Activez votre compte',
    },
    'user.password.activation.description': {
        id: 'forms.user.password.activation.description',
        defaultMessage: 'Veuillez compléter les champs suivants.',
    },
    'health.refunds.requests.glasses-lenses-reimbursed.title': {
        id: 'api.forms.health.refunds.requests.glasses-lenses-reimbursed.title',
        defaultMessage:
            'Demande de remboursement pour des lunettes ou lentilles remboursées par la Sécurité sociale',
    },
    'health.refunds.requests.glasses-lenses-reimbursed.lmv.description': {
        id: 'api.forms.health.refunds.requests.glasses-lenses-reimbursed.lmv.description',
        defaultMessage:
            '<p>Vous pouvez nous transmettre ici les justificatifs de dépenses engagées pour les soins pris en charge par l’assurance maladie obligatoire (verres, monture de lunettes correctrices et lentilles correctrices remboursées).</p><p>Attention : une demande de remboursement non conforme ne pourra pas être traitée.</p>',
    },
    'health.refunds.requests.lenses-not-reimbursed.title': {
        id: 'api.forms.health.refunds.requests.lenses-not-reimbursed.title',
        defaultMessage:
            'Demande de remboursement pour des lentilles non remboursées par la Sécurité sociale',
    },
    'health.refunds.requests.lenses-not-reimbursed.lmv.description': {
        id: 'api.forms.health.refunds.requests.lenses-not-reimbursed.lmv.description',
        defaultMessage:
            '<p>Vous pouvez nous transmettre ici la facture des lentilles journalières ou mensuelles non remboursées par l’assurance maladie obligatoire avec la mention "acquittée", accompagnée obligatoirement de la prescription médicale en cours de validité si vous ne l\'avez pas déjà fournie. </p><p>Attention : une demande de remboursement non conforme ne pourra pas être traitée.</p>',
    },
    'health.refunds.requests.medicine.title': {
        id: 'api.forms.health.refunds.requests.medicine.title',
        defaultMessage:
            'Demander un remboursement pour des médicaments prescrits',
    },
    'health.refunds.requests.medicine.lmv.description': {
        id: 'api.forms.health.refunds.requests.medicine.lmv.description',
        defaultMessage:
            "<p>Vous pouvez nous transmettre ici les justificatifs de dépenses engagées pour l'achat de médicaments accompagnés de la copie de la prescription médicale (sauf en cas de renouvellement).</p><p>Attention : une demande de remboursement non conforme ne pourra pas être traitée.</p>",
    },
    'health.refunds.requests.hospitalization.lmv.description': {
        id: 'api.forms.health.refunds.requests.hospitalization.lmv.description',
        defaultMessage:
            "<p><l>Seuls les justificatifs de dépenses engagées liées à votre hospitalisation ayant fait l'objet d'un devis préalablement transmis et pour lequel nous avons fait une estimation de remboursement peuvent être transmis ici.</l></p><l> Dans le cas contraire, vous devez nous adresser les originaux de facture par voie postale.</l><p>Vous pouvez également nous transmettre les justificatifs de dépenses liées à des consultations ou des actes médicaux et paramédicaux ponctuels effectués au sein d'un hôpital ou d'une clinique, accompagnés de la quittance de paiement.</p><p>Attention : une demande de remboursement non conforme ne pourra pas être traitée.</p>",
    },
    'health.refunds.requests.hospitalization.surgeryInvoiceFile.title': {
        id: 'api.forms.health.refunds.requests.hospitalization.surgery-invoice-file.title',
        defaultMessage: 'Facture ou bordereau de facturation',
    },
    'health.refunds.requests.hospitalization.surgeryInvoiceFile.description': {
        id: 'api.forms.health.refunds.requests.hospitalization.surgery-invoice-file.description',
        defaultMessage:
            'Veuillez joindre la facture avec la mention "acquittée" délivrée par le professionnel de santé.',
    },
    'health.refunds.requests.hospitalization.surgeryReportFile.title': {
        id: 'api.forms.health.refunds.requests.hospitalization.surgery-report-file.title',
        defaultMessage: 'Note d’honoraires',
    },
    'health.refunds.requests.hospitalization.surgeryReportFile.description': {
        id: 'api.forms.health.refunds.requests.hospitalization.surgery-report-file.description',
        defaultMessage:
            'Veuillez joindre le document fourni par le professionnel de santé.',
    },
    'satisfaction.app.rating.title': {
        id: 'api.forms.satisfaction.app.rating.title',
        defaultMessage: "Que pensez-vous de l'application {appName} ?",
    },
    'satisfaction.app.comment.title': {
        id: 'api.forms.satisfaction.app.comment.title',
        defaultMessage: 'Nous sommes navrés',
    },
    'satisfaction.app.comment.description': {
        id: 'api.forms.satisfaction.app.comment.comment.description',
        defaultMessage:
            "L'application {appName} ne répond pas entièrement à vos attentes et nous en sommes désolés.",
    },
    'satisfaction.app.comment.comment.placeholder': {
        id: 'api.forms.satisfaction.comment.comment.placeholder',
        defaultMessage: 'Ecrivez votre texte ici',
    },
    'satisfaction.app.comment.comment.title': {
        id: 'api.forms.satisfaction.app.comment.comment.title',
        defaultMessage:
            'Voulez-vous nous en dire plus afin que nous puissions améliorer votre expérience ?',
    },
    'health.cost-coverages.requests.hospitalization.description.mercer': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.description-mercer',
        defaultMessage:
            "Pour information, vous devez demander une prise en charge hospitalière au maximum 30 jours avant la date de début d'hospitalisation.",
    },
    'health.cost-coverages.requests.hospitalization.beneficiary.help': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.beneficiary.help',
        defaultMessage:
            "<p>Deux possibilités vous sont offertes pour une prise en charge pour vous ou l'un de vos bénéficiaires :</p><p><l>- Demander à l’établissement hospitalier de réaliser votre demande de prise en charge hospitalière,</l><l>- Contacter votre centre de gestion au n° de téléphone indiqué sur votre carte de tiers payant, ou via ce formulaire.</l></p>",
    },
    'health.cost-coverages.requests.hospitalization.entryDate.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.entry-date.title',
        defaultMessage: 'Date d’entrée dans l’établissement',
    },
    'health.cost-coverages.requests.hospitalization.hospitalProfessionalId.title':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospital-professional-id.title',
            defaultMessage:
                'N° FINESS géographique de l’établissement hospitalier',
        },
    'health.cost-coverages.requests.hospitalization.hospitalProfessionalId.help':
        {
            id: 'api.forms.health.cost-coverages.requests.hospitalization.hospital-professional-id.help',
            defaultMessage:
                'Le numéro FINESS comporte 9 chiffres. Vous pouvez le retrouver dans le document remis par votre établissement hospitalier.',
        },
    'health.cost-coverages.requests.hospitalization.dmtCode.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.dmt-code.title',
        defaultMessage: 'Code « Discipline Médico Tarifaire » (DMT)',
    },
    'health.cost-coverages.requests.hospitalization.dmtCode.help': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.dmt-code.help',
        defaultMessage:
            "<l>Vous pouvez retrouver le code DMT dans le document remis par votre établissement hospitalier.</l><l>Si le code DMT n'apparait pas sur le document, veuillez indiquer s'il s'agit d'une hospitalisation médicale ou chirurgicale.</l>",
    },
    'health.cost-coverages.requests.hospitalization.treatmentMethod.title': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.treatment-method.title',
        defaultMessage: 'Mode de traitement',
    },
    'health.cost-coverages.requests.hospitalization.treatmentMethod.help': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.treatment-method.help',
        defaultMessage:
            '<l>Vous pouvez retrouver le mode de traitement dans le document remis par votre établissement hospitalier.</l> <l>Exemple : hospitalisation complète, hospitalisation ambulatoire, etc.</l>',
    },
    'health.cost-coverages.requests.hospitalization.hospitalEmail.help': {
        id: 'api.forms.health.cost-coverages.requests.hospitalization.hospital-email.help',
        defaultMessage:
            "L'e-mail de l'établissement hospitalier est obligatoire pour pouvoir contacter l'établissement.",
    },
    'health.refunds.requests.alternative-medicine.treatments.title': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.treatments.title',
        defaultMessage: 'Soins présents sur la facture',
    },
    'health.refunds.requests.alternative-medicine.treatments.description': {
        id: 'api.forms.health.refunds.requests.alternative-medicine.treatments.description',
        defaultMessage:
            '<p>Veuillez préciser tous les soins listés sur votre facture.</p><p><i><l>Par exemple :</l><l>- Si votre facture d’ostéopathie correspond à 1 séance, veuillez ajouter 1 soin.</l><l>- Si votre facture d’ostéopathie regroupe 3 séances, veuillez ajouter 3 soins.</l></i></p>',
    },
    'health.refunds.requests.title': {
        id: 'api.forms.health.refunds.requests.title',
        defaultMessage: 'Demander un remboursement',
    },
    'health.quotes.optical-audio.title': {
        id: 'api.forms.health.quotes.optical-audio.title',
        defaultMessage:
            'Envoyer un devis pour un équipement optique ou auditif',
    },
    'health.cost-coverages.requests.title': {
        id: 'api.forms.health.cost-coverages.requests.title',
        defaultMessage:
            'Demander une prise en charge de dépassement d’honoraire',
    },
    'common.beneficiary-last-name.title': {
        id: 'api.forms.common.beneficiary-last-name.title',
        defaultMessage: 'Nom du bénéficiaire',
    },
    'common.beneficiary-first-name.title': {
        id: 'api.forms.common.beneficiary-first-name.title',
        defaultMessage: 'Prénom du bénéficiaire',
    },
    'health.beneficiaries.common.beneficiary-status.title': {
        id: 'api.forms.health.beneficiaries.common.beneficiary-status.title',
        defaultMessage: 'Qualité',
    },
    'common.effective-date.title': {
        id: 'api.forms.common.effective-date.title',
        defaultMessage: 'Date d’effet souhaité',
    },
    'health.beneficiaries.common.social-security-certificate.title': {
        id: 'api.forms.health.beneficiaries.common.social-security-certificate.title',
        defaultMessage: 'Attestation de Sécurité sociale',
    },
    'health.beneficiaries.common.social-security-certificate.description': {
        id: 'api.forms.health.beneficiaries.common.social-security-certificate.description',
        defaultMessage:
            'Veuillez joindre l’attestation de Sécurité sociale du bénéficiaire concerné ou la vôtre si ce bénéficiaire vous est rattaché.',
    },
    'health.beneficiaries.new.birth-certificate.title': {
        id: 'api.forms.health.beneficiaries.new.birth-certificate.title',
        defaultMessage: 'Justificatif de naissance',
    },
    'health.beneficiaries.new.birth-certificate.description': {
        id: 'api.forms.health.beneficiaries.new.birth-certificate.description',
        defaultMessage:
            'Dans le cas de l’ajout d’un nouveau-né, veuillez joindre son justificatif de naissance.',
    },
    'health.beneficiaries.new.child.title': {
        id: 'api.forms.health.beneficiaries.new.child.title',
        defaultMessage: 'Ajouter un enfant',
    },
    'health.beneficiaries.new.child.description': {
        id: 'api.forms.health.beneficiaries.new.child.description',
        defaultMessage:
            'Selon votre contrat, vous pouvez faire bénéficier vos ayants droit de votre couverture santé. Pour connaître les conditions d’adhésion pour l’ajout de vos enfants, veuillez vous référer à votre Notice d’information.',
    },
    'health.beneficiaries.new.socialSecurityRegime.title': {
        id: 'api.forms.health.beneficiaries.new.socialSecurityRegime.title',
        defaultMessage: 'Régime Sécurité sociale :',
    },
    'health.beneficiaries.new.child.attachedToOtherComplementary.title': {
        id: 'api.forms.health.beneficiaries.new.child.attachedToOtherComplementary.title',
        defaultMessage:
            'Votre enfant bénéficie-t-il déjà d’un contrat santé géré par un autre organisme complémentaire ?',
    },
    'health.beneficiaries.new.doubleTeletransmission.title': {
        id: 'api.forms.health.beneficiaries.new.doubleTeletransmission.title',
        defaultMessage: 'Souhaitez-vous la double télétransmission ?',
    },
    'health.beneficiaries.new.child.social-security-certificate.title': {
        id: 'api.forms.health.beneficiaries.new.child.social-security-certificate.title',
        defaultMessage:
            'Attestation de droits de Sécurité sociale de votre enfant',
    },
    'health.beneficiaries.new.child.social-security-certificate.description': {
        id: 'api.forms.health.beneficiaries.new.child.social-security-certificate.description',
        defaultMessage:
            "Veuillez joindre l'attestation de droits de Sécurité sociale de votre enfant ou à défaut l’acte de naissance.",
    },
    'health.beneficiaries.new.child.parents-social-security-certificate.title':
        {
            id: 'api.forms.health.beneficiaries.new.child.parents-social-security-certificate.title',
            defaultMessage:
                'Attestation de droits de Sécurité sociale des deux parents',
        },
    'health.beneficiaries.new.child.parents-social-security-certificate.description':
        {
            id: 'api.forms.health.beneficiaries.new.child.parents-social-security-certificate.description',
            defaultMessage:
                'Si vous avez répondu que vous souhaitez la double télétransmission, veuillez joindre l’attestation de droits de Sécurité sociale des deux parents.',
        },
    'health.beneficiaries.new.partner.description': {
        id: 'api.forms.health.beneficiaries.new.partner.description',
        defaultMessage:
            'Selon votre contrat, vous pouvez faire bénéficier vos ayants droit de votre couverture santé. Pour connaître les conditions d’adhésion pour l’ajout de votre conjoint / concubin, veuillez vous référer à votre Notice d’information.',
    },
    'health.beneficiaries.new.partner.attachedToOtherComplementary.title': {
        id: 'api.forms.health.beneficiaries.new.partner.attachedToOtherComplementary.title',
        defaultMessage:
            'Votre conjoint / concubin bénéficie-t-il déjà d’un contrat santé géré par un autre organisme complémentaire ?',
    },
    'health.beneficiaries.new.partner-certificate.title': {
        id: 'api.forms.health.beneficiaries.new.partner-certificate.title',
        defaultMessage: 'Justificatif',
    },
    'health.beneficiaries.new.partner-certificate.description': {
        id: 'api.forms.health.beneficiaries.new.partner-certificate.description',
        defaultMessage:
            "<p>Selon votre situation, veuillez joindre :</p><l>- l'attestation de PACS</l><l>- ou le certificat de concubinage</l><l>- ou une attestation sur l’honneur de vie commune</l><l>- ou un justificatif de vie commune</l>",
    },
    'health.beneficiaries.new.partner.social-security-certificate.description':
        {
            id: 'api.forms.health.beneficiaries.new.partner.social-security-certificate.description',
            defaultMessage:
                "Veuillez joindre  l'attestation de droits de Sécurité sociale de votre conjoint / concubin.",
        },
    'common.answer.yes': {
        id: 'api.forms.common.answer.yes',
        defaultMessage: 'Oui',
    },
    'common.answer.no': {
        id: 'api.forms.common.answer.no',
        defaultMessage: 'Non',
    },
    'common.general': {
        id: 'api.forms.common.general',
        defaultMessage: 'Régime général',
    },
    'common.msa': {
        id: 'api.forms.common.msa',
        defaultMessage: 'MSA',
    },
    'common.other-or-alsace-moselle': {
        id: 'api.forms.common.other-or-alsace-moselle',
        defaultMessage: 'Autre dont Régime local Alsace-Moselle',
    },
    'messages.dematerialized-communication.activation.title': {
        id: 'api.forms.messages.dematerialized-communication.activation.title',
        defaultMessage:
            'Souhaitez-vous recevoir vos documents par e-mail plutôt que par courrier postal ?',
    },
    'messages.dematerialized-communication.title': {
        id: 'api.forms.messages.dematerialized-communication.title',
        defaultMessage: 'Modifier mon option "Mes courriers en ligne"',
    },
    'messages.dematerialized-communication.description': {
        id: 'api.forms.messages.dematerialized-communication.description',
        defaultMessage:
            '<p>Pourquoi adhérer à l\'option "Mes courriers en ligne" ?</p><l>- Pratique : vous recevez un e-mail avec le décompte de chaque prestation</l><l>- Gratuit : vous ne payez aucuns frais supplémentaires</l><l>- Conforme : vos documents ont la même valeur juridique</l><l>- Réversible : vous pouvez choisir à tout moment de revenir aux documents papier</l>',
    },
    'health.bank-details.contributions.ribDetails.owner.description': {
        id: 'api.forms.health.bank-details.contributions.rib-details.owner.description',
        defaultMessage: 'Indiquez le nom et le prénom.',
    },
    'health.bank-details.contributions.description': {
        id: 'api.forms.health.bank-details.contributions.description',
        defaultMessage:
            '<p><l>Pour modifier les coordonnées bancaires de cotisations, il suffit de saisir les informations ci-dessous et de signer électroniquement le mandat de prélèvement SEPA pré-rempli.</l><l>Cette modification concerne uniquement le RIB sur lequel vous êtes prélevés de vos cotisations. Si vous souhaitez modifier vos coordonnées bancaires utilisées pour vos remboursements, nous vous invitons à modifier le RIB de remboursement.</l></p>',
    },
    'health.bank-details.contributions.ribDetails.bic.title': {
        id: 'api.forms.health.bank-details.contributions.ribDetails.bic.title',
        defaultMessage: 'BIC',
    },
    'health.bank-details.contributions.ribDetails.title': {
        id: 'api.forms.health.bank-details.contributions.ribDetails.title',
        defaultMessage: 'Coordonnées bancaires',
    },
    'health.bank-details.contributions.ribDetails.description': {
        id: 'api.forms.health.bank-details.contributions.ribDetails.description',
        defaultMessage:
            'Le compte bancaire doit être domicilié en France ou à Monaco.',
    },
    'health.bank-details.contributions.address.title': {
        id: 'api.forms.health.bank-details.contributions.address.title',
        defaultMessage: 'Adresse postale du titulaire du compte',
    },
    'health.bank-details.contributions.signature-location.title': {
        id: 'api.forms.health.bank-details.contributions.signature-location.title',
        defaultMessage: 'Demande faite à',
    },
    'health.bank-details.contributions.signature-location.description': {
        id: 'api.forms.health.bank-details.contributions.signature-location.description',
        defaultMessage: 'Indiquez la ville où vous effectuez la demande.',
    },
    'signature.phonesend-code.label': {
        id: 'api.forms.signature.phonesend-code.label',
        defaultMessage: 'Envoyer le code',
    },
    'signature.phone.phoneSelection.title': {
        id: 'api.forms.signature.phone.phone-selection.title',
        defaultMessage: 'Numéro de téléphone',
    },
    'signature.phone.description': {
        id: 'api.forms.signature.phone.description',
        defaultMessage:
            'Sélectionnez le numéro de téléphone sur lequel vous souhaitez recevoir votre code de vérification.',
    },
    'signature.phone.title': {
        id: 'api.forms.signature.phone.title',
        defaultMessage: 'Code de vérification',
    },
    'health.bank-details.refunds.ribDetails.bic.placeholder': {
        id: 'api.forms.health.bank-details.refunds.ribDetails.bic.placeholder',
        defaultMessage: '8 ou 11 caractères',
    },
    'health.contact.immediate.title': {
        id: 'forms.health.contact.immediate.title',
        defaultMessage: 'Être rappelé',
    },
    'health.contact.immediate.description': {
        id: 'forms.health.contact.immediate.description',
        defaultMessage:
            'Renseignez le numéro de téléphone sur lequel vous souhaitez être appelé.',
    },
    'health.contact.phone.title': {
        id: 'widgets.health.contact.phone.title',
        defaultMessage: 'Numéro de téléphone',
    },
    'health.contact.date.title': {
        id: 'widgets.health.contact.date.title',
        defaultMessage: 'Date du rendez-vous',
    },
    'health.contact.appointmentSlot.title': {
        id: 'widgets.health.contact.appointmentSlot.title',
        defaultMessage: 'Créneau horaire',
    },
    'health.contact.appointment.title': {
        id: 'forms.health.contact.appointment.title',
        defaultMessage: 'Choisir un créneau pour être appelé',
    },
    'health.contact.appointment.description': {
        id: 'forms.health.contact.appointment.description',
        defaultMessage:
            'Renseignez le numéro de téléphone sur lequel vous souhaitez être appelé.',
    },
    'common.membershipNumber.title': {
        id: 'widgets.common.membershipNumber.title',
        defaultMessage: 'Numéro d’adhérent iSanté',
    },
    'common.membershipNumber.description': {
        id: 'widgets.common.membershipNumber.description',
        defaultMessage:
            "<l>Vous pouvez trouver votre numéro d’adhérent iSanté à l'intérieur de votre carte de tiers payant (en haut à droite).</l><l>Attention, à ne pas confondre avec le N° télétransmission.</l>",
    },
    'health.tp-card.request.validation.description': {
        id: 'widgets.health.tp-card.request.validation.description',
        defaultMessage:
            'Je souhaite recevoir une nouvelle carte de tiers payant.',
    },
    'user.registration.check.title': {
        id: 'forms.user.registration.check.title',
        defaultMessage: 'Demande de création de compte',
    },
    'user.registration.check.description': {
        id: 'forms.user.registration.check.description',
        defaultMessage:
            "Pour créer votre compte, veuillez compléter les champs suivants (le nom et le prénom sont ceux de <b>l'adhérent principal</b>)<nbsp></nbsp>:",
    },
    'common.firstname.title': {
        id: 'widgets.common.firstname.title',
        defaultMessage: 'Prénom',
    },
    'common.firstname.help': {
        id: 'widgets.common.firstname.help',
        defaultMessage:
            'Si vous avez un prénom composé, veuillez essayer de le saisir avec un espace ou avec un tiret.',
    },
    'documents.certificate.health.title': {
        id: 'forms.documents.certificate.health.title',
        defaultMessage: "Demande d'attestation d'appartenance",
    },
    'documents.certificate.health.description': {
        id: 'forms.documents.certificate.health.description',
        defaultMessage:
            '<l>Veuillez cocher la case suivante pour demander l’envoi de votre attestation d’appartenance.</l><l>Vous pourrez la retrouver d’ici quelques instants dans vos messages.</l>',
    },
    'documents.certificate.health.validation.description': {
        id: 'widgets.documents.certificate.health.validation.description',
        defaultMessage:
            'Je demande l’envoi de mon attestation d’appartenance sur {appName}.',
    },
    'subscription.health-product.consent.title': {
        id: 'widgets.subscription.health-product.consent.title',
        defaultMessage: 'En signant électroniquement, je consens :',
    },
    'subscription-consent.description': {
        id: 'widgets.subscription-consent.description',
        defaultMessage: 'À la souscription de l’option ou surcomplémentaire',
    },
    'sepa-consent.description': {
        id: 'widgets.sepa-consent.description',
        defaultMessage: 'À l’utilisation et à la signature du mandat SEPA',
    },
    'form-consent.description': {
        id: 'widgets.form-consent.description',
        defaultMessage: 'À la signature du bulletin d’adhésion',
    },
    'user.registration.activation.title': {
        id: 'forms.user.registration.activation.title',
        defaultMessage: 'Activation de compte',
    },
    'user.registration.activation.description': {
        id: 'forms.user.registration.activation.description',
        defaultMessage:
            "<p><i>Le code de sécurité que vous avez reçu par courrier vous sera demandé à l'étape suivante.</i></p><p>Veuillez compléter les champs suivants (le nom et le prénom sont ceux de <b>l'adhérent principal</b>)<nbsp></nbsp>:</p>",
    },
    'subscription.bank-details.contributions.title': {
        id: 'forms.subscription.bank-details.contributions.title',
        defaultMessage: 'Indiquer mon RIB de prélèvement',
    },
    'subscription.bank-details.contributions.description': {
        id: 'forms.subscription.bank-details.contributions.description',
        defaultMessage:
            '<l>Pour modifier les coordonnées bancaires de prélèvement, veuillez saisir les informations ci-dessous.</l><l><i>Le mandat SEPA pré-rempli vous sera présenté à la prochaine étape afin de le valider et le signer électroniquement.</i></l>',
    },
    'rib-details.owner.title': {
        id: 'widgets.rib-details.owner.title',
        defaultMessage: 'Titulaire du compte',
    },
    'rib-details.owner.description': {
        id: 'widgets.rib-details.owner.description',
        defaultMessage: 'Indiquez le nom et le prénom.',
    },
    'rib-details.iban.title': {
        id: 'widgets.rib-details.iban.title',
        defaultMessage: 'IBAN',
    },
    'rib-details.iban.placeholder': {
        id: 'widgets.rib-details.iban.placeholder',
        defaultMessage: 'FR00 0000 0000 0000 0000 0000 000',
    },
    'rib-details.bic.title': {
        id: 'widgets.rib-details.bic.title',
        defaultMessage: 'BIC',
    },
    'rib-details.title': {
        id: 'widgets.rib-details.title',
        defaultMessage: 'Coordonnées bancaires',
    },
    'rib-details.description': {
        id: 'widgets.rib-details.description',
        defaultMessage:
            'Le compte bancaire doit être domicilié en France ou à Monaco.',
    },
    'rib-details.iban.description': {
        id: 'widgets.rib-details.iban.description',
        defaultMessage: 'Renseignez votre IBAN de 27 caractères sans espace.',
    },
    'rib-details.bank-name.title': {
        id: 'widgets.rib-details.bank-name.title',
        defaultMessage: 'Nom de l’établissement',
    },
    'attachments.rib.title': {
        id: 'widgets.attachments.rib.title',
        defaultMessage: 'Joindre le RIB',
    },
    'bank-details.rib-iban': {
        id: 'bank-details.rib-iban',
        defaultMessage: 'IBAN',
    },
    'bank-details.rib-bic': {
        id: 'bank-details.rib-bic',
        defaultMessage: 'BIC',
    },
    'bank-details.rib-bank-name': {
        id: 'bank-details.rib-bank-name',
        defaultMessage: 'Nom de l’établissement',
    },
    'contributions.address.title': {
        id: 'widgets.contributions.address.title',
        defaultMessage: 'Adresse postale du titulaire du compte',
    },
    'contributions.attachmentFiles.title': {
        id: 'widgets.contributions.attachmentFiles.title',
        defaultMessage: 'Joindre le relevé d’identité bancaire (RIB)',
    },
    'contributions.signature-location.title': {
        id: 'widgets.contributions.signature-location.title',
        defaultMessage: 'Demande faite à',
    },
    'contributions.signature-location.description': {
        id: 'widgets.contributions.signature-location.description',
        defaultMessage: 'Indiquez la ville où vous effectuez la demande.',
    },
    'contributions.signature-location.primo-description': {
        id: 'widgets.contributions.signature-location.primo-description',
        defaultMessage:
            'Renseignez la ville où vous vous situez. Cette information sera nécessaire pour la validation du mandat SEPA.',
    },
    'common.careDate.title': {
        id: 'widgets.common.careDate.title',
        defaultMessage: 'Date de soins',
    },
    'death-benefit-simulation.title': {
        id: 'forms.death-benefit-simulation.title',
        defaultMessage: 'Calculer mon capital',
    },
    'provident.family-situation.title': {
        id: 'widgets.provident.family-situation.title',
        defaultMessage: 'Situation familiale',
    },
    single: {
        id: 'widgets.single',
        defaultMessage: 'Célibataire',
    },
    widower: {
        id: 'widgets.widower',
        defaultMessage: 'Veuf',
    },
    divorced: {
        id: 'widgets.divorced',
        defaultMessage: 'Divorcé',
    },
    married: {
        id: 'widgets.married',
        defaultMessage: 'Marié',
    },
    partner: {
        id: 'widgets.partner',
        defaultMessage: 'En concubinage',
    },
    'civil-partnership': {
        id: 'widgets.civil-partnership',
        defaultMessage: 'Pacsé',
    },
    'provident.number-of-children.title': {
        id: 'forms.provident.number-of-children.title',
        defaultMessage: 'Nombre d’enfants à charge',
    },
    'provident.annual-salary.title': {
        id: 'widgets.provident.annual-salary.title',
        defaultMessage: 'Salaire annuel brut',
    },
    'beneficiaries-designation.person-quality.title': {
        id: 'forms.beneficiaries-designation.person-quality.title',
        defaultMessage: 'Personne physique par sa qualité',
    },
    'quality.title': {
        id: 'widgets.quality.title',
        defaultMessage: 'Personne physique par sa qualité',
    },
    'quality.description': {
        id: 'widgets.quality.description',
        defaultMessage:
            'S’il s’agit d’un groupe de personnes (ex : “mes enfants”), votre capital sera réparti à parts égales entre elles.',
    },
    'quality.placeholder': {
        id: 'widgets.quality.placeholder',
        defaultMessage: 'Sélectionner',
    },
    'beneficiaries-designation.person-name.title': {
        id: 'forms.beneficiaries-designation.person-name.title',
        defaultMessage: 'Personne physique nominativement',
    },
    'common.beneficiary-birth-place.title': {
        id: 'widgets.common.beneficiary-birth-place.title',
        defaultMessage: 'Commune de naissance',
    },
    'beneficiaries-designation.legal-entity.title': {
        id: 'forms.beneficiaries-designation.legal-entity.title',
        defaultMessage: 'Personne morale',
    },
    'common.corporate-name.title': {
        id: 'widgets.common.corporate-name.title',
        defaultMessage: 'Raison sociale',
    },
    'common.corporate-id.title': {
        id: 'widgets.common.corporate-id.title',
        defaultMessage: 'Numéro SIREN ou RNA',
    },
    'address.postal-address.title': {
        id: 'widgets.address.postal-address.title',
        defaultMessage: 'Adresse postale',
    },
    'guarantee-group.optical-panier-libre': {
        id: 'simulator.guarantee-group.optical-panier-libre',
        defaultMessage: 'Panier libre',
    },
    'guarantee.optical-panier-libre.description': {
        id: 'widgets.guarantee.optical-panier-libre.description',
        defaultMessage:
            'Dans le cadre du panier libre, les montures sont prises en charge à hauteur de 100 € par votre régime obligatoire et nos services.',
    },
    'guarantee-expression.euro.rss': {
        id: 'widgets.guarantee-expression.euro-rss',
        defaultMessage: 'X€ - RSS',
    },
    'common.capital-percentage.title': {
        id: 'widgets.common.capital-percentage.title',
        defaultMessage: '% du capital attribué à ce bénéficiaire',
    },
    'common.default-beneficiary.title': {
        id: 'widgets.common.default-beneficiary.title',
        defaultMessage: 'Si le bénéficiaire décède avant moi',
    },
    'default-beneficiary.their-heirs': {
        id: 'widgets.default-beneficiary.their-heirs',
        defaultMessage: 'Sa part sera reversée à ses héritiers',
    },
    'default-beneficiary.divided-between-designated-beneficiaries': {
        id: 'widgets.default-beneficiary.divided-between-designated-beneficiaries',
        defaultMessage:
            'Sa part sera répartie entre les autres bénéficiaires désignés à parts égales.',
    },
    visioLinkComing: {
        id: 'workflow-teleconsultation.channel.video.field-description',
        defaultMessage:
            'Vous allez être contacté par téléphone par un médecin à ce numéro avant de pouvoir accéder à la visio.',
    },
    phoneDescription: {
        id: 'workflow-teleconsultation.channel.phone.field-description',
        defaultMessage: 'Vous allez être rappelé sur ce numéro.',
    },
    phoneTitle: {
        id: 'workflow-teleconsultation.channel.phone.field-title',
        defaultMessage:
            'Entrez le numéro sur lequel vous souhaitez être rappelé.',
    },
    phone: {
        id: 'workflow-teleconsultation.channel.phone.label',
        defaultMessage: 'Téléphone',
    },
    visio: {
        id: 'workflow-teleconsultation.channel.video.label',
        defaultMessage: 'Visio',
    },
    visioTitle: {
        id: 'workflow-teleconsultation.channel.video.field-title',
        defaultMessage: 'Numéro de téléphone de contact',
    },
    visioDescription: {
        id: 'workflow-teleconsultation.channel.video.field-description',
        defaultMessage:
            'Vous allez être contacté par téléphone par un médecin à ce numéro avant de pouvoir accéder à la visio.',
    },
    inFrance: {
        id: 'widgets.workflow-teleconsultation.country.in-france',
        defaultMessage: 'En France',
    },
    outsideFrance: {
        id: 'workflow-teleconsultation.country.outside-france.label',
        defaultMessage: 'En dehors de France',
    },
    outsideFranceListTitle: {
        id: 'workflow-teleconsultation.country.outside-france.list-title',
        defaultMessage: 'Choisir le pays où vous vous situez',
    },
    outsideFranceListPlaceholder: {
        id: 'workflow-teleconsultation.country.outside-france.list-placeholder',
        defaultMessage: 'Pays',
    },
    outsideFranceUeWarning: {
        id: 'workflow-teleconsultation.country.outside-france.ue-warning',
        defaultMessage:
            "Etant dans un pays membre de l'Union Européenne, notre médecin pourra vous délivrer une ordonnance si votre besoin de santé le justifie. Toutefois, il est possible que vous rencontriez des difficultés pour l'utiliser dans le pays où vous vous situez (selon la langue, la législation du pays…).",
    },
    outsideFranceOtherWarning: {
        id: 'workflow-teleconsultation.country.outside-france.other-warning',
        defaultMessage:
            "Si je suis dans un pays hors de l'Union Européenne, le médecin ne pourra pas vous délivrer d'ordonnance. Il pourra néanmoins répondre à toutes vos questions et vous conseiller le mieux possible.",
    },
    reasonTextMessageInputTitle: {
        id: 'workflow-teleconsultation.motive.written-area-text-title',
        defaultMessage: 'Ma demande',
    },
    reasonTitle: {
        id: 'workflow-teleconsultation.motive.area-text-title',
        defaultMessage: 'Motif',
    },
    reasonTextMessageInputPlaceholder: {
        id: 'workflow-teleconsultation.motive.written.area-text-placeholder',
        defaultMessage: 'Détails de ma demande',
    },
    reasonPlaceholder: {
        id: 'workflow-teleconsultation.motive.area-text-placeholder',
        defaultMessage: 'Décrivez ici vos symptômes.',
    },
    'beneficiaries-designation.consent.title': {
        id: 'forms.beneficiaries-designation.consent.title',
        defaultMessage: 'En signant ce document',
    },
    'add-beneficiary.child-title': {
        id: 'forms.add-beneficiary.child-title',
        defaultMessage: 'Ajouter un enfant mineur',
    },
    'add-beneficiary.child-description': {
        id: 'forms.add-beneficiary.child-description',
        defaultMessage:
            'Vous avez uniquement la possibilité d’ajouter vos enfants mineurs.',
    },
    'date.birth-date.title': {
        id: 'widgets.date.birth-date.title',
        defaultMessage: 'Date de naissance',
    },
    'date.birth-date.child-description': {
        id: 'widgets.date.birth-date.child-description',
        defaultMessage:
            'Rappel : seuls les enfants de moins de 18 ans peuvent être ajoutés via ce formulaire.',
    },
    'date.placeholder': {
        id: 'widgets.date.placeholder',
        defaultMessage: 'jj/mm/aaaa',
    },
    'request-refund.optical.description': {
        id: 'forms.request-refund.optical.description',
        defaultMessage:
            'Vous pouvez faire une demande de remboursement lorsque vous avez avancé des frais chez un opticien pour de nouvelles lunettes ou de nouvelles lentilles par exemple.',
    },
    'request-refund.medicine.description': {
        id: 'forms.request-refund.medicine.description',
        defaultMessage:
            'Vous pouvez faire une demande de remboursement lorsque vous avez avancé des frais pour des médicaments prescrits par votre médecin et non pris en charge par la Sécurité sociale.',
    },
    'request-refund.hospitalization.description': {
        id: 'forms.request-refund.hospitalization.description',
        defaultMessage:
            'Vous pouvez faire une demande de remboursement lorsque vous avez avancé les frais lors de votre hospitalisation (frais de séjour, chambre particulière, anesthésie, …).',
    },
    'send-quote.optical.description': {
        id: 'forms.send-quote.optical.description',
        defaultMessage: `Vous pouvez joindre votre devis et compléter les informations suivantes afin d'obtenir une estimation du remboursement de vos lentilles, verres, montures, etc.`,
    },
    'send-quote.dental.description': {
        id: 'forms.send-quote.dental.description',
        defaultMessage: `Vous pouvez joindre votre devis et compléter les informations suivantes afin d'obtenir une estimation du remboursement de vos soins dentaires.`,
    },
    'extend-coverage.request.title': {
        id: 'forms.extend-coverage.request.title',
        defaultMessage: `<p>1<sup>ère</sup> demande de maintien de ma complémentaire santé</p>`,
    },
    'extend-coverage.request.description': {
        id: 'forms.extend-coverage.request.description',
        defaultMessage: `Pour pouvoir bénéficier de la prolongation de vos droits, vous devez être inscrit auprès de Pôle emploi et bénéficier de l'allocation d'Aide au Retour à l'Emploi (ARE).`,
    },
    'attachments.are-certificate.title': {
        id: 'widgets.attachments.are-certificate.title',
        defaultMessage: `Attestation d'Aide au retour à l'Emploi (ARE)`,
    },
    'attachments.are-certificate.description': {
        id: 'widgets.attachments.are-certificate.description',
        defaultMessage: `Elle doit mentionner la date à laquelle l’indemnisation débute.`,
    },
    'attachments.are-certificate.help': {
        id: 'widgets.attachments.are-certificate.help',
        defaultMessage: `L'attestation d'Aide au retour à l'Emploi est disponible sur votre espace Pôle emploi.`,
    },
    'teletransmission.deletion.title': {
        id: 'forms.teletransmission.deletion.title',
        defaultMessage: `Désactiver la télétransmission`,
    },
    'teletransmission.deletion.description': {
        id: 'forms.teletransmission.deletion.description',
        defaultMessage: `<p><l>En l’absence de télétransmission, vous devrez nous envoyer vos décomptes de Sécurité sociale pour être remboursé.</l><l>Pour obtenir des informations concernant votre télétransmission, vous pouvez consulter votre espace personnel sur ameli.fr ou vous rapprocher de votre caisse de Sécurité sociale (MSA, CNMSS…).</l></p>`,
    },
    'teletransmission.activate.title': {
        id: 'forms.teletransmission.activate.title',
        defaultMessage: 'Activer la télétransmission',
    },
    'teletransmission.question.description': {
        id: 'forms.teletransmission.question.description',
        defaultMessage:
            '<p><l>La télétransmission est un système d’échange de données entre la Sécurité sociale et la complémentaire santé.</l><l>Lorsqu’elle est activée, elle vous permet d’éviter l’envoi de documents et d’être remboursé plus rapidement.</l></p>',
    },
    'checkbox.teletransmission-deletion.description': {
        id: 'widgets.checkbox.teletransmission-deletion.description',
        defaultMessage: `En cochant cette case, je confirme vouloir désactiver la télétransmission du bénéficiaire concerné.`,
    },
    'informations-accuracy.description': {
        id: 'widgets.informations-accuracy.description',
        defaultMessage:
            'Je déclare toutes les informations fournies exactes et sincères.',
    },
    'contract-check.description': {
        id: 'widgets.contract-check.description',
        defaultMessage:
            "Je reconnais avoir reçu et pris connaissance de la Notice d'information de mon contrat et de ses annexes.",
    },
    'beneficiaries-designation.consent.description': {
        id: 'widgets.beneficiaries-designation.consent.description',
        defaultMessage:
            'Je consens à la signature de ma désignation de bénéficiaires.',
    },
    'revert-to-standard.description': {
        id: 'widgets.revert-to-standard.description',
        defaultMessage:
            "J'avais précédemment rempli une clause spécifique et je souhaite la modifier pour revenir sur la clause standard du contrat.",
    },
    'beneficiaries-designation.partner': {
        id: 'widgets.beneficiaries-designation.partner',
        defaultMessage:
            'Mon conjoint, tel que précisé dans la clause standard du contrat',
    },
    'beneficiaries-designation.cohabitant': {
        id: 'widgets.beneficiaries-designation.cohabitant',
        defaultMessage: 'Mon concubin',
    },
    'beneficiaries-designation.civil-partner': {
        id: 'widgets.beneficiaries-designation.civil-partner',
        defaultMessage: 'Mon partenaire de Pacs',
    },
    'beneficiaries-designation.children': {
        id: 'widgets.beneficiaries-designation.children',
        defaultMessage: 'Mes enfants, nés ou à naitre, vivants ou représentés',
    },
    'beneficiary.last-name.child-title': {
        id: 'widgets.beneficiary.last-name.child-title',
        defaultMessage: 'Nom de l’enfant',
    },
    'beneficiary.first-name.child-title': {
        id: 'widgets.beneficiary.first-name.child-title',
        defaultMessage: 'Prénom de l’enfant',
    },
    'date.birth-date.child-title': {
        id: 'widgets.date.birth-date.child-title',
        defaultMessage: 'Date de naissance de l’enfant',
    },
    'social-security-number.child-description': {
        id: 'widgets.social-security-number.child-description',
        defaultMessage:
            'Le numéro de Sécurité sociale de l’enfant si celui-ci a plus de 16 ans, sinon à défaut celui du parent auquel l’enfant sera rattaché.',
    },
    'teletransmission.establish.title': {
        id: 'widgets.teletransmission.establish.title',
        defaultMessage:
            'Souhaitez-vous activer la télétransmission pour être remboursé plus facilement et plus rapidement ?',
    },
    'teletransmission.establish.child-description': {
        id: 'widgets.teletransmission.establish.child-description',
        defaultMessage:
            "Si votre enfant est déjà rattaché à la complémentaire de l'autre parent, il bénéficiera de la double-télétransmission. Il sera donc rattaché aux complémentaires des deux parents. Ainsi, pour les soins de l’enfant, n’importe quel parent peut présenter sa carte de tiers-payant et être remboursé plus rapidement.",
    },
    'teletransmission.help': {
        id: 'widgets.teletransmission.help',
        defaultMessage:
            'La télétransmission est un système d’échange de données entre la Sécurité sociale et la complémentaire santé. Lorsqu’elle est activée, elle vous permet d’éviter l’envoi de documents et d’être remboursé plus rapidement.',
    },
    'attachments.social-security-certificate.child-title': {
        id: 'widgets.attachments.social-security-certificate.child-title',
        defaultMessage: 'Attestation de Sécurité sociale de l’enfant',
    },
    'attachments.social-security-certificate.child-description': {
        id: 'widgets.attachments.social-security-certificate.child-description',
        defaultMessage: 'Ou à défaut l’acte de naissance.',
    },
    'attachments.social-security-certificate.help': {
        id: 'widgets.attachments.social-security-certificate.help',
        defaultMessage:
            'L’attestation de Sécurité sociale est disponible sur le site de votre Sécurité sociale (ameli.fr, MSA.fr, …).',
    },
    'attachments.social-security-certificate.parents-title': {
        id: 'widgets.attachments.social-security-certificate.parents-title',
        defaultMessage: 'Attestation de Sécurité sociale du / des parents',
    },
    'attachments.social-security-certificate.parents-description': {
        id: 'widgets.attachments.social-security-certificate.parents-description',
        defaultMessage:
            'Si vous avez répondu oui pour activer la télétransmission, veuillez joindre votre attestation de Sécurité sociale. En cas de double-télétransmission, veuillez joindre les attestations des deux parents.',
    },
    'health.beneficiaries.new.partner.title': {
        id: 'widgets.health.beneficiaries.new.partner.title',
        defaultMessage: 'Ajouter mon conjoint',
    },
    'beneficiary.last-name.partner-title': {
        id: 'widgets.beneficiary.last-name.partner-title',
        defaultMessage: 'Nom du conjoint',
    },
    'beneficiary.first-name.partner-title': {
        id: 'widgets.beneficiary.first-name.partner-title',
        defaultMessage: 'Prénom du conjoint',
    },
    'date.birth-date.partner-title': {
        id: 'widgets.date.birth-date.partner-title',
        defaultMessage: 'Date de naissance du conjoint',
    },
    'social-security-number.partner-title': {
        id: 'widgets.social-security-number.partner-title',
        defaultMessage: 'Numéro de Sécurité sociale (15 chiffres) du conjoint',
    },
    'teletransmission.establish.partner-description': {
        id: 'widgets.teletransmission.establish.partner-description',
        defaultMessage:
            "Si vous souhaitez activer la télétransmission et que votre conjoint est déjà rattaché à un autre organisme complémentaire, il doit d'abord désactiver la télétransmission auprès de cet autre organisme. En effet, la Sécurité sociale ne peut gérer qu’un seul organisme complémentaire.",
    },
    'attachments.social-security-certificate.partner-title': {
        id: 'widgets.attachments.social-security-certificate.partner-title',
        defaultMessage: 'Attestation de Sécurité sociale de votre conjoint',
    },
    'message.beneficiary-deletion.description': {
        id: 'widgets.message.beneficiary-deletion.description',
        defaultMessage:
            'Précisez les informations du bénéficiaire concerné (nom, prénom…) et la date de résiliation souhaitée.',
    },
    'message.teletransmission-question.description': {
        id: 'widgets.message.teletransmission-question.description',
        defaultMessage:
            'Précisez les informations du bénéficiaire concerné (nom, prénom…) et détaillez votre question.',
    },
    'message.teletransmission-question.placeholder': {
        id: 'widgets.message.teletransmission-question.placeholder',
        defaultMessage:
            'Exemple : Je vous informe que ma télétransmission ne fonctionne pas...',
    },
    'attachments.social-security-certificate.title': {
        id: 'widgets.attachments.social-security-certificate.title',
        defaultMessage: 'Attestation de Sécurité sociale',
    },
    'attachments.social-security-certificate.question-description': {
        id: 'widgets.attachments.social-security-certificate.question-description',
        defaultMessage:
            'Si votre télétransmission ne fonctionne pas pour vous ou l’un de vos bénéficiaires, veuillez joindre l’attestation de Sécurité sociale de la personne concernée. En l’absence de télétransmission, vous devrez nous envoyer vos décomptes de Sécurité sociale pour être remboursé.',
    },
    'attachments.compulsory-health-insurance-certificate.title': {
        id: 'widgets.attachments.compulsory-health-insurance-certificate.title',
        defaultMessage: 'Attestation de mutuelle obligatoire',
    },
    'attachments.compulsory-health-insurance-certificate.description': {
        id: 'widgets.attachments.compulsory-health-insurance-certificate.description',
        defaultMessage:
            'Veuillez joindre une attestation mentionnant que le bénéficiaire que vous souhaitez résilier bénéficie d’une mutuelle obligatoire.',
    },
    'attachments.compulsory-health-insurance-certificate.help': {
        id: 'widgets.attachments.compulsory-health-insurance-certificate.help',
        defaultMessage:
            'Le bénéficiaire concerné peut demander cette attestation à son employeur.',
    },
    other: {
        id: 'widgets.other',
        defaultMessage: 'Autre',
    },
    'beneficiary.request-refund.help': {
        id: 'widgets.beneficiary.request-refund.help',
        defaultMessage: `Chaque demande de remboursement ne doit concerner qu'une seule prestation et un seul bénéficiaire.`,
    },
    'beneficiary.request-refund.medicine-help': {
        id: 'widgets.beneficiary.request-refund.medicine-help',
        defaultMessage:
            'Chaque demande de remboursement ne doit concerner qu’un seul bénéficiaire.',
    },
    'attachments.invoice-file.medicine-description': {
        id: 'widgets.attachments.invoice-file.medicine-description',
        defaultMessage:
            'Veuillez joindre la facture avec la mention "acquittée" délivrée par la pharmacie.',
    },
    'attachments.invoice-file.medicine-help': {
        id: 'widgets.attachments.invoice-file.medicine-help',
        defaultMessage:
            'Seuls les médicaments achetés en pharmacie et figurant sur la liste des médicaments ayant reçu une autorisation de mise sur le marché par l’Agence nationale de sécurité du médicament sont remboursés.',
    },
    'attachments.proof-of-reimbursement-file.title': {
        id: 'widgets.attachments.proof-of-reimbursement-file.title',
        defaultMessage: 'Autre justificatif de remboursement',
    },
    'attachments.proof-of-reimbursement-file.description': {
        id: 'widgets.attachments.proof-of-reimbursement-file.description',
        defaultMessage:
            'Vous pouvez joindre par exemple une ordonnance, une attestation de cure…',
    },
    'bank-details.iban.help': {
        id: 'widgets.bank-details.iban.help',
        defaultMessage:
            'Votre IBAN est disponible sur le RIB fourni par votre établissement bancaire.',
    },
    'beneficiary.send-quote.help': {
        id: 'widgets.beneficiary.send-quote.help',
        defaultMessage: `Chaque devis ne doit concerner qu'une seule prestation et un seul bénéficiaire.`,
    },
    'beneficiary.description': {
        id: 'widgets.beneficiary.description',
        defaultMessage: `Sélectionnez le bénéficiaire concerné :`,
    },
    'attachments.amc-file.title': {
        id: 'widgets.attachments.amc-file.title',
        defaultMessage: 'Bordereau AMC',
    },
    'attachments.amc-file.description': {
        id: 'widgets.attachments.amc-file.description',
        defaultMessage: `Veuillez joindre le bordereau AMC fourni par l'établissement hospitalier. Si aucun bordereau AMC ne vous a été fourni, à défaut la facture détaillée mentionnant la part que vous avez payé.`,
    },
    'attachments.amc-file.help': {
        id: 'widgets.attachments.amc-file.help',
        defaultMessage:
            'Le bordereau AMC (Assurance Maladie Complémentaire) est une facture acquittée que vous donne votre établissement de santé après votre séjour. Il indique le bénéficiaire des soins, la date, le professionnel de santé ainsi que les différents actes réalisés.',
    },
    'attachments.quote-file.optical-description': {
        id: 'widgets.attachments.quote-file.optical-description',
        defaultMessage:
            'Veuillez joindre le devis complet délivré par le professionnel de santé.',
    },
    'attachments.quote-file.optical-help': {
        id: 'widgets.attachments.quote-file.optical-help',
        defaultMessage:
            'Un devis complet comporte le nom et prénom de la personne concernée par le soin, la nature du soin, une répartition claire des honoraires pour chaque acte et la Base de Remboursement de la Sécurité sociale (BRSS) si le soin est remboursé par la Sécurité sociale.',
    },
    'attachments.job-seeker-certificate.title': {
        id: 'widgets.attachments.job-seeker-certificate.title',
        defaultMessage: "Justificatif de demandeur d'emploi",
    },
    'attachments.social-security-certificate.beneficiary-title': {
        id: 'widgets.attachments.social-security-certificate.beneficiary-title',
        defaultMessage:
            'Attestation de Sécurité sociale du bénéficiaire concerné',
    },
    'attachments.social-security-certificate.beneficiary-description': {
        id: 'widgets.attachments.social-security-certificate.beneficiary-description',
        defaultMessage:
            "Veuillez nous joindre l'attestation de Sécurité sociale du bénéficiaire concerné, ou à défaut, celle du parent si votre enfant n'a pas encore d'attestation de Sécurité sociale.",
    },
    'attachments.job-seeker-certificate.help': {
        id: 'widgets.attachments.job-seeker-certificate.help',
        defaultMessage:
            'Ce justificatif est disponible sur votre espace Pôle emploi sous le nom de télé-actualisation, attestation de paiement ou relevé de situation.',
    },
    'groups.help': {
        id: 'widgets.groups.help',
        defaultMessage:
            'Seuls les 50 premiers résultats seront affichés et sélectionnables. Vous pouvez affiner votre recherche pour afficher de nouveaux résultats',
    },
    'companies.help': {
        id: 'widgets.companies.help',
        defaultMessage:
            'Seuls les 50 premiers résultats seront affichés et sélectionnables. Vous pouvez affiner votre recherche pour afficher de nouveaux résultats',
    },
    'phone-number.landline.title': {
        id: 'widgets.phone-number.landline.title',
        defaultMessage: 'Numéro de téléphone fixe',
    },
    'phone-number.mobile.title': {
        id: 'widgets.phone-number.mobile.title',
        defaultMessage: 'Numéro de téléphone portable',
    },
    'phone-number-one.mobile.title': {
        id: 'widgets.phone-number-one.mobile.title',
        defaultMessage: 'Téléphone portable principal',
    },
    'phone-number.secondary.title': {
        id: 'widgets.phone-number.secondary.title',
        defaultMessage: 'Numéro de téléphone secondaire',
    },
    'date.care-date.title': {
        id: 'widgets.date.care-date.title',
        defaultMessage: 'Dates de soins',
    },
    'date.payment-date.title': {
        id: 'widgets.date.payment-date.title',
        defaultMessage: 'Dates de règlement',
    },
    'quote.hospitalization.mercer-description': {
        id: 'forms.quote.hospitalization.mercer-description',
        defaultMessage:
            "<p><l>Vous pouvez envoyer un devis établi par un professionnel de santé.</l><l>Chaque devis ne doit concerner qu'une seule prestation et un seul bénéficiaire.</l><l>A noter que pour nous permettre d'établir l'estimation chiffrée, le devis doit comporter pour chaque code CCAM, son propre dépassement d'honoraire et non un cumul des dépassements.</l></p>",
    },
    'profile.phone.title': {
        id: 'widgets.profile.phone.title',
        defaultMessage: 'Modifier mon numéro de téléphone',
    },
    'user-infos.last-name.15-characters-title': {
        id: 'widgets.user-infos.last-name.15-characters-title',
        defaultMessage: 'Nom de famille (15 premiers caractères max.)',
    },
    'user-infos.phone-number.title': {
        id: 'widgets.user-infos.phone-number.title',
        defaultMessage: 'Numéro de téléphone',
    },
    'bank-details.contributions.checkbox-confirmation.title': {
        id: 'widgets.bank-details.contributions.checkbox-confirmation.title',
        defaultMessage: 'Modification des coordonnées bancaires de cotisations',
    },
    'bank-details.contributions.checkbox-confirmation.description': {
        id: 'widgets.bank-details.contributions.checkbox-confirmation.description',
        defaultMessage:
            'Je confirme que ce changement concerne uniquement le RIB de cotisations.',
    },
    'bank-details.refunds.checkbox-confirmation.mercer-description': {
        id: 'widgets.bank-details.refunds.checkbox-confirmation.mercer-description',
        defaultMessage:
            "J'autorise ma complémentaire santé à verser les remboursements des frais de santé sur ce nouveau compte bancaire et je confirme que ce changement concerne uniquement le RIB de remboursement.",
    },
    'bank-details.refunds.mercer-description': {
        id: 'forms.bank-details.refunds.mercer-description',
        defaultMessage:
            'Cette modification concerne uniquement le RIB sur lequel vous recevez vos remboursements. Si vous souhaitez modifier vos coordonnées bancaires utilisées lors de vos prélèvements, nous vous invitons à modifier le RIB de cotisations.',
    },
    'attachments.title': {
        id: 'forms.attachments.title',
        defaultMessage: 'Pièce(s) jointe(s)',
    },
    'documents.upload.title': {
        id: 'forms.documents.upload.title',
        defaultMessage: 'Joindre un document',
    },
    'documents.upload.description': {
        id: 'forms.documents.upload.description',
        defaultMessage:
            'Vous pouvez joindre tout document utile à votre consultation à distance : compte rendu médical, analyses biologiques…',
    },
    'address.autocomplete-placeholder': {
        id: 'widgets.address.autocomplete-placeholder',
        defaultMessage: 'Veuillez saisir le début de votre adresse',
    },
    'address.home-care-description': {
        id: 'widgets.address.home-care-description',
        defaultMessage: 'Lieu où les soins "à domicile" seront réalisés',
    },
    'address.street-number.title': {
        id: 'widgets.address.street-number.title',
        defaultMessage: 'Numéro de voie',
    },
    'address.address.street-name.title': {
        id: 'widgets.address.address.street-name.title',
        defaultMessage: 'Type et nom de voie',
    },
    'address.postal-code-country.title': {
        id: 'widgets.address.postal-code-country.title',
        defaultMessage: 'Code postal / Ville',
    },
    'address.street-number-and-name.title': {
        id: 'address.street-number-and-name.title',
        defaultMessage: 'Numéro et nom de voie',
    },
    'phone-number.country-title': {
        id: 'widgets.phone-number.country-title',
        defaultMessage: 'Pays',
    },
    'home-care.frequency.title': {
        id: 'widgets.home-care.frequency.title',
        defaultMessage: 'Le professionnel doit passer :',
    },
    'home-care.frequency.once': {
        id: 'widgets.home-care.frequency.once',
        defaultMessage: 'Une seule fois',
    },
    'home-care.frequency.recurrentCare': {
        id: 'widgets.home-care.frequency.recurrentCare',
        defaultMessage: 'Soin récurrent',
    },
    'home-care.frequency.number-of-times': {
        id: 'widgets.home-care.frequency.number-of-times',
        defaultMessage: 'Nombre de fois',
    },
    'home-care.frequency.by': {
        id: 'widgets.home-care.frequency.by',
        defaultMessage: 'Par',
    },
    'home-care.needed-care.title': {
        id: 'widgets.home-care.needed-care.title',
        defaultMessage: 'Précisez les détails des soins dont vous avez besoin.',
    },
    'home-care.description': {
        id: 'forms.home-care.description',
        defaultMessage:
            'En cas de doute, consultez les indications de votre ordonnance si vous en avez une.',
    },
    'home-care.injection-product.title': {
        id: 'widgets.home-care.injection-product.title',
        defaultMessage: "Type d'injection",
    },
    'home-care.injection-product.anti-coagulant': {
        id: 'widgets.home-care.injection-product.anti-coagulant',
        defaultMessage: 'Anticoagulant (+ contrôle des plaquettes)',
    },
    'home-care.injection-product.vaccine': {
        id: 'widgets.home-care.injection-product.vaccine',
        defaultMessage: 'Vaccin',
    },
    'home-care.cares-to-select.title': {
        id: 'widgets.home-care.cares-to-select.title',
        defaultMessage: 'Sélectionnez un ou plusieurs soins',
    },
    'home-care.injection-product.anti-inflammatory': {
        id: 'widgets.home-care.injection-product.anti-inflammatory',
        defaultMessage: 'Anti-inflammatoire',
    },
    'home-care.injection-product.antibiotic': {
        id: 'widgets.home-care.injection-product.antibiotic',
        defaultMessage: 'Antibiotique',
    },
    'home-care.injection-product.vitamine': {
        id: 'widgets.home-care.injection-product.vitamine',
        defaultMessage: 'Vitamine',
    },
    'home-care.injection-product.other-injection': {
        id: 'widgets.home-care.injection-product.other-injection',
        defaultMessage: 'Autre',
    },
    'home-care.other-details.title': {
        id: 'widgets.home-care.other-details.title',
        defaultMessage: 'Précisez : ',
    },
    'home-care.administration-type.title': {
        id: 'widgets.home-care.administration-type.title',
        defaultMessage: 'Mode d’administration',
    },
    'home-care.administration-type.subcutaneous': {
        id: 'widgets.home-care.administration-type.subcutaneous',
        defaultMessage: 'Sous-cutanée',
    },
    'home-care.administration-type.intramuscular': {
        id: 'widgets.home-care.administration-type.intramuscular',
        defaultMessage: 'Intramusculaire',
    },
    'home-care.administration-type.intravenous': {
        id: 'widgets.home-care.administration-type.intravenous',
        defaultMessage: 'Intraveineuse',
    },
    'home-care.administration-type.dont-know': {
        id: 'widgets.home-care.administration-type.dont-know',
        defaultMessage: 'Je ne sais pas',
    },
    'home-care.stoma.title': {
        id: 'widgets.home-care.stoma.title',
        defaultMessage: 'Sélectionnez un type de sonde',
    },
    'home-care.stoma.urinary-tube': {
        id: 'widgets.home-care.stoma.urinary-tube',
        defaultMessage: 'Sonde urinaire',
    },
    'home-care.stoma.nasogastric-tube': {
        id: 'widgets.home-care.stoma.nasogastric-tube',
        defaultMessage: 'Sonde nasogastrique (SNG)',
    },
    'home-care.stoma.urinary-stoma': {
        id: 'widgets.home-care.stoma.urinary-stoma',
        defaultMessage: 'Stomie urinaire',
    },
    'home-care.stoma.digestive-stoma': {
        id: 'widgets.home-care.stoma.digestive-stoma',
        defaultMessage: 'Stomie digestive',
    },
    'home-care.urinary-tube-care-type.title': {
        id: 'widgets.home-care.urinary-tube-care-type.title',
        defaultMessage: 'Sélectionnez un type de soin',
    },
    'home-care.nasogastric-tube-care-type.title': {
        id: 'widgets.home-care.nasogastric-tube-care-type.title',
        defaultMessage: 'Sélectionnez un type de soin',
    },
    'home-care.urinary-tube-care-type.tube-installation': {
        id: 'widgets.home-care.urinary-tube-care-type.tube-installation',
        defaultMessage: 'Pose de sonde',
    },
    'home-care.urinary-tube-care-type.tube-change': {
        id: 'widgets.home-care.urinary-tube-care-type.tube-change',
        defaultMessage: 'Changement de sonde à demeure',
    },
    'home-care.urinary-tube-care-type.tube-removal': {
        id: 'widgets.home-care.urinary-tube-care-type.tube-removal',
        defaultMessage: 'Retrait de sonde',
    },
    'home-care.nasogastric-tube-care-type.enteral-feeding': {
        id: 'widgets.home-care.urinary-tube-care-type.enteral-feeding',
        defaultMessage: 'Alimentation entrérale',
    },
    'home-care.perfusion-product.title': {
        id: 'widgets.home-care.perfusion-product.title',
        defaultMessage: 'Sélectionnez la perfusion dont vous avez besoin',
    },
    'home-care.perfusion-product.hydration': {
        id: 'widgets.home-care.perfusion-product.hydration',
        defaultMessage: 'Hydratation',
    },
    'home-care.perfusion-product.antibiotic': {
        id: 'widgets.home-care.perfusion-product.antibiotic',
        defaultMessage: 'Antibiotique',
    },
    'home-care.perfusion-product.anti-inflammatory': {
        id: 'widgets.home-care.perfusion-product.anti-inflammatory',
        defaultMessage: 'Anti-inflammatoire',
    },
    'home-care.perfusion-product.glucose': {
        id: 'widgets.home-care.perfusion-product.glucose',
        defaultMessage: 'Glucose',
    },
    'home-care.perfusion-product.chemotherapy': {
        id: 'widgets.home-care.perfusion-product.chemotherapy',
        defaultMessage: 'Chimiothérapie',
    },
    'home-care.perfusion-type.title': {
        id: 'widgets.home-care.perfusion-type.title',
        defaultMessage: 'Type de perfusion',
    },
    'home-care.perfusion-type.subcutaneous': {
        id: 'widgets.home-care.perfusion-type.subcutaneous',
        defaultMessage: 'Sous-cutanée',
    },
    'home-care.perfusion-type.intravenous': {
        id: 'widgets.home-care.perfusion-type.intravenous',
        defaultMessage: 'Intraveineuse',
    },
    'home-care.perfusion-type.catheter': {
        id: 'widgets.home-care.perfusion-type.catheter',
        defaultMessage: 'Sur cathéter',
    },
    'home-care.perfusion-type.implantable-chamber': {
        id: 'widgets.home-care.perfusion-type.implantable-chamber',
        defaultMessage: 'Sur chambre implantable',
    },
    'home-care.test-type.title': {
        id: 'widgets.home-care.test-type.title',
        defaultMessage:
            'Sélectionnez le type de dépistage dont vous avez besoin',
    },
    'home-care.test-type.pcr-test': {
        id: 'widgets.home-care.test-type.pcr-test',
        defaultMessage: 'Test par PCR',
    },
    'home-care.test-type.serology-test': {
        id: 'widgets.home-care.test-type.serology-test',
        defaultMessage: 'Test par sérologie',
    },
    'home-care.test-type.antigenic-test': {
        id: 'widgets.home-care.test-type.antigenic-test',
        defaultMessage: 'Test antigénique',
    },
    'home-care.test-reason.title': {
        id: 'widgets.home-care.test-reason.title',
        defaultMessage:
            'Pour quelle raison avez-vous besoin de faire ce test ?',
    },
    'home-care.test-reason.symptoms': {
        id: 'widgets.home-care.test-reason.symptoms',
        defaultMessage: 'Symptomatique',
    },
    'home-care.test-reason.indirect-tested': {
        id: 'widgets.home-care.test-reason.indirect-tested',
        defaultMessage: 'Cas contact positif',
    },
    'home-care.test-reason.indirect-symptoms': {
        id: 'widgets.home-care.test-reason.indirect-symptoms',
        defaultMessage: 'Cas contact symptomatique',
    },
    'home-care.test-reason.other': {
        id: 'widgets.home-care.test-reason.other',
        defaultMessage: 'Autre',
    },
    'home-care.test-other.title': {
        id: 'widgets.home-care.test-other.title',
        defaultMessage: 'Précisez la nature de votre soin',
    },
    'home-care.chemotherapy-type.title': {
        id: 'widgets.home-care.chemotherapy-type.title',
        defaultMessage: 'Sélectionnez le soin dont vous avez besoin',
    },
    'home-care.chemotherapy-type.connection': {
        id: 'widgets.home-care.chemotherapy-type.connection',
        defaultMessage: 'Perfusion',
    },
    'home-care.chemotherapy-type.disconnection': {
        id: 'widgets.home-care.chemotherapy-type.disconnection',
        defaultMessage: 'Débranchement',
    },
    'home-care.eye-drops-instillation-reason.title': {
        id: 'widgets.home-care.eye-drops-instillation-reason.title',
        defaultMessage: 'Sélectionnez un ou plusieurs soins',
    },
    'home-care.eye-drops-instillation-reason.instillation': {
        id: 'widgets.home-care.eye-drops-instillation-reason.instillation',
        defaultMessage: 'Instillation de collyre',
    },
    'home-care.eye-drops-instillation-reason.ocular-bandage': {
        id: 'widgets.home-care.eye-drops-instillation-reason.ocular-bandage',
        defaultMessage: 'Pansement oculaire',
    },
    'home-care.bandage-cause.abscess': {
        id: 'widgets.home-care.bandage-cause.abscess',
        defaultMessage: 'Kyste / abcès',
    },
    'home-care.bandage-cause.catheter-wound': {
        id: 'widgets.home-care.bandage-cause.catheter-wound',
        defaultMessage: 'Cathéter / PICC line',
    },
    'home-care.bandage-cause.other-wound': {
        id: 'widgets.home-care.bandage-cause.other-wound',
        defaultMessage: 'Autre',
    },
    'home-care.bandage-cause.wound-custom.title': {
        id: 'widgets.home-care.bandage-cause.wound-custom.title',
        defaultMessage: 'Précisez le type de plaie',
    },
    'answer.dont-know': {
        id: 'widgets.answer.dont-know',
        defaultMessage: 'Je ne sais pas',
    },
    'home-care.bandage-wick.title': {
        id: 'widgets.home-care.bandage.wick.title',
        defaultMessage: 'Pansement avec mèche(s) ?',
    },
    'api.common.answer.yes': {
        id: 'widgets.api.common.answer.yes',
        defaultMessage: 'oui',
    },
    'api.common.answer.no': {
        id: 'widgets.api.common.answer.no',
        defaultMessage: 'non',
    },
    'home-care.bandage-cause.title': {
        id: 'widgets.home-care.bandage.cause.title',
        defaultMessage: 'Type de plaie',
    },
    'home-care.bandage-cause.surgical-wound': {
        id: 'widgets.home-care.bandage.cause.surgical-wound',
        defaultMessage: 'Post-opératoire / chirurgie',
    },
    'home-care.bandage-cause.burn': {
        id: 'widgets.home-care.bandage.cause.burn',
        defaultMessage: 'Brûlure',
    },
    'home-care.bandage-cause.diabetic-wound': {
        id: 'widgets.home-care.bandage.cause.diabetic-wound',
        defaultMessage: 'Plaie diabétique',
    },
    'home-care.bandage-cause.bedsore': {
        id: 'widgets.home-care.bandage.cause.bedsore',
        defaultMessage: 'Escarre',
    },
    'home-care.bandage-cause.ulcer': {
        id: 'widgets.home-care.bandage.cause.ulcer',
        defaultMessage: 'Ulcère',
    },
    'home-care.drugs-distribution-monitoring-reason.title': {
        id: 'widgets.home-care.drugs-distribution-monitoring-reason.title',
        defaultMessage: 'Sélectionnez un ou plusieurs soins',
    },
    'home-care.drugs-distribution-monitoring-reason.distribution': {
        id: 'widgets.home-care.drugs-distribution-monitoring-reason.distribution',
        defaultMessage: 'Distribution de médicaments',
    },
    'home-care.drugs-distribution-monitoring-reason.preparation': {
        id: 'widgets.home-care.drugs-distribution-monitoring-reason.preparation',
        defaultMessage: 'Préparation du pilulier',
    },
    'home-care.person-help.washout-help.title': {
        id: 'widgets.home-care.person-help.washout-help.title',
        defaultMessage: 'Type de toilette',
    },
    'home-care.person-help.washout-help.washout': {
        id: 'widgets.home-care.person-help.washout-help.washout',
        defaultMessage: 'Aide à la toilette',
    },
    'home-care.person-help.washout-help.shower': {
        id: 'widgets.home-care.person-help.washout-help.shower',
        defaultMessage: 'Douche',
    },
    'home-care.person-help.washout-help.armchair-washout': {
        id: 'widgets.home-care.person-help.washout-help.armchair-washout',
        defaultMessage: 'Toilette au fauteuil',
    },
    'home-care.person-help.washout-help.bed-washout': {
        id: 'widgets.home-care.person-help.washout-help.bed-washout',
        defaultMessage: 'Toilette au lit (patient non valide)',
    },
    'home-care.person-help.patient-validity.title': {
        id: 'widgets.home-care.person-help.patient-validity.title',
        defaultMessage: 'Validité du patient',
    },
    'home-care.person-help.patient-validity.independant': {
        id: 'widgets.home-care.person-help.patient-validity.independant',
        defaultMessage: 'Valide et autonome',
    },
    'home-care.person-help.patient-validity.help-walking': {
        id: 'widgets.home-care.person-help.patient-validity.help-walking',
        defaultMessage: 'Valide, marche avec de l’aide',
    },
    'home-care.person-help.patient-validity.zimmer-walking': {
        id: 'widgets.home-care.person-help.patient-validity.zimmer-walking',
        defaultMessage: 'Semi-valide, marche avec un déambulateur',
    },
    'home-care.person-help.patient-validity.armchair-walking': {
        id: 'widgets.home-care.person-help.patient-validity.armchair-walking',
        defaultMessage: 'Semi-valide, en fauteuil roulant',
    },
    'home-care.person-help.patient-validity.disabled': {
        id: 'widgets.home-care.person-help.patient-validity.disabled',
        defaultMessage: 'Non valide',
    },
    'home-care.person-help.selected-cares.title': {
        id: 'widgets.home-care.person-help.selected-cares.title',
        defaultMessage: "De quel type de soin s'agit-t-il ?",
    },
    'home-care.person-help.selected-cares.dressing-help': {
        id: 'widgets.home-care.person-help.selected-cares.dressing-help',
        defaultMessage: 'Habillage',
    },
    'home-care.person-help.selected-cares.protection-diaper': {
        id: 'widgets.home-care.person-help.selected-cares.protection-diaper',
        defaultMessage: 'Change (couche / protection)',
    },
    'home-care.person-help.selected-cares.armchair-transfer': {
        id: 'widgets.home-care.person-help.selected-cares.armchair-transfer',
        defaultMessage: 'Transfert au fauteuil',
    },
    'home-care.person-help.selected-cares.compression-stockings': {
        id: 'widgets.home-care.person-help.selected-cares.compression-stockings',
        defaultMessage: 'Mise des bas de contention',
    },
    'social-security.organization-code.title': {
        id: 'forms.social-security.organization-code.title',
        defaultMessage: 'Modifier ma caisse de Sécurité sociale',
    },
    'social-security.organization-code.description': {
        id: 'forms.social-security.organization-code.description',
        defaultMessage:
            'Vous pouvez modifier votre code d’organisme de rattachement ou celui de vos bénéficiaires.',
    },
    'organization-code.title': {
        id: 'widgets.organization-code.title',
        defaultMessage: 'Code organisme',
    },
    'organization-code.description': {
        id: 'widgets.organization-code.description',
        defaultMessage:
            'Votre code organisme est composé de 9 chiffres. Vous pouvez le retrouver sur votre attestation de sécurité sociale.',
    },
    'organization-code.placeholder': {
        id: 'widgets.organization-code.placeholder',
        defaultMessage: '00 000 0000',
    },
    'organization-code.help': {
        id: 'widgets.organization-code.help',
        defaultMessage:
            'Pour obtenir votre attestation de droits, connectez-vous sur votre compte correspondant à votre régime obligatoire (ex : Ameli, MSA, etc.).',
    },
    'beneficiary.title': {
        id: 'widgets.beneficiary.title',
        defaultMessage: 'Bénéficiaire',
    },
    'social-security.local-regime.description': {
        id: 'widgets.social-security.local-regime.description',
        defaultMessage: 'Régime Alsace-Moselle',
    },
    workflowPrescriptionHasPrescriptionTitle: {
        id: 'widgets.workflow-home-care.prescription.has-prescription.title',
        defaultMessage: 'Avez-vous une ordonnance ?',
    },
    workflowPrescriptionHasPrescriptionYesAtHomeLabel: {
        id: 'widgets.workflow-home-care.prescription.has-prescription.yes-at-home.label',
        defaultMessage: 'Oui, avec mention "À domicile"',
    },
    workflowPrescriptionHasPrescriptionYesLabel: {
        id: 'widgets.workflow-home-care.prescription.has-prescription.yes.label',
        defaultMessage: 'Oui, sans mention',
    },
    workflowPrescriptionHasPrescriptionNoLabel: {
        id: 'widgets.workflow-home-care.prescription.has-prescription.no.label',
        defaultMessage: 'Non',
    },
    'workflow-home-care.prescription.upload.title': {
        id: 'widgets.workflow-home-care.prescription.upload.title',
        defaultMessage: 'Veuillez ajouter votre ordonnance',
    },
    'delete-contract.title': {
        id: 'forms.delete-contract.title',
        defaultMessage: 'Résilier mon contrat',
    },
    'delete-contract.description': {
        id: 'forms.delete-contract.description',
        defaultMessage:
            'Les contrats souscrits par votre employeur ne peuvent pas être résiliés en ligne. La résiliation sera effective pour tous les bénéficiaires du contrat.',
    },

    'contract.name.placeholder': {
        id: 'widgets.contract.name.placeholder',
        defaultMessage: 'Veuillez sélectionner un contrat',
    },
    'contract.other.title': {
        id: 'widgets.contract.other.title',
        defaultMessage: 'Autre contrat',
    },
    'contract.other.description': {
        id: 'widgets.contract.other.description',
        defaultMessage:
            "Si vous avez sélectionné 'autre contrat', veuillez préciser le nom du contrat concerné : maintien retraité, veuf/veuve, licencié, loi EVIN, etc…",
    },

    'contract.appendices.description': {
        id: 'widgets.contract.appendices.description',
        defaultMessage: `<p><b>Conditions de résiliation</b></p>
                        <p>Veuillez prendre connaissance des conditions de résiliation qui s'appliquent à votre contrat :</p>
                        <p><b>Contrats frais de santé individuels (Solution 25/40/50/Excellence)</b></p>
                            <l>- À chaque échéance annuelle, au 31/12 de chaque année en notifiant votre résiliation avec un préavis minimum de deux mois. La date d\'expédition de votre notification de résiliation fait foi pour le respect du délai.</l>
                            <br></br>
                            <p>- À tout moment sans frais ni pénalités,</p>
                                  <tab>si vous adhérez à un contrat collectif obligatoire d'entreprise sous réserve de nous en apporter la preuve ( avec une attestation nominative de votre employeur précisant le caractère obligatoire de votre affiliation ). La résiliation sera effective au dernier jour du mois au cours duquel nous en aurons eu connaissance</tab>
                                  <tab>à l'expiration d'un délai d'un an à compter de la première souscription. Dans ce cas la résiliation prend effet un mois après la réception de votre notification de résiliation.</tab>
                        <p><b>Surcomplémentaire Optimal</b></p>
                            <p>- À tout moment sans frais ni pénalités, à l'expiration d'un délai d'un an à compter de la première souscription. Dans ce cas la résiliation prend effet un mois après la réception de votre notification de résiliation.</p>
                            <p>- À la date à laquelle vous ne bénéficiez plus d'un contrat socle complémentaire intermédié et géré par MERCER (France).</p>
                            <p>- À chaque échéance annuelle, au 31/12 de chaque de chaque année en notifiant votre résiliation avec un préavis minimum de deux mois. La date d'expédition de votre notification de résiliation fait foi pour le respect du délais.</p>
                        <p><b>Surcomplémentaire Renfort</b></p>
                            <p>- À tout moment sans frais ni pénalités, à l'expiration d'un délai d'un an à compter de la première souscription. Dans ce cas la résiliation prend effet un mois après la réception de votre notification de résiliation.</p>
                            <p>- À chaque échéance annuelle, au 31/12 de chaque de chaque année en notifiant votre résiliation avec un préavis minimum de deux mois. La date d'expédition de votre notification de résiliation fait foi pour le respect du délais</p>
                        <p><b>Garantie accident de la vie – Protection familiale intégrale (PFI) / GAV  ou TEMPO DECES</b></p>
                            <p>- A la date anniversaire du contrat, en nous adressant une notification au moins deux mois avant cette date.</p>
                        <p><b>Solution TNS, Groupama TNS (Gold Life, Vista Conseil, Revenu et Patrim, A&S Assurance), Premaxis (Prema 2021 New)</b></p>
                            <p>- Résiliation à l'échéance : résiliation du contrat à la date d'échéance du contrat, qui est le 31 décembre de chaque année. Ceci moyennant un préavis de deux mois.</p>
                            <p>- Résiliation Infra-annuelle : après un an de présence sur le contrat, possibilité de résilier le contrat à tout moment moyennant un mois de préavis.</p>
                            <p>- Adhésion à un contrat collectif obligatoire : si adhésion à un contrat collectif à adhésion obligatoire via l'employeur, possibilité de résilier sans délai.</p>
                            `,
    },

    'comments.title': {
        id: 'widgets.comments.title',
        defaultMessage: 'Commentaires',
    },
    'contract-deletion.reasons.title': {
        id: 'widgets.contract-deletion.reasons.title',
        defaultMessage: 'Motif(s) de la résiliation',
    },
    'contract-deletion.specify.title': {
        id: 'widgets.contract-deletion.specify.title',
        defaultMessage: 'Veuillez préciser le motif',
    },
    'date.deletion-date.title': {
        id: 'widgets.date.deletion-date.title',
        defaultMessage: 'Date de résiliation souhaitée',
    },
    'date.deletion-date.description': {
        id: 'widgets.date.deletion-date.description',
        defaultMessage:
            'Cette date pourra être modifiée afin de respecter les conditions générales du contrat.',
    },
    'attachments.deletion-certificates.title': {
        id: 'widgets.attachments.deletion-certificates.title',
        defaultMessage: 'Justificatifs',
    },
    'attachments.deletion-certificates.description': {
        id: 'widgets.attachments.deletion-certificates.description',
        defaultMessage:
            'Veuillez joindre les justificatifs correspondant aux motifs sélectionnés.',
    },
    'contract-deletion.confirmation.description': {
        id: 'widgets.contract-deletion.confirmation.description',
        defaultMessage: 'Je confirme vouloir résilier mon contrat',
    },
    'contract-deletion.reasons.too-expensive': {
        id: 'widgets.contract-deletion.reasons.too-expensive',
        defaultMessage: 'Le tarif est trop cher',
    },
    'contract-deletion.reasons.guarantees-mismatch': {
        id: 'widgets.contract-deletion.reasons.guarantees-mismatch',
        defaultMessage: 'Les garanties ne correspondent plus à mon besoin',
    },
    'contract-deletion.reasons.other-insurer': {
        id: 'widgets.contract-deletion.reasons.other-insurer',
        defaultMessage:
            "J'ai adhéré à une mutuelle obligatoire (Joindre justificatif : certificat adhésion, certificat appartenance)",
    },
    'contract-deletion.reasons.death': {
        id: 'widgets.contract-deletion.reasons.death',
        defaultMessage:
            "Décès du bénéficiaire (Veuillez joindre obligatoirement l'acte de décès pour que votre demande soit prise en compte)",
    },
    'contract-deletion.reasons.other': {
        id: 'widgets.contract-deletion.reasons.other',
        defaultMessage: 'Autre',
    },
    'contract-deletion.contracts.other': {
        id: 'widgets.contract-deletion.contracts.other',
        defaultMessage:
            'AUTRES (maintien retraité, veuf/veuve, licencié, loi EVIN, etc.)',
    },
    'home-care.blood-sample.reason.title': {
        id: 'widgets.home-care.blood-sample.reason.title',
        defaultMessage: 'Raison de la prise de sang',
    },
    'home-care.blood-sample.empty-stomach.title': {
        id: 'widgets.home-care.blood-sample.empty-stomach.title',
        defaultMessage: 'La prise de sang doit se faire à jeun',
    },
    'home-care.covid-19.date-of-diagnostic.title': {
        id: 'widgets.home-care.covid-19.date-of-diagnostic.title',
        defaultMessage: 'Date de diagnostic positif (J0)',
    },
    'home-care.covid-19.additional-informations.title': {
        id: 'widgets.home-care.covid-19.additional-informations.title',
        defaultMessage: "Signes d'alerte à rechercher + infos complémentaires",
    },
    'home-care.covid-19.need-oxygen-therapy.title': {
        id: 'widgets.home-care.covid-19.need-oxygen-therapy.title',
        defaultMessage: "Besoin d'oxygénothérapie à domicile",
    },
    'home-care.covid-19.medical-remote-monitoring.title': {
        id: 'widgets.home-care.covid-19.medical-remote-monitoring.title',
        defaultMessage: 'Télésuivi possible',
    },
    'home-care.covid-19.covidom.title': {
        id: 'widgets.home-care.covid-19.covidom.title',
        defaultMessage: 'Suivi covidom',
    },
    'home-care.covid-19.contact.title': {
        id: 'widgets.home-care.covid-19.contact.title',
        defaultMessage: "Contact en cas d'aggravation",
    },
    'home-care.covid-19.contact.fifteen': {
        id: 'widgets.home-care.covid-19.contact.fifteen',
        defaultMessage: '15',
    },
    'home-care.covid-19.contact.doctor': {
        id: 'widgets.home-care.covid-19.contact.doctor',
        defaultMessage: 'Médecin traitant',
    },
    'home-care.covid-19.contact.other': {
        id: 'widgets.home-care.covid-19.contact.other',
        defaultMessage: 'Autre',
    },
    'home-care.ablation-suture-staples.reason.title': {
        id: 'widgets.home-care.ablation-suture-staples.reason.title',
        defaultMessage: 'Sélectionnez un ou plusieurs soins',
    },
    'home-care.ablation-suture-staples.reason.suture': {
        id: 'widgets.home-care.ablation-suture-staples.reason.suture',
        defaultMessage: 'Points de suture',
    },
    'home-care.ablation-suture-staples.reason.staples': {
        id: 'widgets.home-care.ablation-suture-staples.reason.staples',
        defaultMessage: 'Agrafes',
    },
    'home-care.sutures-stables-number.title': {
        id: 'widgets.home-care.sutures-stables-number.title',
        defaultMessage: 'Y a-t-il plus de 10 points ou agrafes à retirer ?',
    },
    'home-care.glucose-diabetes-monitoring.selected-cares.title': {
        id: 'widgets.home-care.glucose-diabetes-monitoring.selected-cares.title',
        defaultMessage: 'Sélectionnez un ou plusieurs soins',
    },
    'home-care.glucose-diabetes-monitoring.selected-cares.blood-glucose-control':
        {
            id: 'widgets.home-care.glucose-diabetes-monitoring.selected-cares.blood-glucose-control',
            defaultMessage: 'Contrôle glycémie',
        },
    'home-care.glucose-diabetes-monitoring.selected-cares.insulin-injection': {
        id: 'widgets.home-care.glucose-diabetes-monitoring.selected-cares.insulin-injection',
        defaultMessage: "Injection d'insuline",
    },
    'home-care.unsupported-care.description.title': {
        id: 'widgets.home-care.unsupported-care.description.title',
        defaultMessage:
            'Précisez les détails des soins dont vous avez besoin (100 caractères maximum)',
    },
    'home-care.administration-time.title': {
        id: 'widgets.home-care.administration-time.title',
        defaultMessage: 'Temps d’administration',
    },
    'medical-data.title': {
        id: 'forms.medical-data.title',
        defaultMessage: 'Mettre à jour les informations médicales',
    },
    'medical-data.description': {
        id: 'forms.medical-data.description',
        defaultMessage:
            'Renseignez les données médicales pour que les professionnels de santé en sache davantage lors du rendez-vous.',
    },
    'medical-data.height.title': {
        id: 'widgets.medical-data.height.title',
        defaultMessage: 'Taille',
    },
    'medical-data.height.description': {
        id: 'widgets.medical-data.height.description',
        defaultMessage: 'Veuillez indiquer la taille en cm.',
    },
    'medical-data.weight.title': {
        id: 'widgets.medical-data.weight.title',
        defaultMessage: 'Poids',
    },
    'medical-data.weight.description': {
        id: 'widgets.medical-data.weight.description',
        defaultMessage: 'Veuillez indiquer le poids en kg.',
    },
    'medical-data.known-allergies.title': {
        id: 'widgets.medical-data.known-allergies.title',
        defaultMessage: 'Allergie(s) connue(s) ?',
    },
    'answer.no': {
        id: 'widgets.answer.no',
        defaultMessage: 'Non',
    },
    'answer.yes': {
        id: 'widgets.answer.yes',
        defaultMessage: 'Oui',
    },
    'answer.specify': {
        id: 'widgets.answer.specify',
        defaultMessage: 'Précisez :',
    },
    'medical-data.identified-ongoing-treat.title': {
        id: 'widgets.medical-data.identified-ongoing-treat.title',
        defaultMessage: 'Traitement(s) en cours ?',
    },
    'medical-data.medical-background.title': {
        id: 'widgets.medical-data.medical-background.title',
        defaultMessage: 'Antécédents médicaux',
    },
    'medical-data.surgical-history.title': {
        id: 'widgets.medical-data.surgical-history.title',
        defaultMessage: 'Antécédents chirurgicaux',
    },
    'medical-data.referring-doctor.title': {
        id: 'widgets.medical-data.referring-doctor.title',
        defaultMessage: 'Avez-vous un médecin traitant ?',
    },
    'time.end.title': {
        id: 'widgets.time.end.title',
        defaultMessage: "Jusqu'à",
    },
    'time.start.title': {
        id: 'widgets.time.start.title',
        defaultMessage: 'Disponible à partir de',
    },
    'care-infos.care-location.title': {
        id: 'widgets.care-infos.care-location.title',
        defaultMessage: 'Lieu des soins',
    },
    'care-infos.care-location.PTRES': {
        id: 'widgets.care-infos.care-location.PTRES',
        defaultMessage: 'À domicile',
    },
    'care-infos.care-location.NCCF': {
        id: 'widgets.care-infos.care-location.NCCF',
        defaultMessage: 'Au cabinet',
    },
    'care-infos.care-location.null': {
        id: 'widgets.care-infos.care-location.null',
        defaultMessage: 'Indifférent',
    },
    'date.care-start-date.title': {
        id: 'widgets.date.care-start-date.title',
        defaultMessage: 'Date de début des soins',
    },
    'workflow-home-care.care-location-and-date.start-date-alert': {
        id: 'workflow-home-care.care-location-and-date.start-date-alert',
        defaultMessage:
            'Les soins demandés avec une date de début le jour même sont moins susceptibles d’être acceptés que ceux prévus pour le lendemain de la demande. Cela permet au professionnel de s’organiser pour vous intégrer plus facilement à sa tournée quotidienne.',
    },
    'home-care.covid-vaccination.received-booster.title': {
        id: 'widgets.home-care.covid-vaccination.received-booster.title',
        defaultMessage: 'Est-ce un rappel ?',
    },
    'home-care.covid-vaccination.previous-booster-type.title': {
        id: 'widgets.home-care.covid-vaccination.previous-booster-type.title',
        defaultMessage: 'Quel type de vaccin avez-vous reçu ?',
    },
    'home-care.covid-vaccination.previous-booster-type.astrazeneca': {
        id: 'widgets.home-care.covid-vaccination.previous-booster-type.astrazeneca',
        defaultMessage: 'AstraZeneca',
    },
    'home-care.covid-vaccination.previous-booster-type.pfizer': {
        id: 'widgets.home-care.covid-vaccination.previous-booster-type.pfizer',
        defaultMessage: 'Pfizer',
    },
    'home-care.covid-vaccination.previous-booster-type.moderna': {
        id: 'widgets.home-care.covid-vaccination.previous-booster-type.moderna',
        defaultMessage: 'Moderna',
    },
    'home-care.covid-vaccination.previous-booster-type.janssen': {
        id: 'widgets.home-care.covid-vaccination.previous-booster-type.janssen',
        defaultMessage: 'Janssen',
    },
    'home-care.covid-vaccination.other.title': {
        id: 'widgets.home-care.covid-vaccination.other.title',
        defaultMessage:
            "Avez-vous d'autres précisions à communiquer à l'infirmier(e) qui vous prendra en charge ?",
    },
    'home-care.covid-vaccination.major-certif.description': {
        id: 'widgets.home-care.covid-vaccination.major-certif.description',
        defaultMessage: 'Je certifie être majeur',
    },
    'home-care.covid-vaccination.covid-certif.description': {
        id: 'widgets.home-care.covid-vaccination.covid-certif.description',
        defaultMessage:
            'Je certifie ne pas avoir été testé positif à la Covid-19 depuis moins de 3 mois',
    },
    'home-care.covid-vaccination.info-trouble.description': {
        id: 'widgets.home-care.covid-vaccination.info-trouble.description',
        defaultMessage:
            "Je certifie ne pas avoir de troubles de l'hémostase / de la coagulation",
    },
    'home-care.covid-vaccination.info-certif.description': {
        id: 'widgets.home-care.covid-vaccination.info-certif.description',
        defaultMessage:
            'Je certifie que les informations renseignées sont exactes',
    },
    'care-infos.care-duration.title': {
        id: 'widgets.care-infos.care-duration.title',
        defaultMessage: 'Durée des soins',
    },
    'care-infos.care-duration.custom-title': {
        id: 'widgets.care-infos.care-duration.custom-title',
        defaultMessage: 'Personnalisez la durée de vos soins (en jours)',
    },
    'time-availability.title': {
        id: 'widgets.time-availability.title',
        defaultMessage: 'Disponibilités horaires',
    },
    'time-availability.description': {
        id: 'widgets.time-availability.description',
        defaultMessage:
            "<l>Sélectionnez un créneau d'au moins 2 heures.</l>L'heure de passage précise sera à définir avec le professionnel de santé.",
    },
    'time-availability.start.first-title': {
        id: 'widgets.time-availability.start.first-title',
        defaultMessage: 'Disponible à partir de',
    },
    'time-availability.end.title': {
        id: 'widgets.time-availability.end.title',
        defaultMessage: "Jusqu'à",
    },
    'time-availability.start.second-title': {
        id: 'widgets.time-availability.start.second-title',
        defaultMessage: 'Et à partir de',
    },
    'care-infos.professional-gender.title': {
        id: 'widgets.care-infos.professional-gender.title',
        defaultMessage: "Sexe de l'intervenant",
    },
    'care-infos.care-duration.one-day': {
        id: 'widgets.care-infos.care-duration.one-day',
        defaultMessage: '1 jour',
    },
    'care-infos.care-duration.seven-days': {
        id: 'widgets.care-infos.care-duration.seven-days',
        defaultMessage: '7 jours',
    },
    'care-infos.care-duration.ten-days': {
        id: 'widgets.care-infos.care-duration.ten-days',
        defaultMessage: '10 jours',
    },
    'care-infos.care-duration.fifteen-days': {
        id: 'widgets.care-infos.care-duration.fifteen-days',
        defaultMessage: "15 jours (ou jusqu'à la cicatrisation)",
    },
    'care-infos.care-duration.thirty-days': {
        id: 'widgets.care-infos.care-duration.thirty-days',
        defaultMessage: '30 jours',
    },
    'care-infos.care-duration.long-duration': {
        id: 'widgets.care-infos.care-duration.long-duration',
        defaultMessage: 'Longue durée (60 jours ou plus)',
    },
    'care-infos.care-duration.custom': {
        id: 'widgets.care-infos.care-duration.custom',
        defaultMessage: 'Durée personnalisée (en jours)',
    },
    'care-infos.professional-gender.null': {
        id: 'widgets.professional-gender.title.null',
        defaultMessage: 'Indifférent',
    },
    'care-infos.professional-gender.male': {
        id: 'widgets.professional-gender.title.male',
        defaultMessage: 'Masculin',
    },
    'care-infos.professional-gender.female': {
        id: 'widgets.professional-gender.title.female',
        defaultMessage: 'Féminin',
    },
    'time-availability.placeholder': {
        id: 'widgets.time-availability.placeholder',
        defaultMessage: 'Sélectionnez une heure',
    },
    'complaints.subject.title': {
        id: 'widgets.complaints.subject.title',
        defaultMessage: 'Objet de la réclamation',
    },
    'complaints.subject.placeholder': {
        id: 'widgets.complaints.subject.placeholder',
        defaultMessage: "Sélectionner l'objet de votre réclamation",
    },
    'complaints.subject.health-refunds': {
        id: 'widgets.complaints.subject.health-refunds',
        defaultMessage: 'Remboursements frais de santé',
    },
    'complaints.subject.quote': {
        id: 'widgets.complaints.subject.quote',
        defaultMessage: 'Devis (optique, dentaire, hospitalier)',
    },
    'complaints.subject.advance-of-cost': {
        id: 'widgets.complaints.subject.advance-of-cost',
        defaultMessage: 'Prise en charge (optique, dentaire, hospitalière)',
    },
    'complaints.subject.contract-membership': {
        id: 'widgets.complaints.subject.contract-membership',
        defaultMessage: 'Adhésion au contrat',
    },
    'complaints.subject.contract-termination': {
        id: 'widgets.complaints.subject.contract-termination',
        defaultMessage: 'Résiliation du contrat',
    },
    'complaints.subject.contract-modification': {
        id: 'widgets.complaints.subject.contract-modification',
        defaultMessage: 'Modification du contrat',
    },
    'complaints.subject.contract-extend-coverage': {
        id: 'widgets.complaints.subject.contract-extend-coverage',
        defaultMessage: 'Maintien de contrat (Portabilité, retraité,…..)',
    },
    'complaints.subject.beneficiaries-manage': {
        id: 'widgets.complaints.subject.beneficiaries-manage',
        defaultMessage: 'Gestion des bénéficiaires',
    },
    'complaints.subject.contributions': {
        id: 'widgets.complaints.subject.contributions',
        defaultMessage: 'Cotisations',
    },
    'complaints.subject.noemie-teletransmission': {
        id: 'widgets.complaints.subject.noemie-teletransmission',
        defaultMessage: 'Télétransmission NOEMIE',
    },
    'complaints.subject.tp-card': {
        id: 'widgets.complaints.subject.tp-card',
        defaultMessage: 'Carte de tiers payant',
    },
    'complaints.subject.provident': {
        id: 'widgets.complaints.subject.provident',
        defaultMessage: 'Prévoyance (arrêt de travail, invalidité, décès)',
    },
    'complaints.subject.other': {
        id: 'widgets.complaints.subject.other',
        defaultMessage: 'Autre demande',
    },
    'home-care.physiotherapist.after-surgery-reeducation.description': {
        id: 'widgets.home-care.physiotherapist.after-surgery-reeducation.description',
        defaultMessage: 'Rééducation suite chirurgie',
    },
    'home-care.physiotherapist.related-pathological-examination.description': {
        id: 'widgets.home-care.physiotherapist.related-pathological-examination.description',
        defaultMessage:
            "J'ai des examens en relation avec la pathologie : Scanner, IRM, Radio",
    },
    'home-care.physiotherapist.care-location.title': {
        id: 'widgets.home-care.physiotherapist.care-location.title',
        defaultMessage: 'Localisation du soin',
    },
    'home-care.physiotherapist.care-location.superior': {
        id: 'widgets.home-care.physiotherapist.care-location.superior',
        defaultMessage: 'Membres supérieurs',
    },
    'home-care.physiotherapist.care-location.inferior': {
        id: 'widgets.home-care.physiotherapist.care-location.inferior',
        defaultMessage: 'Membres inférieurs',
    },
    'home-care.physiotherapist.care-location.shoulder': {
        id: 'widgets.home-care.physiotherapist.care-location.shoulder',
        defaultMessage: 'Épaule',
    },
    'home-care.physiotherapist.care-location.elbow': {
        id: 'widgets.home-care.physiotherapist.care-location.elbow',
        defaultMessage: 'Coude',
    },
    'home-care.physiotherapist.care-location.wrist': {
        id: 'widgets.home-care.physiotherapist.care-location.wrist',
        defaultMessage: 'Poignet',
    },
    'home-care.physiotherapist.care-location.finger': {
        id: 'widgets.home-care.physiotherapist.care-location.finger',
        defaultMessage: 'Doigt',
    },
    'home-care.physiotherapist.care-location.hip': {
        id: 'widgets.home-care.physiotherapist.care-location.hip',
        defaultMessage: 'Hanche',
    },
    'home-care.physiotherapist.care-location.knee': {
        id: 'widgets.home-care.physiotherapist.care-location.knee',
        defaultMessage: 'Genou',
    },
    'home-care.physiotherapist.care-location.ankle': {
        id: 'widgets.home-care.physiotherapist.care-location.ankle',
        defaultMessage: 'Cheville',
    },
    'home-care.physiotherapist.care-location.foot': {
        id: 'widgets.home-care.physiotherapist.care-location.foot',
        defaultMessage: 'Pied',
    },
    'home-care.physiotherapist.care-location.cervical': {
        id: 'widgets.home-care.physiotherapist.care-location.cervical',
        defaultMessage: 'Cervicales',
    },
    'home-care.physiotherapist.care-location.spine': {
        id: 'widgets.home-care.physiotherapist.care-location.spine',
        defaultMessage: 'Rachis',
    },
    'home-care.physiotherapist.care-location.lumbar': {
        id: 'widgets.home-care.physiotherapist.care-location.lumbar',
        defaultMessage: 'Lombaires',
    },
    'home-care.physiotherapist.care-location.back': {
        id: 'widgets.home-care.physiotherapist.care-location.back',
        defaultMessage: 'Dos',
    },
    'home-care.body-part-mobilization-recuperation.patient-validity.independant':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-validity.independant',
            defaultMessage: 'Valide et autonome',
        },
    'home-care.body-part-mobilization-recuperation.patient-validity.help-walking':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-validity.help-walking',
            defaultMessage: 'Valide, marche avec de l’aide',
        },
    'home-care.body-part-mobilization-recuperation.patient-validity.zimmer-walking':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-validity.zimmer-walking',
            defaultMessage: 'Semi-valide, marche avec un déambulateur',
        },
    'home-care.body-part-mobilization-recuperation.patient-validity.armchair-walking':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-validity.armchair-walking',
            defaultMessage: 'Semi-valide, en fauteuil roulant',
        },
    'home-care.body-part-mobilization-recuperation.patient-validity.paraplegic':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-validity.paraplegic',
            defaultMessage: 'Patient paraplégique',
        },
    'home-care.body-part-mobilization-recuperation.patient-validity.tetraplegic':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-validity.tetraplegic',
            defaultMessage: 'Patient tétraplégique',
        },
    'home-care.body-part-mobilization-recuperation.patient-pathology.title': {
        id: 'widgets.home-care.body-part-mobilization-recuperation.patient-pathology.title',
        defaultMessage: 'Pathologie du patient',
    },
    'home-care.body-part-mobilization-recuperation.patient-pathology.cva': {
        id: 'widgets.home-care.body-part-mobilization-recuperation.patient-pathology.cva',
        defaultMessage: 'AVC',
    },
    'home-care.body-part-mobilization-recuperation.patient-pathology.multiple-sclerosis':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-pathology.multiple-sclerosis',
            defaultMessage: 'Sclérose en plaques',
        },
    'home-care.body-part-mobilization-recuperation.patient-pathology.parkinson':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.patient-pathology.parkinson',
            defaultMessage: 'Parkinson',
        },
    'home-care.body-part-mobilization-recuperation.patient-pathology.other': {
        id: 'widgets.home-care.body-part-mobilization-recuperation.patient-pathology.other',
        defaultMessage: 'Autre',
    },
    'home-care.body-part-mobilization-recuperation.presence-of-a-close-one.description':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.presence-of-a-close-one.description',
            defaultMessage: "Besoin de la présence d'un proche",
        },
    'home-care.body-part-mobilization-recuperation.medical-bed.description': {
        id: 'widgets.home-care.body-part-mobilization-recuperation.medical-bed.description',
        defaultMessage: "Présence d'un lit médicalisé",
    },
    'home-care.body-part-mobilization-recuperation.under-respiratory-support.description':
        {
            id: 'widgets.home-care.body-part-mobilization-recuperation.under-respiratory-support.description',
            defaultMessage: 'Personne sous assistance respiratoire',
        },
    'home-care.body-part-mobilization-recuperation.prescription.title': {
        id: 'widgets.home-care.body-part-mobilization-recuperation.prescription.title',
        defaultMessage: "Motif de l'ordonnance",
    },
    'home-care.body-part-mobilization-recuperation.patient-validity.title': {
        id: 'widgets.home-care.body-part-mobilization-recuperation.patient-validity.title',
        defaultMessage: 'Validité du patient',
    },
    'home-care.respiratory-physiotherapy.aerosol-prescribed.description': {
        id: 'widgets.home-care.respiratory-physiotherapy.aerosol-prescribed.description',
        defaultMessage: 'Aérosol prescrit',
    },
    'home-care.perineo-sphincteric-reeducation.sonde.title': {
        id: 'widgets.home-care.perineo-sphincteric-reeducation.sonde.title',
        defaultMessage: 'Rééducation souhaitée',
    },
    'home-care.perineo-sphincteric-reeducation.sonde.manual': {
        id: 'widgets.home-care.perineo-sphincteric-reeducation.sonde.manual',
        defaultMessage: 'Manuelle',
    },
    'home-care.perineo-sphincteric-reeducation.sonde.sonde': {
        id: 'widgets.home-care.perineo-sphincteric-reeducation.sonde.sonde',
        defaultMessage: 'Avec sonde',
    },
    'home-care.perineo-sphincteric-reeducation.childbirth-mode.title': {
        id: 'widgets.home-care.perineo-sphincteric-reeducation.childbirth-mode.title',
        defaultMessage: "Mode d'accouchement",
    },
    'home-care.perineo-sphincteric-reeducation.childbirth-mode.natural-delivery':
        {
            id: 'widgets.home-care.perineo-sphincteric-reeducation.childbirth-mode.natural-delivery',
            defaultMessage: 'Par voie basse',
        },
    'home-care.perineo-sphincteric-reeducation.childbirth-mode.cesarean-delivery':
        {
            id: 'widgets.home-care.perineo-sphincteric-reeducation.childbirth-mode.cesarean-delivery',
            defaultMessage: 'Par césarienne',
        },
    'home-care.perineo-sphincteric-reeducation.lesion-after-birth.title': {
        id: 'widgets.home-care.perineo-sphincteric-reeducation.lesion-after-birth.title',
        defaultMessage: "Type de lésion suite à l'accouchement",
    },
    'home-care.perineo-sphincteric-reeducation.lesion-after-birth.lesions-none':
        {
            id: 'widgets.home-care.perineo-sphincteric-reeducation.lesion-after-birth.lesions-none',
            defaultMessage: 'Aucune',
        },
    'home-care.perineo-sphincteric-reeducation.lesion-after-birth.episiotomy': {
        id: 'widgets.home-care.perineo-sphincteric-reeducation.lesion-after-birth.episiotomy',
        defaultMessage: 'Épisiotomie',
    },
    'home-care.perineo-sphincteric-reeducation.lesion-after-birth.tearing': {
        id: 'widgets.home-care.perineo-sphincteric-reeducation.lesion-after-birth.tearing',
        defaultMessage: 'Déchirure',
    },
    'home-care.midwife.first-pregnancy.title': {
        id: 'widgets.home-care.midwife.first-pregnancy.title',
        defaultMessage: 'Première grossesse',
    },
    'home-care.midwife.pregnancy-type.title': {
        id: 'widgets.home-care.midwife.pregnancy-type.title',
        defaultMessage: 'Type de grossesse',
    },
    'home-care.midwife.pregnancy-type.simple': {
        id: 'widgets.home-care.midwife.pregnancy-type.simple',
        defaultMessage: 'Grossesse simple',
    },
    'home-care.midwife.pregnancy-type.twins': {
        id: 'widgets.home-care.midwife.pregnancy-type.twins',
        defaultMessage: 'Grossesse gémellaire',
    },
    'home-care.midwife.childbirth-mode.title': {
        id: 'widgets.home-care.midwife.childbirth-mode.title',
        defaultMessage: "Mode d'accouchement",
    },
    'home-care.midwife.childbirth-mode.natural-delivery': {
        id: 'widgets.home-care.midwife.childbirth-mode.natural-delivery',
        defaultMessage: 'Par voie basse',
    },
    'home-care.midwife.childbirth-mode.cesarean-delivery': {
        id: 'widgets.home-care.midwife.childbirth-mode.cesarean-delivery',
        defaultMessage: 'Par césarienne',
    },
    'home-care.postnatal-monitoring.feeding-mode.title': {
        id: 'widgets.home-care.postnatal-monitoring.feeding-mode.title',
        defaultMessage: "Mode d'allaitement",
    },
    'home-care.postnatal-monitoring.feeding-mode.breast': {
        id: 'widgets.home-care.postnatal-monitoring.feeding-mode.breast',
        defaultMessage: 'Sein',
    },
    'home-care.postnatal-monitoring.feeding-mode.baby-bottle': {
        id: 'widgets.home-care.postnatal-monitoring.feeding-mode.baby-bottle',
        defaultMessage: 'Allaitement biberon',
    },
    'home-care.postnatal-monitoring.child-weight.title': {
        id: 'widgets.home-care.postnatal-monitoring.child-weight.title',
        defaultMessage: 'Poids du bébé (en grammes)',
    },
    'home-care.postnatal-monitoring.baby-pathology.title': {
        id: 'widgets.home-care.postnatal-monitoring.baby-pathology.title',
        defaultMessage: "Pathologie du bébé suite à l'accouchement",
    },
    'home-care.postnatal-monitoring.baby-pathology.pathologies-none': {
        id: 'widgets.home-care.postnatal-monitoring.baby-pathology.pathologies-none',
        defaultMessage: 'Aucune',
    },
    'home-care.postnatal-monitoring.baby-pathology.jaundice': {
        id: 'widgets.home-care.postnatal-monitoring.baby-pathology.jaundice',
        defaultMessage: 'Jaunisse',
    },
    'home-care.postnatal-monitoring.baby-pathology.weight-difficulties': {
        id: 'widgets.home-care.postnatal-monitoring.baby-pathology.weight-difficulties',
        defaultMessage: 'Difficultés prise de poids',
    },
    'home-care.postnatal-monitoring.baby-pathology.other': {
        id: 'widgets.home-care.postnatal-monitoring.baby-pathology.other',
        defaultMessage: 'Autre',
    },
    'home-care.midwife.lesion-after-birth.title': {
        id: 'widgets.home-care.midwife.lesion-after-birth.title',
        defaultMessage: "Type de lésion suite à l'accouchement",
    },
    'home-care.midwife.lesion-after-birth.lesions-none': {
        id: 'widgets.home-care.midwife.lesion-after-birth.lesions-none',
        defaultMessage: 'Aucune',
    },
    'home-care.midwife.lesion-after-birth.episiotomy': {
        id: 'widgets.home-care.midwife.lesion-after-birth.episiotomy',
        defaultMessage: 'Épisiotomie',
    },
    'home-care.midwife.lesion-after-birth.tearing': {
        id: 'widgets.home-care.midwife.lesion-after-birth.tearing',
        defaultMessage: 'Déchirure',
    },
    'home-care.pregnancy-monitoring.pathology.title': {
        id: 'widgets.home-care.pregnancy-monitoring.pathology.title',
        defaultMessage: 'Quel type de pathologie entraîne une surveillance ?',
    },
    'home-care.pregnancy-monitoring.pathology.hypertension': {
        id: 'widgets.home-care.pregnancy-monitoring.pathology.hypertension',
        defaultMessage: 'Hypertension',
    },
    'home-care.pregnancy-monitoring.pathology.diabetes': {
        id: 'widgets.home-care.pregnancy-monitoring.pathology.diabetes',
        defaultMessage: 'Diabète',
    },
    'home-care.pregnancy-monitoring.pathology.baby-growth': {
        id: 'widgets.home-care.pregnancy-monitoring.pathology.baby-growth',
        defaultMessage: 'Croissance du bébé',
    },
    'home-care.pregnancy-monitoring.pathology.early-contraction': {
        id: 'widgets.home-care.pregnancy-monitoring.pathology.early-contraction',
        defaultMessage: 'Contractions précoces',
    },
    'home-care.pregnancy-monitoring.pathology.cholestasis': {
        id: 'widgets.home-care.pregnancy-monitoring.pathology.cholestasis',
        defaultMessage: 'Cholestasis',
    },
    'home-care.pregnancy-monitoring.pathology.other': {
        id: 'widgets.home-care.pregnancy-monitoring.pathology.other',
        defaultMessage: 'Autre',
    },
    'home-care.pregnancy-monitoring.gynecologist-name.title': {
        id: 'widgets.home-care.pregnancy-monitoring.gynecologist-name.title',
        defaultMessage: 'Nom de votre Gynécologue référent',
    },
    'home-care.midwife.term.title': {
        id: 'widgets.home-care.midwife.term.title',
        defaultMessage: 'A quel terme êtes-vous ? (en semaines)',
    },
    'home-care.birth-preparation.father.description': {
        id: 'widgets.home-care.birth-preparation.father.description',
        defaultMessage: 'Présence du père souhaitée',
    },
    'home-care.birth-preparation.expectations.title': {
        id: 'widgets.home-care.birth-preparation.expectations.title',
        defaultMessage: 'Avez-vous des attentes particulières ?',
    },
    'home-care.child-birth.anesthesia.title': {
        id: 'widgets.home-care.child-birth.anesthesia.title',
        defaultMessage: 'Travail souhaité avec anesthésie',
    },
    'home-care.gynecological-follow-up.contraception.description': {
        id: 'widgets.home-care.gynecological-follow-up.contraception.description',
        defaultMessage: "J'utilise un mode de contraception",
    },
    'home-care.gynecological-follow-up.last-smear.title': {
        id: 'widgets.home-care.gynecological-follow-up.last-smear.title',
        defaultMessage: 'Date de votre dernier frottis ?',
    },
    'home-care.midwife.first-pregnancy.cesarean.description': {
        id: 'widgets.home-care.midwife.first-pregnancy.cesarean.description',
        defaultMessage: 'Avez-vous eu une césarienne ?',
    },
    'consent.commercial.description': {
        id: 'widgets.consent.commercial.description',
        defaultMessage:
            'Offres et astuces pour mieux vous protéger au quotidien ? En complément des solutions dont vous bénéficiez, soyez informé de nos offres commerciales conçues spécifiquement pour faciliter votre quotidien.',
    },
    'consent.commercial.yes.label': {
        id: 'widgets.consent.commercial.yes.label',
        defaultMessage:
            "J'accepte l'utilisation par Mercer de mes données personnelles et de celles de mes ayants droits afin de recevoir des offres commerciales personnalisées sur mon portail Mercer ma santé.",
    },
    'consent.commercial.no.label': {
        id: 'widgets.consent.commercial.no.label',
        defaultMessage:
            "Je refuse l'utilisation par Mercer de mes données personnelles et de celles de mes ayants droits afin de recevoir des offres commerciales personnalisées sur mon portail Mercer ma santé.",
    },
    'consent.channels.commercial.title': {
        id: 'widgets.consent.channels.commercial.title',
        defaultMessage:
            'Je souhaite également recevoir des offres (plusieurs choix possibles) :',
    },
    'general-conditions-of-use-renewal.title': {
        id: 'forms.general-conditions-of-use-renewal.title',
        defaultMessage: "Mise à jour des Conditions Générales d'Utilisation",
    },
    'general-conditions-of-use-renewal.description': {
        id: 'forms.general-conditions-of-use-renewal.description',
        defaultMessage:
            "Nous avons mis à jour nos Conditions Générales d'Utilisation pour améliorer notre service. Pour continuer à utiliser notre application, veuillez prendre un moment pour lire et accepter nos nouvelles Conditions Générales d'Utilisation suivantes :",
    },
    'general-conditions-of-use-renewal.confirm-description': {
        id: 'widgets.general-conditions-of-use-renewal.confirm-description',
        defaultMessage: "J'accepte ces conditions générales d'utilisation",
    },
    'user.phone-upgrade.title': {
        id: 'forms.user.phone-upgrade.title',
        defaultMessage: 'Vos n° de téléphone deviennent essentiels',
    },
    'user.phone-upgrade.description': {
        id: 'forms.user.phone-upgrade.description',
        defaultMessage:
            'Prochainement afin de renforcer la sécurité de vos données, les codes de sécurité pour vous connecter vous seront exclusivement envoyés par SMS. Renseignez bien les numéros de portable sur lesquels vous souhaitez les recevoir.',
    },
    'phone-upgrade.mobile-title': {
        id: 'widgets.phone-upgrade.mobile-title',
        defaultMessage: 'Numéro de portable',
    },
    'phone-upgrade.mobile-help': {
        id: 'widgets.phone-upgrade.mobile-help',
        defaultMessage:
            'Ce n° de téléphone portable vous permettra de recevoir un code de sécurité pour vous authentifier.',
    },
    'user.phone-upgrade-modale.title': {
        id: 'forms.user.phone-upgrade-modale.title',
        defaultMessage: 'Vos numéros de téléphone sont essentiels',
    },
    'user.phone-upgrade-modale.description': {
        id: 'forms.user.phone-upgrade-modale.description',
        defaultMessage:
            'Afin de recevoir le code de vérification par SMS permettant de vous connecter à l’application Ma Santé, nous vous invitons à renseigner votre numéro de téléphone portable ci-dessous.Nous vous invitons aussi à renseigner le numéro de portable secondaire avec le numéro de téléphone mobile d’un bénéficiaire au contrat afin que ce dernier soit autonome pour s’authentifier sur l’application.',
    },
    'phone-upgrade-modale.mobile-help': {
        id: 'widgets.phone-upgrade-modale.mobile-help',
        defaultMessage:
            'Ce numéro de téléphone doit être un numéro français et être renseigné sur 10 chiffres sans code pays.',
    },
    'phone-upgrade.secondary-title': {
        id: 'widgets.phone-upgrade.secondary-title',
        defaultMessage: 'Numéro de portable secondaire',
    },
    'phone-upgrade.secondary-help': {
        id: 'widgets.phone-upgrade.secondary-help',
        defaultMessage:
            'Autre n° de téléphone portable pouvant être utilisé pour recevoir un code de sécurité vous permettant de vous authentifier',
    },
    'consent.title': {
        id: 'forms.consent.title',
        defaultMessage: 'Gestion du consentement',
    },
    'consent.description': {
        id: 'forms.consent.description',
        defaultMessage:
            'Modifiez vos consentements en quelques clics pour une expérience en ligne personnalisée et sécurisée.',
    },
    'consent.footer': {
        id: 'forms.consent.footer',
        defaultMessage:
            "<i>(*) En application de la législation en vigueur, vous disposez d'un droit d'accès, d'interrogation, de rectification ou d'effacement, de limitation du traitement de vos données, d'un droit d'opposition et de portabilité de vos données ainsi que du droit de définir des directives relatives au sort de vos données après votre décès. <br></br> Vous disposez enfin du droit d'introduire une réclamation auprès d'une autorité de contrôle. Ce droit s'exerce auprès de la Commission nationale de l'Informatique et des libertés (Cnil), 3 rue de Fontenoy, 75007 Paris.<br></br>Pour trouver toutes les informations relatives aux traitements de données à caractère personnel que nous effectuons, veuillez consulter notre Politique de confidentialité. </i>",
    },
    'consent.channels.prevention.title': {
        id: 'widgets.consent.channels.prevention.title',
        defaultMessage:
            'Je souhaite également recevoir des conseils (plusieurs choix possibles) :',
    },
    'consent.commercial.title': {
        id: 'widgets.consent.commercial.title',
        defaultMessage: 'Offres commerciales',
    },
    'consent.data-protection.description': {
        id: 'widgets.consent.data-protection.description',
        defaultMessage:
            'La confidentialité de vos données est au cœur de nos préoccupations pour maintenir un lien de confiance inébranlable avec vous. (*)',
    },
    'consent.prevention.title': {
        id: 'widgets.consent.prevention.title',
        defaultMessage: 'Consentement prévention',
    },
    'consent.prevention.description': {
        id: 'widgets.consent.prevention.description',
        defaultMessage:
            'Nous vous proposons de vous accompagner avec des conseils santé et des recommandations personnalisées. Pour cela veuillez noter que nous avons mis à jour notre {link1}.',
    },
    'consent.prevention.description-link.privacy-policy': {
        id: 'widgets.consent.prevention.description-link.privacy-policy',
        defaultMessage: 'politique de confidentialité',
    },
    'consent.prevention.description-link.know-more': {
        id: 'widgets.consent.prevention.description-link.know-more',
        defaultMessage: 'en savoir plus',
    },
    'consent.prevention.yes.label': {
        id: 'widgets.consent.prevention.yes.label',
        defaultMessage:
            'Je consens au traitement de mes données personnelles (à l’exclusion de mes données de santé) afin de bénéficier des services de prévention dans les conditions décrites et de recevoir des informations personnalisées en matière de prévention et de santé.',
    },
    'consent.prevention.no.label': {
        id: 'widgets.consent.prevention.no.label',
        defaultMessage:
            'Je refuse le traitement de mes données personnelles afin de bénéficier des services de Ma Santé dans les conditions décrites et de recevoir des informations personnalisées en matière de prévention et de santé.',
    },
    'consent.channels.email.label': {
        id: 'widgets.consent.channels.email.label',
        defaultMessage: 'Par mail',
    },
    'consent.channels.sms.label': {
        id: 'widgets.consent.channels.sms.label',
        defaultMessage: 'Par SMS',
    },
    'consent.channels.phone.label': {
        id: 'widgets.consent.channels.phone.label',
        defaultMessage: 'Par téléphone',
    },
    'health.refunds.requests.alternative-medicine.subCategory.dietician-without-supplements':
        {
            id: 'widgets.health.refunds.requests.alternative-medicine.subCategory.dietician-without-supplements',
            defaultMessage:
                'Consultation diététicien (hors compléments alimentaires)',
        },
    'health.refunds.requests.alternative-medicine.subCategory.nutritionist-without-supplements':
        {
            id: 'widgets.health.refunds.requests.alternative-medicine.subCategory.nutritionist-without-supplements',
            defaultMessage:
                'Consultation nutritionniste (hors compléments alimentaires)',
        },
    'health.bank-details.refunds.description-manual-process': {
        id: 'forms.health.bank-details.refunds.description-manual-process',
        defaultMessage:
            'Pour modifier les coordonnées bancaires de remboursement, veuillez saisir les informations ci-dessous et joindre le nouveau RIB. Pour des raisons de sécurité, la demande peut prendre quelques jours à être traitée.',
    },
    'home-care-contact.other.title': {
        id: 'forms.home-care-contact.other.title',
        defaultMessage: 'Autres demandes',
    },
    'home-care-contact.other.description': {
        id: 'forms.home-care-contact.other.description',
        defaultMessage:
            'Envoyez un message concernant les soins à domicile via ce formulaire. <br></br> Une réponse vous sera apportée par email ou par téléphone',
    },
    'home-care-contact.manage-care.title': {
        id: 'forms.home-care-contact.manage-care.title',
        defaultMessage: 'Gérer mes soins à domicile',
    },
    'home-care-contact.manage-care.description': {
        id: 'forms.home-care-contact.manage-care.description',
        defaultMessage:
            "N'hésitez pas à consulter notre page d'aide. La réponse à votre question s'y trouve peut-être. <br></br> Si vous n'avez pas trouvé, écrivez-nous via le formulaire ci-dessous. Nous vous répondrons au plus vite !",
    },
    'home-care-contact.beneficiary.description': {
        id: 'widgets.home-care-contact.beneficiary.description',
        defaultMessage:
            "Vous pouvez faire une demande pour vous (l'assuré principal du contrat) ou pour vos bénéficiaires mineurs",
    },
    'home-care-contact.beneficiary.help': {
        id: 'widgets.home-care-contact.beneficiary.help',
        defaultMessage:
            "Vos bénéficiaires majeurs peuvent avoir un accès indépendant à l'application donc envoyer une demande depuis leur compte.",
    },
    'home-care-contact.message.title': {
        id: 'widgets.home-care-contact.message.title',
        defaultMessage: 'Comment pouvons-nous vous aider ?',
    },
    'home-care-contact.care-creation-date.title': {
        id: 'widgets.home-care-contact.care-creation-date.title',
        defaultMessage: 'Date de création de votre demande de soin',
    },
    'home-care-contact.care-giver-informed.title': {
        id: 'widgets.home-care-contact.care-giver-informed.title',
        defaultMessage: 'Avez-vous prévenu votre soignant ?',
    },
    'message-object.title': {
        id: 'widgets.message-object.title',
        defaultMessage: 'Votre demande concerne',
    },
    'home-care-contact.question': {
        id: 'widgets.home-care-contact.question',
        defaultMessage: 'Question sur une demande de soins',
    },
    'home-care-contact.cancellation': {
        id: 'widgets.home-care-contact.cancellation',
        defaultMessage: 'Annulation de soins',
    },
    'add-beneficiary.adult-title': {
        id: 'forms.add-beneficiary.adult-title',
        defaultMessage: 'Inviter un bénéficiaire majeur',
    },
    'add-beneficiary.adult-description': {
        id: 'forms.add-beneficiary.adult-description',
        defaultMessage:
            'En invitant vos bénéficiaires majeurs, ils peuvent créer un compte et avoir ainsi un accès autonome et gratuit aux services Ma Sphère Médicale tel que la prise de rendez-vous ou le contenu de prévention.',
    },
    'beneficiary-account.phone-number-description': {
        id: 'widgets.beneficiary-account.phone-number-description',
        defaultMessage:
            'Ce n° pourra être utilisé pour son authentification ainsi que ses éventuels rendez-vous',
    },
    'beneficiary-account.email-description': {
        id: 'widgets.beneficiary-account.email-description',
        defaultMessage:
            'Votre bénéficiaire recevra son invitation sur cette adresse email. Elle lui servira également d’identifiant de connexion par la suite.',
    },
    'consent.change-everytime.description': {
        id: 'widgets.consent.change-everytime.description',
        defaultMessage:
            '<b>Vous pouvez à tout moment modifier votre consentement dans la rubrique Profil.</b>',
    },
    'user.password.request.username.help': {
        id: 'api.forms.user.password.request.username.help',
        defaultMessage:
            'Votre identifiant est votre <b>numéro adhérent</b>, vous pouvez le trouver à l’intérieur de votre <b>carte de tiers payant</b>.',
    },
    'contract-deletion.disclaimer.description': {
        id: 'widgets.contract-deletion.disclaimer.description',
        defaultMessage:
            'La date de prise d’effet de la résiliation vous sera confirmée par le centre de gestion.<br></br>La résiliation concerne tous les bénéficiaires du contrat.<br></br>Toute nouvelle souscription est impossible pendant 2 années civiles entières à partir de la date de résiliation.',
    },
    'beneficiaries.message.description-active-only': {
        id: 'beneficiaries.message.description-active-only',
        defaultMessage:
            "Les nouveaux bénéficiaires que vous souhaitez ajouter à votre contrat ne seront visibles sur votre espace qu'à partir de la date d'effet de leur affiliation",
    },
    'confirmation-page.beneficiary-addition-description': {
        id: 'confirmation-page.beneficiary-addition-description',
        defaultMessage:
            "Les nouveaux bénéficiaires que vous souhaitez ajouter à votre contrat ne seront visibles sur votre espace qu'à partir de la date d'effet de leur affiliation",
    },
    'quote.beneficiary.description-active-only': {
        id: 'quote.beneficiary.description-active-only',
        defaultMessage: 'Sélectionnez le bénéficiaire concerné par ce devis.',
    },
    'refunds.beneficiary.description-active-only': {
        id: 'refunds.beneficiary.description-active-only',
        defaultMessage:
            'Pour toutes demandes de devis qui concerneraient <b>l’un de vos anciens bénéficiaires</b> (radiés lors des deux dernières années), nous vous invitons à <b>contacter votre centre de gestion</b> au numéro indiqué sur votre carte de tiers payant.',
    },
    'cost-coverages.hospitalization.beneficiary.description-active-only': {
        id: 'cost-coverages.hospitalization.beneficiary.description-active-only',
        defaultMessage:
            'Veuillez indiquer le bénéficiaire concerné par l’hospitalisation.',
    },
    'beneficiary.type.title': {
        id: 'widgets.beneficiary.type.title',
        defaultMessage: 'Type de bénéficiaire',
    },
    'beneficiary.status.partner': {
        id: 'widgets.beneficiary.status.partner',
        defaultMessage: 'Conjoint',
    },
    'beneficiary.status.child': {
        id: 'widgets.beneficiary.status.child',
        defaultMessage: 'Enfant',
    },
    'user-infos.gender.male': {
        id: 'widgets.user-infos.gender.male',
        defaultMessage: 'M.',
    },
    'user-infos.gender.female': {
        id: 'widgets.user-infos.gender.female',
        defaultMessage: 'Mme.',
    },
    'social-security-number.placeholder': {
        id: 'widgets.social-security-number.placeholder',
        defaultMessage: '0 00 00 00 000 000 00',
    },
    'other-complementary-health-insurance.title': {
        id: 'widgets.other-complementary-health-insurance.title',
        defaultMessage:
            'Est-il(elle) déjà couvert(e) par une autre complémentaire santé ou par la CMU ?',
    },
    'organization-code.primo-description': {
        id: 'widgets.organization-code.primo-description',
        defaultMessage:
            'S’il n’a pas d’autre mutuelle, veillez dans ce cas à bien renseigner son code organisme pour activer la télétransmission.',
    },
    'social-security-number.child-title': {
        id: 'widgets.social-security-number.child-title',
        defaultMessage: 'N° de Sécurité sociale de l’enfant (15 chiffres)',
    },
    'social-security-number.child-attachment.title': {
        id: 'widgets.social-security-number.child-attachment.title',
        defaultMessage:
            "L'enfant est-il rattaché au numéro de Sécurité sociale du parent ?",
    },
    'social-security-number.child-attachment.description': {
        id: 'widgets.social-security-number.child-attachment.description',
        defaultMessage:
            'Pour le savoir, merci de vous référer à votre attestation de Sécurité sociale ou à celle de votre enfant.',
    },
    'social-security-number.child-attachment.yes-main': {
        id: 'widgets.social-security-number.child-attachment.yes-main',
        defaultMessage: 'Oui, au vôtre',
    },
    'social-security-number.child-attachment.yes-partner': {
        id: 'widgets.social-security-number.child-attachment.yes-partner',
        defaultMessage: 'Oui, à celui de votre conjoint',
    },
    'social-security-number.child-attachment.no': {
        id: 'widgets.social-security-number.child-attachment.no',
        defaultMessage: 'Non, il a son propre numéro de Sécurité sociale',
    },
    'social-security-number.attached-title': {
        id: 'widgets.social-security-number.attached-title',
        defaultMessage: 'N° de Sécurité sociale de rattachement (15 chiffres)',
    },
    'social-security-number.attached-description': {
        id: 'widgets.social-security-number.attached-description',
        defaultMessage:
            'Renseignez le numéro de Sécurité sociale indiqué en face de votre enfant sur l’attestation de Sécurité sociale.',
    },
    'bank-details-partner-add-title': {
        id: 'workflow-primo.bank-details.partner-add.title',
        defaultMessage: 'Ajouter les coordonnées bancaires de votre conjoint',
    },
    'bank-details-partner-change-title': {
        id: 'workflow-primo.bank-details.partner-change.title',
        defaultMessage: 'Modifier les coordonnées bancaires de votre conjoint',
    },
    'user-infos.phone-number-origin.french': {
        id: 'widgets.user-infos.phone-number-origin.french',
        defaultMessage: "J'ai un numéro français",
    },
    'user-infos.phone-number-origin.foreign': {
        id: 'widgets.user-infos.phone-number-origin.foreign',
        defaultMessage: "J'ai un numéro étranger",
    },
    'user-infos.phone-number-origin.title': {
        id: 'widgets.user-infos.phone-number-origin.title',
        defaultMessage: 'Numéro de portable',
    },
    'user-infos.phone-number-origin.description': {
        id: 'widgets.user-infos.phone-number-origin.description',
        defaultMessage:
            'Un numéro de téléphone portable français vous permettra de recevoir un code de sécurité par SMS pour votre authentification, ce qui renforce la sécurité de votre espace assuré.',
    },
    'phone-upgrade.phone-number-origin.title': {
        id: 'widgets.phone-upgrade.phone-number-origin.title',
        defaultMessage: 'Vérifiez votre numéro de téléphone',
    },
    'phone-upgrade.phone-number-origin.description': {
        id: 'widgets.phone-upgrade.phone-number-origin.description',
        defaultMessage:
            'Renseignez votre numéro de téléphone portable français sur lequel vous recevrez, par SMS, le code de sécurité.',
    },
});
