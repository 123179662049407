import { useIntl } from 'react-intl';

const useFormattedTime = () => {
    const { formatDate } = useIntl();

    const formatCustomDate = (
        date: Parameters<Intl.DateTimeFormat['format']>[0] | string,
        options = {}
    ) => {
        const formattedDate = formatDate(date, options);
        return formattedDate.replace(':', 'h');
    };

    return formatCustomDate;
};

export default useFormattedTime;
