import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
    getIntlPath,
    getAuthToken,
    GetServerSidePropsContextWithAuth,
    Auth,
    getRedirectUrl,
} from 'core';
import routes from 'routes';
import { getPrimoAuthToken } from 'lib/primo/helpers';

export enum SCOPE {
    STANDARD = 'standard',
    PRIMO = 'primo',
}

function withAuth(handler: any, scopes: SCOPE[] = [SCOPE.STANDARD]) {
    return (context: GetServerSidePropsContextWithAuth) => {
        const standardToken = getAuthToken(context.req);
        const primoToken = getPrimoAuthToken(context.req);
        const tokenByScope: Record<SCOPE, string> = {
            [SCOPE.STANDARD]: standardToken,
            [SCOPE.PRIMO]: primoToken,
        };

        const hasRequiredToken = scopes.some((scope) => !!tokenByScope[scope]);

        if (!hasRequiredToken) {
            const destination = getIntlPath(routes, '/login', context.locale);

            return {
                redirect: {
                    destination: `${destination}?redirect=${encodeURIComponent(
                        JSON.stringify(
                            getRedirectUrl(context.resolvedUrl, context.query)
                        )
                    )}`,
                    permanent: false,
                },
            };
        }

        const token = standardToken || primoToken;
        const decodedJwt = jwtDecode<JwtPayload & { payload: Auth }>(token);
        context.req.auth = { ...decodedJwt.payload, token };

        return handler(context);
    };
}

export { withAuth };
