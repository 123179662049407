import { Flex, Heading, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import React, { useEffect } from 'react';

export type TabsProps = {
    tabs: TabProps[];
    status: 'pending' | 'error' | 'success' | 'empty' | 'maintenance';
};

export default function Tabs({ tabs, status }: TabsProps) {
    const [activeTab, setActiveTab] = React.useState<TabProps>(tabs?.[0]);

    useEffect(() => {
        if (activeTab === undefined) {
            setActiveTab(tabs?.[0]);
        }
    }, [tabs, activeTab]);

    return (
        <Stack gap="6">
            <HStack
                data-testid="dashboard-tabs-container"
                spacing="2"
                overflowX="auto"
                sx={{
                    '::-webkit-scrollbar': {
                        height: 0,
                    },
                }}>
                {tabs.map((tab) => (
                    <React.Fragment key={tab.id}>
                        {status !== 'pending' ? (
                            tabs.length > 1 ? (
                                <Tab
                                    label={tab.label}
                                    isActive={activeTab?.id === tab.id}
                                    onSelect={() => setActiveTab(tab)}
                                />
                            ) : (
                                <Heading as="h3" key={uniqueId()} fontSize="xl">
                                    {tab.label}
                                </Heading>
                            )
                        ) : (
                            <Skeleton
                                rounded="full"
                                height="50px"
                                width="200px"
                            />
                        )}
                    </React.Fragment>
                ))}
            </HStack>
            {activeTab?.component}
        </Stack>
    );
}

export type TabProps = {
    id: string;
    label: string;
    component: React.ReactElement;
    eligibilities?: string[];
};

type TabContainerProps = Pick<TabProps, 'label'> & {
    isActive: boolean;
    onSelect: () => void;
};

export function Tab({ label, isActive, onSelect }: TabContainerProps) {
    const focusStyles = {
        bg: 'bg.colorextralight',
        borderColor: 'transparent',
    };

    return (
        <Flex
            data-testid="Tab"
            role="tab"
            tabIndex={0}
            alignItems="center"
            py="3"
            px="5"
            rounded="full"
            onClick={onSelect}
            cursor="pointer"
            userSelect="none"
            border="1px solid"
            borderColor={isActive ? 'transparent' : 'strokes.medium'}
            {...(isActive && focusStyles)}
            _hover={focusStyles}
            _focusVisible={{ outline: 'unset', ...focusStyles }}>
            <Text
                fontWeight="bold"
                color={isActive ? 'texts.main' : 'texts.medium'}
                whiteSpace="nowrap">
                {label}
            </Text>
        </Flex>
    );
}
