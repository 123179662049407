export default {
    "/news": {
        "fr-FR": "/journal",
        "en-GB": "/feed"
    },
    "/news/teletransmission-request": {
        "fr-FR": "/journal/demande-teletransmission",
        "en-GB": "/news/teletransmission-request"
    },
    "/refunds": {
        "fr-FR": "/remboursements",
        "en-GB": "/refunds"
    },
    "/refunds/statements": {
        "fr-FR": "/remboursements/e-releves",
        "en-GB": "/refunds/e-statements"
    },
    "/refunds/analysis": {
        "fr-FR": "/remboursements/analyse",
        "en-GB": "/refunds/analysis"
    },
    "/refunds/:refundId": {
        "fr-FR": "/remboursements/:refundId",
        "en-GB": "/refunds/:refundId"
    },
    "/simulator/families": {
        "fr-FR": "/simulateur/familles",
        "en-GB": "/simulator/families"
    },
    "/simulator/families/:familyId": {
        "fr-FR": "/simulateur/familles/:familyId",
        "en-GB": "/simulator/families/:familyId"
    },
    "/simulator/families/:familyId/:guaranteeId": {
        "fr-FR": "/simulateur/familles/:familyId/:guaranteeId",
        "en-GB": "/simulator/families/:familyId/:guaranteeId"
    },
    "/simulator/:simulationGuaranteeId": {
        "fr-FR": "/simulateur/:simulationGuaranteeId",
        "en-GB": "/simulator/:simulationGuaranteeId"
    },
    "/onboarding": {
        "fr-FR": "/bienvenue",
        "en-GB": "/onboarding"
    },
    "/account": {
        "fr-FR": "/compte",
        "en-GB": "/account"
    },
    "/login": {
        "fr-FR": "/connexion",
        "en-GB": "/login"
    },
    "/reset-password/:providerKey": {
        "fr-FR": "/reinitialiser-mot-de-passe/:providerKey",
        "en-GB": "/reset-password/:providerKey"
    },
    "/logout": {
        "fr-FR": "/deconnexion",
        "en-GB": "/logout"
    },
    "/account/beneficiaries": {
        "fr-FR": "/compte/beneficiaires",
        "en-GB": "/account/beneficiaries"
    },
    "/account/profile": {
        "fr-FR": "/compte/profil",
        "en-GB": "/account/profile"
    },
    "/account/products/levels/:id": {
        "fr-FR": "/compte/produits/niveaux/:id",
        "en-GB": "/account/products/levels/:id"
    },
    "/account/products/:contractType/:id": {
        "fr-FR": "/compte/produits/:contractType/:id",
        "en-GB": "/account/products/:contractType/:id"
    },
    "/account/guarantees/families": {
        "fr-FR": "/compte/garanties/familles",
        "en-GB": "/account/guarantees/families"
    },
    "/account/guarantees/families/:familyId": {
        "fr-FR": "/compte/garanties/familles/:familyId",
        "en-GB": "/account/guarantees/families/:familyId"
    },
    "/account/guarantees/families/:familyId/:guaranteeId": {
        "fr-FR": "/compte/garanties/familles/:familyId/:guaranteeId",
        "en-GB": "/account/guarantees/families/:familyId/:guaranteeId"
    },
    "/account/guarantees/families/:familyId/shared-ceilings/:sharedCeilingId": {
        "fr-FR": "/compte/garanties/familles/:familyId/plafonds-partages/:sharedCeilingId",
        "en-GB": "/account/guarantees/families/:familyId/shared-ceilings/:sharedCeilingId"
    },
    "/document/:source": {
        "fr-FR": "/document/:source",
        "en-GB": "/document/:source"
    },
    "/messages": {
        "fr-FR": "/messages",
        "en-GB": "/messages"
    },
    "/messages/new": {
        "fr-FR": "/messages/nouveau",
        "en-GB": "/messages/new"
    },
    "/messages/new/mail": {
        "fr-FR": "/messages/nouveau/courrier",
        "en-GB": "/messages/new/mail"
    },
    "/messages/new/acceo": {
        "fr-FR": "/messages/nouveau/acceo",
        "en-GB": "/messages/new/acceo"
    },
    "/messages/new/phone": {
        "fr-FR": "/messages/nouveau/telephone",
        "en-GB": "/messages/new/phone"
    },
    "/messages/new/disclaimer": {
        "fr-FR": "/messages/nouveau/clause",
        "en-GB": "/messages/new/disclaimer"
    },
    "/messages/:messageId": {
        "fr-FR": "/messages/:messageId",
        "en-GB": "/messages/:messageId"
    },
    "/messages/:messageId/answer": {
        "fr-FR": "/messages/:messageId/reponse",
        "en-GB": "/messages/:messageId/answer"
    },
    "/messages/new/:reasonId": {
        "fr-FR": "/messages/nouveau/:reasonId",
        "en-GB": "/messages/new/:reasonId"
    },
    "/messages/new/:reasonId/:themeId": {
        "fr-FR": "/messages/nouveau/:reasonId/:themeId",
        "en-GB": "/messages/new/:reasonId/:themeId"
    },
    "/account/tp-card": {
        "fr-FR": "/compte/carte-de-tiers-payant",
        "en-GB": "/account/tp-card"
    },
    "/account/contributions": {
        "fr-FR": "/compte/cotisations",
        "en-GB": "/account/contributions"
    },
    "/account/contributions/:schedulesId": {
        "fr-FR": "/compte/cotisations/:schedulesId",
        "en-GB": "/account/contributions/:schedulesId"
    },
    "/forms/:formId": {
        "fr-FR": "/formulaires/:formId",
        "en-GB": "/forms/:formId"
    },
    "/register/forms": {
        "fr-FR": "/inscription/formulaires",
        "en-GB": "/register/forms"
    },
    "/register/account-validated/:chosenLogin": {
        "fr-FR": "/inscription/compte-valide/:chosenLogin",
        "en-GB": "/register/account-validated/:chosenLogin"
    },
    "/register/success/:status": {
        "fr-FR": "/inscription/success/:status",
        "en-GB": "/register/success/:status"
    },
    "/register/responses/:status": {
        "fr-FR": "/inscription/reponses/:status",
        "en-GB": "/register/responses/:status"
    },
    "/register/known": {
        "fr-FR": "/inscription/connu",
        "en-GB": "/register/known"
    },
    "/register/activation/:providerKey": {
        "fr-FR": "/inscription/activation/:providerKey",
        "en-GB": "/register/activation/:providerKey"
    },
    "/register/challenge/:strategy": {
        "fr-FR": "/inscription/challenge/:strategy",
        "en-GB": "/register/challenge/:strategy"
    },
    "/register/challenge": {
        "fr-FR": "/inscription/challenge",
        "en-GB": "/register/challenge"
    },
    "/geolocation": {
        "fr-FR": "/localiser-un-medecin",
        "en-GB": "/geolocation"
    },
    "/account/profile/terms-of-use": {
        "fr-FR": "/compte/profil/cgu",
        "en-GB": "/account/profile/terms-of-use"
    },
    "/account/profile/privacy-policy": {
        "fr-FR": "/compte/profil/politique-de-confidentialite",
        "en-GB": "/account/profile/privacy-policy"
    },
    "/redirect-portal": {
        "fr-FR": "/portail-redirection",
        "en-GB": "/redirect-portal"
    },
    "/forgot-password": {
        "fr-FR": "/mot-de-passe-oublie",
        "en-GB": "/forgot-password"
    },
    "/forgot-username": {
        "fr-FR": "/identifiant-oublie",
        "en-GB": "/forgot-username"
    },
    "/lost-credentials/error/:status": {
        "fr-FR": "/identifiants-oublies/erreur/:status",
        "en-GB": "/lost-credentials/error/:status"
    },
    "/help/faq": {
        "fr-FR": "/aide/faq",
        "en-GB": "/help/faq"
    },
    "/help/faq/:id": {
        "fr-FR": "/aide/faq/:id",
        "en-GB": "/help/faq/:id"
    },
    "/help/faq/questions/:questionId": {
        "fr-FR": "/aide/faq/questions/:questionId",
        "en-GB": "/help/faq/questions/:questionId"
    },
    "/link-builder": {
        "fr-FR": "/constructeur-de-lien",
        "en-GB": "/link-builder"
    },
    "/register": {
        "fr-FR": "/inscription",
        "en-GB": "/register"
    },
    "/password-upgrade/:token": {
        "fr-FR": "/modifier-mot-de-passe/:token",
        "en-GB": "/password-upgrade/:token"
    },
    "/maintenance": {
        "fr-FR": "/maintenance",
        "en-GB": "/maintenance"
    },
    "/account/documents": {
        "fr-FR": "/compte/documents",
        "en-GB": "/account/documents"
    },
    "/account/risks": {
        "fr-FR": "/compte/risques",
        "en-GB": "/account/risks"
    },
    "/account/beneficiaries-designation": {
        "fr-FR": "/compte/designation-beneficiaires",
        "en-GB": "/account/beneficiaries-designation"
    },
    "/signature/capital-allocation": {
        "fr-FR": "/signature/allocation-capital",
        "en-GB": "/signature/capital-allocation"
    },
    "/signature/change-rib": {
        "fr-FR": "/signature/modification-rib",
        "en-GB": "/signature/change-rib"
    },
    "/signature/summary": {
        "fr-FR": "/signature/recapitulatif",
        "en-GB": "/signature/summary"
    },
    "/signature/welcome": {
        "fr-FR": "/signature/bienvenue",
        "en-GB": "/signature/welcome"
    },
    "/signature/:procedureId/document-preview": {
        "fr-FR": "/signature/:procedureId/previsualisation-document",
        "en-GB": "/signature/:procedureId/document-preview"
    },
    "/signature/:procedureId/send-verification-code": {
        "fr-FR": "/signature/:procedureId/envoi-code-verification",
        "en-GB": "/signature/:procedureId/send-verification-code"
    },
    "/signature/:procedureId/verification-code": {
        "fr-FR": "/signature/:procedureId/code-de-verification",
        "en-GB": "/signature/:procedureId/verification-code"
    },
    "/signature/:procedureId/confirmation": {
        "fr-FR": "/signature/:procedureId/confirmation",
        "en-GB": "/signature/:procedureId/confirmation"
    },
    "/signature/:procedureId/:type/beneficiaries": {
        "fr-FR": "/signature/:procedureId/:type/beneficiaires",
        "en-GB": "/signature/:procedureId/:type/beneficiaries"
    },
    "/username-upgrade/:username/:token": {
        "fr-FR": "/nouvel-identifiant/:username/:token",
        "en-GB": "/username-upgrade/:username/:token"
    },
    "/oauth2-redirect": {
        "fr-FR": "/oauth2-redirection",
        "en-GB": "/oauth2-redirect"
    },
    "/oauth2-registration-redirect": {
        "fr-FR": "/oauth2-inscription-redirection",
        "en-GB": "/oauth2-registration-redirect"
    },
    "/account/profile/oauth2-password-change": {
        "fr-FR": "/compte/profil/oauth2-changement-de-mot-de-passe",
        "en-GB": "/account/profile/oauth2-password-change"
    },
    "/redirect": {
        "fr-FR": "/redirection",
        "en-GB": "/redirect"
    },
    "/teleconsultation/:type": {
        "fr-FR": "/teleconsultation/:type",
        "en-GB": "/teleconsultation/:type"
    },
    "/teleconsultation/:type/confirmation": {
        "fr-FR": "/teleconsultation/:type/confirmation",
        "en-GB": "/teleconsultation/:type/confirmation"
    },
    "/lyfe-redirect": {
        "fr-FR": "/lyfe-redirect",
        "en-GB": "/lyfe-redirect"
    },
    "/SAMLPost": {
        "fr-FR": "/SAMLPost",
        "en-GB": "/SAMLPost"
    },
    "/saml/sso": {
        "fr-FR": "/saml/sso",
        "en-GB": "/saml/sso"
    },
    "/agenda": {
        "fr-FR": "/agenda",
        "en-GB": "/agenda"
    },
    "/agenda/interventions/:id": {
        "fr-FR": "/agenda/interventions/:id",
        "en-GB": "/agenda/interventions/:id"
    },
    "/agenda/teleconsultations/:id": {
        "fr-FR": "/agenda/teleconsultations/:id",
        "en-GB": "/agenda/teleconsultations/:id"
    },
    "/agenda/preventions/:id": {
        "fr-FR": "/agenda/preventions/:id",
        "en-GB": "/agenda/preventions/:id"
    },
    "/public/preview": {
        "fr-FR": "/publique/previsualisation",
        "en-GB": "/public/preview"
    },
    "/account-activation/:token": {
        "fr-FR": "/activation-du-compte/:token",
        "en-GB": "/account-activation/:token"
    },
    "/home-consultation": {
        "fr-FR": "/consultation-a-domicile",
        "en-GB": "/home-consultation"
    },
    "/home-consultation/confirmation": {
        "fr-FR": "/consultation-a-domicile/confirmation",
        "en-GB": "/home-consultation/confirmation"
    },
    "/primo": {
        "fr-FR": "/primo",
        "en-GB": "/primo"
    },
    "/primo/waiting-validation": {
        "fr-FR": "/primo/attente-validation",
        "en-GB": "/primo/waiting-validation"
    },
    "/auth/general-conditions": {
        "fr-FR": "/authentification/conditions-generales",
        "en-GB": "/auth/general-conditions"
    },
    "/auth/phone-upgrade": {
        "fr-FR": "/authentification/modifier-numero-de-telephone",
        "en-GB": "/auth/phone-upgrade"
    },
    "/auth/confirmation": {
        "fr-FR": "/authentification/confirmation",
        "en-GB": "/auth/confirmation"
    },
    "/prevention": {
        "fr-FR": "/prevention",
        "en-GB": "/prevention"
    },
    "/medical-sphere": {
        "fr-FR": "/sphere-medicale",
        "en-GB": "/medical-sphere"
    },
    "/prevention/confirmation": {
        "fr-FR": "/prevention/confirmation",
        "en-GB": "/prevention/confirmation"
    },
    "/included-services": {
        "fr-FR": "/services-inclus",
        "en-GB": "/included-services"
    },
    "/included-services/redirect/:page": {
        "fr-FR": "/services-inclus/redirection/:page",
        "en-GB": "/included-services/redirect/:page"
    },
    "/included-services/articles/:articleId": {
        "fr-FR": "/services-inclus/articles/:articleId",
        "en-GB": "/included-services/articles/:articleId"
    },
    "/included-services/sections/:sectionId": {
        "fr-FR": "/services-inclus/sections/:sectionId",
        "en-GB": "/included-services/sections/:sectionId"
    },
    "/included-services/sections-themes/:quickAccessCode": {
        "fr-FR": "/services-inclus/sections-themes/:quickAccessCode",
        "en-GB": "/included-services/sections-themes/:quickAccessCode"
    },
    "/included-services/news": {
        "fr-FR": "/services-inclus/nouveautes",
        "en-GB": "/included-services/news"
    },
    "/included-services/news/:id": {
        "fr-FR": "/services-inclus/nouveautes/:id",
        "en-GB": "/included-services/news/:id"
    },
    "/permissions-test": {
        "fr-FR": "/permissions-test",
        "en-GB": "/permissions-test"
    },
    "/mfa": {
        "fr-FR": "/mfa",
        "en-GB": "/mfa"
    },
    "/mfa/:strategy/:id": {
        "fr-FR": "/mfa/:strategy/:id",
        "en-GB": "/mfa/:strategy/:id"
    }
}