import {
    Badge,
    BadgeProps,
    BoxProps,
    Button,
    ButtonProps,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { Tag, TagProps } from '../Tag';
import * as icons from 'design-system/icons';
import { ReactNode } from 'react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { omit } from 'lodash';

const hoverStyle = {
    bg: 'gray.100',
    cusor: 'pointer',
    borderColor: 'transparent',
    '.arrow-icon': {
        transform: 'translateX(-10px)',
        transition: 'ease-in-out all 0.3s',
    },
};

type BadgeMiniCTA = BadgeProps & {
    iconAfter?: string;
    iconBefore?: string;
};

type MiniCTAProps = {
    icon?: string;
    title?: ReactNode;
    titleProps?: TextProps;
    extraDescription?: ReactNode;
    description?: ReactNode;
    descriptionProps?: TextProps;
    redirectLinkProps?: ButtonProps;
    redirectLinkText?: string;
    showChevron?: boolean;
    isIconShown?: boolean;
    isLinkShown?: boolean;
    buttonText?: string;
    buttonLinkProps?: ButtonProps;
    tags?: TagProps[];
    badge?: BadgeMiniCTA;
    onClick?: () => void;
    styleProps?: BoxProps;
};

export default function MiniCTA({
    icon,
    title,
    titleProps,
    extraDescription,
    description,
    descriptionProps,
    redirectLinkProps,
    redirectLinkText,
    showChevron = true,
    onClick,
    buttonText,
    buttonLinkProps,
    tags,
    badge,
    styleProps,
}: MiniCTAProps) {
    const Icon = icons[icon];
    const IconAfterBadge = badge?.iconAfter && icons[badge.iconAfter];
    const IconBeforeBadge = badge?.iconBefore && icons[badge.iconBefore];
    const { isMobile } = useWindowBreakpoints();

    return (
        <HStack
            alignItems="flex-start"
            border="1px solid"
            borderColor="strokes.medium"
            cursor={onClick || showChevron ? 'pointer' : 'default'}
            data-testid="MiniCTA"
            placeItems="center"
            justifyContent="space-between"
            spacing="6"
            borderRadius="xl"
            p="5"
            onClick={onClick}
            _groupHover={hoverStyle}
            _hover={hoverStyle}
            transition="ease-in-out all 0.3s"
            {...styleProps}>
            <HStack gap="4">
                {Icon && (
                    <Flex bg="primary.50" rounded="full" p="2">
                        <Icon h="6" w="6" color="primary.main" />
                    </Flex>
                )}
                <Stack spacing="2">
                    {(badge || extraDescription) && (
                        <HStack
                            alignItems="flex-start"
                            flexDir={isMobile ? 'column' : 'row'}>
                            {badge && (
                                <Badge
                                    variant="subtle"
                                    rounded="full"
                                    px="2"
                                    {...omit(badge, [
                                        'iconBefore',
                                        'iconAfter',
                                    ])}
                                    textTransform="none">
                                    <HStack gap="1">
                                        {badge.iconBefore && (
                                            <IconBeforeBadge />
                                        )}
                                        <Text>{badge.defaultValue}</Text>
                                        {badge.iconAfter && <IconAfterBadge />}
                                    </HStack>
                                </Badge>
                            )}
                            {extraDescription && (
                                <Text
                                    color="texts.medium"
                                    fontSize="sm"
                                    fontWeight="bold">
                                    {extraDescription}
                                </Text>
                            )}
                        </HStack>
                    )}

                    {(title ||
                        description ||
                        buttonText ||
                        redirectLinkText ||
                        tags) && (
                        <Stack gap="1" alignItems="baseline">
                            {title && (
                                <Heading
                                    as="div"
                                    fontSize="lg"
                                    fontWeight="semibold"
                                    {...titleProps}>
                                    {title}
                                </Heading>
                            )}
                            {description && (
                                <Text {...descriptionProps}>{description}</Text>
                            )}
                            {buttonText && (
                                <Button
                                    {...buttonLinkProps}
                                    colorScheme="primary"
                                    size="sm"
                                    w="fit-content">
                                    {buttonText}
                                </Button>
                            )}
                            {redirectLinkText && (
                                <Button
                                    {...redirectLinkProps}
                                    variant="link"
                                    colorScheme={'primary'}
                                    fontSize="sm"
                                    rightIcon={<icons.ArrowRightIcon />}
                                    fontWeight="normal">
                                    {redirectLinkText}
                                </Button>
                            )}
                            {tags && (
                                <HStack data-testid="tag-container">
                                    {tags.map((tag) => (
                                        <Tag key={tag.formattedText} {...tag} />
                                    ))}
                                </HStack>
                            )}
                        </Stack>
                    )}
                </Stack>
            </HStack>
            {showChevron && (
                <icons.ChevronRightIcon
                    className="arrow-icon"
                    data-testid="chevron-icon"
                    transition="ease-in-out all 0.3s"
                />
            )}
        </HStack>
    );
}
